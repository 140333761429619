import React from 'react';
import PageMenu from '../../components/navigation-menu/page-menu';
import {menuItems, getMenuComponent, getDefaultPageRoute} from '../../app-routes';
import {withRouter} from 'react-router-dom';
import {vars} from "../../utils/variables";

const {PATH_KEY: {CLIENTS}} = vars;

class Clients extends React.Component {
	render() {
		let ActiveComponent = null;
		let {location, history} = this.props;
		const activePageId = location.pathname.split('/')[2];

		if (activePageId) {
			ActiveComponent = getMenuComponent(CLIENTS, activePageId);
		} else {
			history.push(getDefaultPageRoute(CLIENTS));
		}
		return (
			<div className={'clients-wrapper'}>
				<div className={'admin-navigation-menu'}>
					<PageMenu
						items={menuItems[CLIENTS]}
					/>
				</div>
				<div
					className={'active-component-wrapper'}
				>
					{activePageId ? (
						<ActiveComponent/>
					) : null}
				</div>
			</div>
		);
	}
}

export default withRouter(Clients);
