import React, {useEffect, useState} from 'react';
import {Button as ToolbarButton, Form, SelectBox} from 'devextreme-react';
import {GroupItem, SimpleItem} from "devextreme-react/form";
import DataGrid, {Button, Column} from "devextreme-react/data-grid";
import PartnersComponent from '../clientsPopupFields/partners';
import ShowComment from '../clientsPopupFields/show-comment';
import {Template} from 'devextreme-react/core/template';
import Collapsible from 'react-collapsible';
import {getFromLocalStorage, isConfirmedValue} from 'utils/functions';
import {notifyApp} from 'utils/notifyWrapper';
import {gridDate} from 'services/dataSourceFilterUtils';
import {apiRequest, getDocuments, getMerchantHistory} from 'services/async';
import {vars} from 'utils/variables';
import DocumentsGrid from '../common/documents-grid';
import AdminPopup from 'components/popup/admin-popup';
import DownloadDocument from '../common/download-document';

const {APP_CODES} = vars;

const formProps = {
	readOnly: true,
	showColonAfterLabel: true,
	labelLocation: 'left',
};

const gridProps = {
	showBorders: true,
	showColumnLines: true,
};

const ClientDetailAccordion = (props) => {
	const {rowData, setInnerPopupFields} = props;
	const [rights] = useState(() => {
		return getFromLocalStorage('userSession', 'Rights');
	})
	const [formData, setFormData] = useState(rowData);
	const [metaData, setMetaData] = useState([]);
	const [documents, setDocuments] = useState([]);
	const [merchantHistory, setMerchantHistory] = useState([]);
	const [isShowAddPopup, setIsShowAddPopup] = useState(false);
	const [documentType, setDocumentType] = useState({
		types: [],
		selectedType: -1,
	});

	useEffect(() => {
		if (isShowAddPopup) {
			closeAddPopup();
		}
	}, [documents]);

	useEffect(() => {
		getVerificationData().then((response) => {
			setFormData({...rowData, ...response});
		}).catch((errorText) => {
			notifyApp(errorText);
		});

		getVerificationMetaData().then((response) => {
			setMetaData(response);
		}).catch((errorText) => {
			notifyApp(errorText);
		});

		getDocuments(rowData.MerchantID).then((response) => {
			setDocuments(response);
		}).catch((errorText) => {
			notifyApp(errorText);
		});

		updateComments().catch((e) => {
			notifyApp(e);
		});
	}, []);

	const updateComments = () => {
		return getMerchantHistory(rowData.MerchantID).then((response) => {
			setMerchantHistory(response);
		}).catch((errorText) => {
			notifyApp(errorText);
		});
	}

	const getVerificationData = async () => {
		const request = await apiRequest({
			operation: 'Merchant/VerificationData',
			data: {
				Params: {MerchantID: rowData.MerchantID},
			}
		});

		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			return request.data.Response;
		} else {
			throw new Error(request.data.ResponseText);
		}
	};

	const getVerificationMetaData = async () => {
		const request = await apiRequest({
			operation: 'Merchant/VerificationMetaData',
			data: {
				Params: {MerchantID: rowData.MerchantID},
			}
		});

		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			return request.data.Response['VerificationMetaData'];
		} else {
			throw new Error(request.data.ResponseText);
		}
	};

	const onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.unshift(
			{
				location: 'before',
				template: 'customToolbar'
			},
		);
	};

	const toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<ToolbarButton
					text={'Добавить комментарий'}
					icon={'plus'}
					onClick={() => {
						setInnerPopupFields({
							component: ShowComment,
							type: {
								subtype: 'add-comment',
								remark: '',
								updateComments: updateComments,
							},
						});
					}}
				/>
			</div>
		);
	};

	const closeAddPopup = () => {
		setIsShowAddPopup(false);
	}

	useEffect(() => {
		if (isShowAddPopup) {
			fetchDocumentTypes().then((result) => {
				setDocumentType({
					types: result,
					selectedType: result[0]['ID'],
				});
			}).catch((error) => {
				notifyApp(error);
			})
		}
	}, [isShowAddPopup]);

	const fetchDocumentTypes = async () => {
		const request = await apiRequest({
			operation: 'DocumentType/List',
		});

		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			if (request.data.Response.DocumentType.length > 0) {
				return request.data.Response.DocumentType;
			} else {
				throw new Error('Ответ DocumentType/List не содержит ни одного элемента');
			}
		} else {
			throw new Error(request.data.ResponseText);
		}
	};

	return (
		<div className={'accordion-items-wrapper'}>
			<AdminPopup
				container={'.app'}
				logo={false}
				handleClose={closeAddPopup}
				visible={isShowAddPopup}
				title={'Загрузка файлов'}
			>
				<SelectBox
					dataSource={documentType.types}
					displayExpr={'Name'}
					valueExpr={'ID'}
					value={documentType.selectedType}
					onValueChanged={({value}) => {
						setDocumentType({
							...documentType,
							selectedType: value,
						});
					}}
				/>
				<DownloadDocument
					key={isShowAddPopup.toString()}
					merchantId={rowData.MerchantID}
					documentType={documentType}
					setDocuments={setDocuments}
				/>
			</AdminPopup>
			<Collapsible
				trigger={'Персональные данные'}
			>
				<Form
					formData={formData}
					{...formProps}
				>
					<SimpleItem label={{text: 'Email'}} render={() => {
						return (
							<div className={'dx-texteditor dx-editor-underlined dx-state-readonly'}>
								<span className={'dx-texteditor-input'}>{formData.UserEmail}</span>
								<i className={`field-value dx-icon-${isConfirmedValue(formData.UserIsEmailConfirmed)}`}/>
							</div>
						);
					}}/>
					<SimpleItem label={{text: 'Телефон'}} render={() => {
						return (
							<div className={'dx-texteditor dx-editor-underlined dx-state-readonly'}>
								<span className={'dx-texteditor-input'}>{formData.UserPhone}</span>
								<i className={`field-value dx-icon-${isConfirmedValue(formData.UserIsPhoneConfirmed)}`}/>
							</div>
						);
					}}/>
					<SimpleItem dataField={'Name'} label={{text: 'Имя'}}/>
					<SimpleItem label={{text: 'Имя (оригинал)'}} editorType={'dxTextBox'}
											editorOptions={{value: `${formData.FirstNameOrig}  ${formData.LastNameOrig}`}}/>
					<SimpleItem label={{text: 'Дата рождения клиента'}} editorType={'dxTextBox'}
											editorOptions={{value: gridDate(rowData['DateOfBirth'])}}/>
					<SimpleItem dataField={'CountryOfBirth'} label={{text: 'Страна рождения'}}/>
					<SimpleItem dataField={'PersonalCode'} label={{text: 'Персональный код (TIN)'}}/>
					<SimpleItem dataField={'Country'} label={{text: 'Страна клиента'}}/>
					<SimpleItem dataField={'Town'} label={{text: 'Город'}}/>
					<SimpleItem dataField={'TownOrig'} label={{text: 'Город (введенный)'}}/>
					<SimpleItem label={{text: 'Адрес'}} editorType={'dxTextBox'}
											editorOptions={{value: `${formData.Street} ${formData.PostCode}`}}/>
					<SimpleItem dataField={'StreetOrig'} label={{text: 'Адрес (введенный)'}}/>
				</Form>
			</Collapsible>

			<Collapsible trigger={'Бизнес данные'}>
				<Form
					formData={formData}
					{...formProps}
				>
					<SimpleItem dataField={'BUcompanyName'} label={{text: 'Название'}}/>
					<SimpleItem dataField={'BUregNumber'} label={{text: 'Регистрационный номер'}}/>
					<SimpleItem dataField={'BUregCountry'} label={{text: 'Страна регистрации'}}/>
					<SimpleItem label={{text: 'Дата регистрации'}} editorType={'dxTextBox'}
											editorOptions={{value: gridDate(rowData['BUdateOfReg'], true)}}/>
					<SimpleItem dataField={'BUcategory'} label={{text: 'Тип бизнеса'}}/>

					<GroupItem caption={'Юридический адрес'}>
						<SimpleItem dataField={'BUemail'} label={{text: 'Email'}}/>
						<SimpleItem dataField={'BUcountry'} label={{text: 'Страна'}}/>
						<SimpleItem dataField={'BUtown'} label={{text: 'Город'}}/>
						<SimpleItem dataField={'BUstreet'} label={{text: 'Улица'}}/>
						<SimpleItem dataField={'BUpostCode'} label={{text: 'Почтовый индекс'}}/>
					</GroupItem>

					<SimpleItem dataField={'BUlegaladdress'} label={{text: 'Юридический адрес'}}/>
					<SimpleItem dataField={'BUpostaladdress'} label={{text: 'Почтовый адрес'}}/>
					<SimpleItem dataField={'BUphone'} label={{text: 'Телефон 1'}}/>
					<SimpleItem dataField={'BUphone2'} label={{text: 'Телефон 2'}}/>
					<SimpleItem dataField={'BUHEADfirstName'} label={{text: 'Имя руководителя'}}/>
					<SimpleItem dataField={'BUHEADlastName'} label={{text: 'Фамилия руководителя'}}/>
					<SimpleItem label={{text: 'Дата рождения руководителя'}} editorType={'dxTextBox'}
											editorOptions={{value: gridDate(rowData['BUHEADdateOfBirth'])}}/>
					<SimpleItem dataField={'BUHEADcountryOfBirth'} label={{text: 'Страна рождения руководителя'}}/>
					<SimpleItem dataField={'BUHEADemail'} label={{text: 'Email руководителя'}}/>
					<SimpleItem dataField={'BUHEADmessenger'} label={{text: 'Мессенджер'}}/>
					<SimpleItem dataField={'BUHEADcountry'} label={{text: 'Страна'}}/>
					<SimpleItem dataField={'BUHEADtown'} label={{text: 'Город'}}/>
					<SimpleItem dataField={'BUHEADstreet'} label={{text: 'Улица'}}/>
					<SimpleItem dataField={'BUHEADpostCode'} label={{text: 'Почтовый индекс'}}/>
					<SimpleItem dataField={'BUHEADphone'} label={{text: 'Телефон 1'}}/>
					<SimpleItem dataField={'BUHEADphone2'} label={{text: 'Телефон 2'}}/>
				</Form>
			</Collapsible>

			<Collapsible trigger={'Верификация. Метаданные'}>
				<DataGrid
					dataSource={metaData}
					{...gridProps}
				>
					<Column dataField={'ParamName'} caption={'Название'}/>
					<Column dataField={'ParamValue'} caption={'Значение'}/>
					<Column dataField={'InsDate'} caption={'Дата добавления'} format={'dd.MM.yy, HH:mm:ss'}
									dataType={'datetime'}/>
					<Column dataField={'LastUpdate'} caption={'Дата обновления'} format={'dd.MM.yy, HH:mm:ss'}
									dataType={'datetime'}/>
				</DataGrid>
			</Collapsible>

			<Collapsible trigger={'Партнеры'}>
				<DataGrid
					showColumnHeaders={false}
					dataSource={[{'Walletto': 'Walletto'}]}
					{...gridProps}
				>
					<Column dataField={'Walletto'} customizeText={() => 'Walletto'}/>
					<Column type={'buttons'}>
						<Button
							visible={!!rowData.WallettoClientExternalID}
							text={'Получить данные'}
							onClick={() => {
								setInnerPopupFields({
									component: PartnersComponent,
									type: 'GetClient',
								});
							}}
						/>
						<Button
							visible={!rowData.WallettoClientExternalID}
							text={'Создать'}
							onClick={() => {
								setInnerPopupFields({
									component: PartnersComponent,
									type: 'CreateClient',
								});
							}}
						/>
					</Column>
					{!!rowData.WallettoClientExternalID && (
						<Column type={'buttons'}>
							<Button
								visible={!!rowData.WallettoClientExternalID}
								text={'Обновить данные'}
								onClick={() => {
									setInnerPopupFields({
										component: PartnersComponent,
										type: 'SetClient',
									});
								}}
							/>
						</Column>
					)}
				</DataGrid>
			</Collapsible>

			<Collapsible trigger={'Документы'}>
				<ToolbarButton
					className={'gap-button'}
					type={'success'}
					disabled={!(rowData.VmVerificationSystemID === 2 && rights.includes('34'))}
					onClick={() => {
						setIsShowAddPopup(true);
					}}
					text={'Добавить'}
				/>
				<DocumentsGrid documents={documents}/>
			</Collapsible>

			<Collapsible trigger={'Комментарии'}>
				<DataGrid
					dataSource={merchantHistory}
					onToolbarPreparing={onToolbarPreparing}
					{...gridProps}
				>
					<Column dataField={'InsDate'} caption={'Дата'} format={'dd.MM.yy, HH:mm:ss'} dataType={'datetime'}/>
					<Column dataField={'BoMerchantHistoryTypeName'} caption={'Параметр'}/>
					<Column dataField={'Remark'} caption={'Комментарий'}/>
					<Column type={'buttons'}>
						<Button
							text={'Подробнее'}
							onClick={({row}) => {
								setInnerPopupFields({
									component: ShowComment,
									type: {
										subtype: 'show-comment',
										remark: row.data.Remark,
									},
								});
							}}
						/>
					</Column>
					<Template name={'customToolbar'} render={toolbarItemRender}/>
				</DataGrid>
			</Collapsible>
		</div>
	);
};

export default ClientDetailAccordion;