import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {
	Button as GridButton,
	Column,
	FilterPanel,
	FilterRow,
	Format,
	Paging,
	RemoteOperations,
	Scrolling,
	StateStoring,
} from 'devextreme-react/data-grid';
import {getDataSource} from 'services/dataSource';
import {getSettlementList} from 'services/requestConsts';
import {getAppliedFilters, getFromLocalStorage, getFromSessionStorage, isVisibleSettlementBtn, formatDate} from 'utils/functions';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {Template} from 'devextreme-react/core/template';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames/bind';
import {vars} from 'utils/variables';
import {menuItems} from 'app-routes';
import {Button, Popover, TextArea} from 'devextreme-react';
import AdminPopup from 'components/popup/admin-popup';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';

const {TRANSACTION, STATE_STORING_KEYS: {MERCHANTS: {SETTLEMENT_PAGE}}, PRECISION, APP_CODES: {SUCCESS}, NOTIFY} = vars;

const initialActionRow = {row: null, target: null};
const initialConfirm = {remark: '', operation: null, title: ''};

const Settlement = ({history}) => {
	const gridRef = useRef(null);
	const [settlementSource, setSettlementSource] = useState(null);
	const [isShowExportDatePopup, setIsShowExportDatePopup] = useState(false);
	const [showFilter, setShowFilter] = useState(() => {
		return !!getFromSessionStorage('filter', 'merchantFilterID');
	});

	const closeExportDatePopup = () => {
		setIsShowExportDatePopup(false);
	}

	useEffect(() => {
		const dataSource = getDataSource(getSettlementList());
		setSettlementSource(dataSource);
		window.sessionStorage.removeItem('filter');
	}, []);

	const toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<span className={'recent-operation-text'}>
					{TRANSACTION.TEXT.FILTERS}
				</span>
			</div>
		);
	};
	const onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.push(
			{
				location: 'before',
				template: 'customToolbar'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'filter',
					onClick: () => {
						setShowFilter(!showFilter);
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'clearsquare',
					onClick: () => {
						if (gridRef.current) {
							gridRef.current.instance.clearFilter()
						}
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					onClick: () => {
						setIsShowExportDatePopup(!isShowExportDatePopup);
					}
				},
				location: 'after'
			},
		);
	};

	const [actionRow, setActionRow] = useState(initialActionRow);
	const [rights] = useState(() => {
		return getFromLocalStorage('userSession', 'Rights');
	});
	const [confirmData, setConfirmData] = useState(initialConfirm);
	const handleClose = () => {
		const dataSource = getDataSource(getSettlementList());

		setConfirmData(initialConfirm);
		setActionRow(initialActionRow);
		setSettlementSource(dataSource);
	};

	return (
		<div className={'page-component-wrapper'}>
			<AdminPopup
				key={confirmData.title}
				title={confirmData.title}
				logo={false}
				handleClose={handleClose}
				visible={!!confirmData.operation}
				maxWidth={800}
			>
				<div className={'add-comment-wrapper'}>
					{actionRow.row ? (
						<div className="field-info">
							<div>{`Клиент: ${actionRow.row.data.MerchantName}`}</div>
							<div>{`Счёт: ${actionRow.row.data.AccountCode}`}</div>
							<div>{`Профиль: ${actionRow.row.data.MerchantApiProfileName}`}</div>
							<div>{`Период: ${formatDate(actionRow.row.data.BeginPeriodDate, true)} - ${formatDate(actionRow.row.data.EndPeriodDate, true)}`}</div>
						</div>
					) : null}

					<div className="field-label">{'Примечание'}</div>
					<TextArea
						height={120}
						text={confirmData.remark}
						onValueChanged={({value}) => {
							setConfirmData({
								...confirmData,
								remark: value,
							});
						}}
					/>
					<Button id={'add-comment-btn'} text={'Ok'} onClick={async () => {
						const request = await apiRequest({
							operation: confirmData.operation,
							data: {
								Params: {
									SettlementID: actionRow.row.data.ID,
									Remark: confirmData.remark,
								}
							}
						});

						if (request.data.ResponseCode === SUCCESS) {
							notifyApp('Success', NOTIFY.SUCCESS);
							handleClose();
						} else {
							notifyApp(request.data.ResponseText);
						}
					}}/>
				</div>
			</AdminPopup>
			<Popover
				visible={!!actionRow.row}
				target={actionRow.target}
				onHiding={() => {
					setActionRow(initialActionRow);
				}}
			>
				<Button onClick={() => {
					window.sessionStorage.setItem('filter', JSON.stringify({
						settlementID: actionRow.row.data.ID
					}));
					history.push(menuItems.checkout[1].url);
				}}>
					Показать платежи
				</Button>
				<Button
					visible={isVisibleSettlementBtn(rights, '31', actionRow)}
					onClick={() => {
						const title = `Выплатить сетлмент: ID ${actionRow.row.data.ID}, сумма ${actionRow.row.data.Amount}, валюта ${actionRow.row.data.CurrencyCode}`;
						setConfirmData({remark: '', operation: 'Settlement/Pay', title: title});
					}}>
					Выплатить
				</Button>
				<Button
					visible={isVisibleSettlementBtn(rights, '32', actionRow)}
					onClick={() => {
						const title = `Отметить как выплаченный: ID ${actionRow.row.data.ID}, сумма ${actionRow.row.data.Amount}, валюта ${actionRow.row.data.CurrencyCode}`;
						setConfirmData({remark: '', operation: 'Settlement/CloseWithoutPay', title: title});
					}}>
					Закрыть без выплаты
				</Button>
			</Popover>
			<DataGrid
				ref={gridRef}
				id={'grid-acc-statement'}
				className={classNames('dx-card wide-card', {
					'filter-row-visible': showFilter
				})}
				onToolbarPreparing={onToolbarPreparing}
				height={'100%'}
				dataSource={settlementSource}
				onContentReady={({component, element}) => {
					getAppliedFilters(component, element);
				}}
			>
				<RemoteOperations
					paging
					filtering
				/>
				<StateStoring
					enabled={true}
					type="localStorage"
					storageKey={SETTLEMENT_PAGE}
					savingTimeout={100}
				/>
				<Paging enabled defaultPageSize={50}/>
				<FilterRow visible={true}/>
				<FilterPanel visible={true}/>
				<Scrolling
					mode={'infinite'}
					showScrollbar='onHover'
				/>
				<Column
					dataField={'InsDate'}
					caption={'Дата'}
					dataType={'datetime'}
					format={'dd.MM.yy, HH:mm:ss'}
				/>
				<Column dataField={'ID'} caption={'ID'}/>
				<Column dataField={'MerchantID'} caption={'Мерчант'}/>
				<Column dataField={'MerchantApiProfileID'} caption={'ID профиля'}/>
				<Column
					dataField={'BeginPeriodDate'}
					caption={'Начало периода'}
					dataType={'datetime'}
					format={'dd.MM.yy'}
				/>
				<Column
					dataField={'EndPeriodDate'}
					caption={'Конец периода'}
					dataType={'datetime'}
					format={'dd.MM.yy'}
				/>
				<Column
					dataField={'SettlementDate'}
					caption={'Дата выплаты'}
					dataType={'datetime'}
					format={'dd.MM.yy'}
				/>
				<Column dataField={'StatusName'} caption={'Статус'}/>
				<Column dataField={'ErrorText'} caption={'Ошибка'}/>
				<Column dataField={'Amount'} caption={'Сумма'}>
					<Format
						type={'fixedPoint'}
						precision={PRECISION}
					/>
				</Column>
				<Column dataField={'CurrencySymbol'} caption={'Валюта (символ)'}/>
				<Column dataField={'PaymentID'} caption={'Payment ID'}/>
				<Column
					type={'buttons'}
					caption={'Действия'}
				>
					<GridButton text={'Действия'} onClick={(e) => {
						console.log(e);
						setActionRow({
							row: e.row,
							target: e.event.target,
						});
					}}/>
				</Column>
				<Template name={'customToolbar'} render={toolbarItemRender}/>
			</DataGrid>
			<ExportDataGrid
				ref={gridRef}
				exportFileName={'SettlementExport'}
				getGridParams={getSettlementList}
				isShowExportDatePopup={isShowExportDatePopup}
				closeExportDatePopup={closeExportDatePopup}
			/>
		</div>
	);
};

export default withRouter(Settlement);


