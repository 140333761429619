export const vars = {
	APP_CODES: {
		SUCCESS: '000',
	},
	CLIENT_PATH: '/clients/clients',
	CLIENT_DETAIL_PATH: '/clients/clients/detail',
	REFERRER_DETAIL_PATH: '/clients/referrers/detail',
	REFERRER_PATH: '/clients/referrers',
	PATH_KEY: {
		CLIENTS: 'clients',
		CHECKOUT: 'checkout',
		SETTINGS: 'settings',
	},
	NOTIFY: {
		ERROR: 'error',
		SUCCESS: 'success',
	},
	LOGIN: {
		TEXT: {
			AFTER_NEW_PASSWORD: 'Password must contain 8 symbols with uppercase, lowercase and digits',
			NEW_PASSWORD_PLACEHOLDER: 'New password',
			NEW_PASSWORD_REQUIRED_ERROR: 'New password is required',
		}
	},
	TRANSACTION: {
		STATUS_COLOR: {
			GREEN: 'green-status',
			RED: 'red-status',
			BLACK: 'black-status',
			OTHER: 'blue-status',
		},
		RIGHTS: {
			REPLAY: '7',
			ACCEPT: '3',
			DECLINE: '4',
		},
		SPECIAL_BO_PAYMENT_TYPE_ID: 3,
		RECHECK_PAYMENT_STATUS_ID: 5,
		SPECIAL_PAYMENT_STATUS_ID: 8,
		TEXT: {
			FILTERS: 'Фильтры'
		},
		REPLAY: 'replay',
	},
	MERCHANT: {
		TRANSACTIONS_PATH: '/clients/transactions',
		RIGHTS: {
			INCOME: '6',
			PAYOUT: '5',
			IS_BAN_RESTRICT: '9',
			IS_BAN_REMOVE_RESTRICT: '10',
			IS_ADD_ACCOUNT_RESTRICT: '11',
			IS_ADD_ACCOUNT_REMOVE_RESTRICT: '12',
			ARREST: '13',
			REMOVE_ARREST: '14',
			RESTRICTION_HISTORY: '15',
			CANCEL_ACCOUNT: '18',
			CHANGE_VERIFICATION_VISIBLE: '20',
			CREATE_ALLOWED_ACCOUNT: '24',
			CREATE_ACCOUNT: '25',
		},
		ACCOUNT_STATUS: {
			IS_ACTIVE: 0,
			IS_ARRESTED: 1,
		},
		ARREST_ACCOUNT_OBJECT: 'Account',
		COMMON_RESTRICTION_OBJECT: 'Merchant',
		CANCEL_ACCOUNT_OPERATION: 'WallettoCard/FullBlock',
		ACCOUNTS_PATH: '/clients/accounts',
		PROFILES_PATH: '/checkout/profiles',
		PROFILE_DETAIL_PATH: '/checkout/profiles/detail',
	},
	WALLETTO_ACCOUNT_ID: 11,
	SHARPAY_IBAN_ID: 12,
	BO_PAYMENT_TYPE__INCOME_CHECKOUT: 7,
	STATE_STORING_KEYS: {
		CLIENTS: {
			CLIENTS_PAGE: 'clients-page',
			TRANSACTIONS_PAGE: 'transactions-page',
			ACCOUNTS_PAGE: 'accounts-page',
			CARDS_OPERATIONS_PAGE: 'cards-operations-page',
			IBAN_OPERATIONS_PAGE: 'iban-operations-page',
		},
		MERCHANTS: {
			ORDERS_PAGE: 'orders-page',
			PAYMENTS_PAGE: 'payments-page',
			SETTLEMENT_PAGE: 'settlement-page',
			PROFILES_PAGE: 'profiles-page',
		},
	},
	HIGHLIGHT_COLUMN_CLASS: 'column-with-filter',
	PRECISION: 2,
	PAY_RETAILS: 14,
	CHECKOUT_REFILL_ID: 6,
	SWIFT_PAYMENT_SYSTEM_TYPE_ID: 16,
	PROFILE_DETAIL_STATE_KEY: 'profile-detail-state',
	VALIDATION: {
		REQUIRED_TEXT: 'Поле обязательное',
		ONLY_LATIN_TEXT: 'Только латинские символы',
		ISO_ALPHA3_TEXT: '3-х буквенный код, напр. \'EST\'',
	},
	COMPACT_BOX_WIDTH: 340,
	MERCHANT_COLUMNS: [
		'ID', 'InsDate', 'Name', 'Country', 'UserTypeID', 'UserTypeName', 'UserStatusName',
		'UserStatusID', 'VmVerificationStatusName', 'VmVerificationStatusID', 'LastActivityDate',
		'TotalBalance', 'UserID', 'UserIsBan',
	],
	ROLLING_COLUMNS: [
		'RollingPaymentID', 'InsDate', 'MerchantID', 'MerchantName', 'MerchantApiProfileID', 'MerchantApiProfileName',
		'RollingReleaseDate', 'RollingStatusName', 'Amount', 'CurrencyID', 'CurrencySymbol', 'CurrencyCode',
		'MerchantOrderID', 'PaymentID', 'IsManualPay', 'RollingStatus', 'AccountID', 'AccountCode', 'Remark',
		'BoUserID', 'BoUserName',
	],
	CRYPTO_ADDR_COLUMNS: [
		'Urlid', 'InsDate', 'MerchantID', 'AccountCode', 'AccountCurrencyCode', 'MerchantOrderID', 'PaymentCount',
		'CurrencyCode', 'Address', 'CurrencyCode', 'BlockchainCode', 'Memo', 'ExternalID', 'AddressStatus', 'ExpiryDate',
	],
};
