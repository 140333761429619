import React from 'react';
import Button from 'devextreme-react/button';
import {withRouter} from 'react-router-dom';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import {NavLink} from "react-router-dom";
import routes from '../../app-routes';

class Header extends React.PureComponent {
	render() {
		const {userMenuItems, location} = this.props;

		const activePageId = location.pathname.split('/')[1];

		return (
			<header className={'header-component'}>
				<div className={'header-menu-wrapper'}>
					<div className={'box left-content'}>
						{/*<div className={'top-logo-wrapper'}>
							<a href="/">
								<img className={'main-top-logo'} src="images/main-top.svg" alt=""/>
							</a>
						</div>*/}
						<div className={'top-menu-wrapper'}>
							{routes.map((props) => {
								if (props.hidden) return;
								return (
									<NavLink
										key={props.id}
										id={props.id}
										activeClassName={props.id === activePageId ? 'selected' : ''}
										to={props.url}
									>
										{props.title}
									</NavLink>
								);
							})}
						</div>
					</div>
					<div className={'box center-content'}>

					</div>

					<div className={'box right-content'}>
						<Button
							className={'user-button authorization'}
							focusStateEnabled={false}
							activeStateEnabled={false}
							hoverStateEnabled={false}
							height={'100%'}
							stylingMode={'text'}
						>
							<UserPanel menuItems={userMenuItems} menuMode={'context'}/>
						</Button>
					</div>
				</div>
			</header>
		);
	}
}

export default withRouter(Header);
