import React from 'react';
import {Offset, Popup, Position} from 'devextreme-react/popup';
import {ReactSVG} from "react-svg";
import './admin-popup.scss';
import {ScrollView} from 'devextreme-react';

export default class AdminPopup extends React.PureComponent {
	render() {
		const {
			visible,
			container = null,
			handleClose,
			title = null,
			logo = true,
			maxWidth = 500,
			minHeight = 315,
			maxHeight = '80%',
			height = 'auto',
			offsetY = 0,
			children
		} = this.props;

		return (
			<div className={'admin-popup-wrapper'}>
				<Popup
					visible={visible}
					container={container}
					dragEnabled={false}
					closeOnOutsideClick={false}
					showTitle={true}
					minHeight={minHeight}
					maxHeight={maxHeight}
					maxWidth={maxWidth}
					height={height}
					onHiding={handleClose}
					title={title}
				>
					<Position>
						<Offset x={0} y={offsetY} />
					</Position>
					<ScrollView
						className={'scroll-view'}
					>
						<div className={'admin-popup-content'}>
							{logo ? (
								<div className={'admin-popup-logo'}>
									<ReactSVG src={'./images/logo-flexible-pay.svg'}/>
								</div>
							) : logo}
							<div className={'admin-popup-component'}>
								{children}
							</div>
						</div>
					</ScrollView>
				</Popup>
			</div>
		);
	}
}
