import React, {useEffect, useState, useRef} from 'react';
import DataGrid, {
	Column,
	Paging,
	Scrolling,
	RemoteOperations,
} from 'devextreme-react/data-grid';
import {getReferralList} from 'services/requestConsts';
import {getDataSource} from 'services/dataSource';
import {Button, DateBox, Popover} from 'devextreme-react';
import {
	fileDateTextFormat,
	firstDateOfMonth,
	lastDateOfMonth,
	prepareDatesForGridExport
} from 'services/dataSourceFilterUtils';
import {vars} from 'utils/variables';
import {useHistory} from 'react-router-dom';
import {menuItems} from 'app-routes';
import PageMenu from 'components/navigation-menu/page-menu';

const {CLIENT_PATH, REFERRER_PATH, PATH_KEY: {CLIENTS}} = vars;

const ReferrerDetail = (props) => {
	const history = useHistory();
	const exportRef = useRef(null);
	const [isShowExportPopup, setIsShowExportPopup] = useState(false);
	const [detailDs, setDetailDs] = useState(null);
	const [exportDetailDs, setExportDetailDs] = useState(null);
	const [referrerUserID] = useState(() => {
		return props.location.state ? props.location.state.referrerUserID : null;
	})

	const [startDate, setStartDate] = useState({
		value: firstDateOfMonth(),
		text: fileDateTextFormat(firstDateOfMonth()),
	});
	const [endDate, setEndDate] = useState({
		value: lastDateOfMonth(),
		text: fileDateTextFormat(lastDateOfMonth()),
	});

	useEffect(() => {
		if (referrerUserID === null) {
			history.replace(REFERRER_PATH);
		}
	}, [referrerUserID]);

	useEffect(() => {
		if (!detailDs) {
			const params = getReferralList({
				ReferrerUserID: referrerUserID,
			});
			const ds = getDataSource(params);
			setDetailDs(ds);
		}
	}, [detailDs]);

	const onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.push(
			{
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					onClick: () => {
						setIsShowExportPopup(!isShowExportPopup);
					}
				},
				location: 'after'
			}
		);
	}

	const exportData = () => {
		const params = getReferralList({
			ReferrerUserID: referrerUserID,
			InsDate: {
				between: prepareDatesForGridExport(startDate.value, endDate.value),
			},
		});

		const ds = getDataSource(params);
		setExportDetailDs(ds);
	}

	const makeExcelReport = () => {
		if (exportDetailDs) {
			exportRef.current.instance.exportToExcel();
			setExportDetailDs(null);
		}
	}

	const onExporting = (e) => {
		let dateStr = startDate.text;

		if (startDate.text !== endDate.text) {
			dateStr = startDate.text + '-' + endDate.text;
		}

		e.fileName = `Referrals(${dateStr})`;
	}

	return (
		<div className={'clients-wrapper'}>
			<div className={'admin-navigation-menu'}>
				<PageMenu
					items={menuItems[CLIENTS]}
				/>
			</div>
			<div
				className={'active-component-wrapper'}
			>
				<div className={'page-component-wrapper'}>
					<DataGrid
						id={'grid-acc-statement'}
						alignment={'center'}
						className={'dx-card wide-card'}
						dataSource={detailDs}
						onToolbarPreparing={onToolbarPreparing}
					>
						<RemoteOperations
							paging
							filtering
						/>
						<Paging enabled defaultPageSize={50}/>
						<Scrolling
							mode={'infinite'}
							showScrollbar='onHover'
						/>
						<Column
							dataField={'ID'}
							caption={'ID клиента'}
							width={100}
						/>
						<Column
							dataField={'InsDate'}
							dataType={'datetime'}
							caption={'Дата регистрации'}
							format={'dd.MM.yy, HH:mm:ss'}
							width={150}
						/>
						<Column
							dataField={'Name'}
							caption={'Клиент'}
						/>
						<Column
							dataField={'UserTypeName'}
							caption={'Тип клиента'}
							width={100}
						/>
						<Column
							dataField={'Country'}
							caption={'Страна клиента'}
							width={120}
						/>
						<Column
							dataField={'UserStatusName'}
							caption={'Статус регистрации'}
							width={150}
						/>
						<Column
							dataField={'VmVerificationStatusName'}
							caption={'Статус верификации'}
							width={150}
						/>
						<Column
							dataField={'UserIsBan'}
							caption={'Заблокирован'}
							dataType={'boolean'}
							trueText={'Да'}
							falseText={'Нет'}
							width={150}
						/>
						<Column
							dataField={'AllIncome'}
							caption={'Оборот'}
						/>
						<Column
							dataField={'PeriodIncome'}
							caption={'Оборот за период'}
						/>
						<Column
							dataField={'LastActivityDate'}
							caption={'Последняя активность'}
							dataType={'datetime'}
							format={'dd.MM.yy, HH:mm:ss'}
							width={150}
						/>
						<Column
							dataField={'ReferrerName'}
							caption={'Пригласивший'}
						/>
						<Column
							caption={'Быстрый просмотр'}
							type={'buttons'}
							buttons={[
								{
									hint: 'Просмотреть',
									text: 'Просмотреть',
									onClick: ({row}) => {
										const value = {
											accountFilterID: row.data.ID
										};

										window.sessionStorage.setItem('filter', JSON.stringify(value));

										history.push(CLIENT_PATH);
									}
								},
							]}
						/>
					</DataGrid>
					<DataGrid
						id={'grid-acc-statement-export'}
						dataSource={exportDetailDs}
						visible={false}
						ref={exportRef}
						onContentReady={makeExcelReport}
						onExporting={onExporting}
					>
						<Column
							dataField={'ID'}
							caption={'ID клиента'}
						/>
						<Column
							dataField={'InsDate'}
							caption={'Дата регистрации'}
						/>
						<Column
							dataField={'Name'}
							caption={'Клиент'}
						/>
						<Column
							dataField={'UserTypeName'}
							caption={'Тип клиента'}
						/>
						<Column
							dataField={'Country'}
							caption={'Страна клиента'}
						/>
						<Column
							dataField={'UserStatusName'}
							caption={'Статус регистрации'}
						/>
						<Column
							dataField={'VmVerificationStatusName'}
							caption={'Статус верификации'}
						/>
						<Column
							dataField={'UserIsBan'}
							caption={'Заблокирован'}
							dataType={'boolean'}
							trueText={'Да'}
							falseText={'Нет'}
						/>
						<Column
							dataField={'AllIncome'}
							caption={'Оборот'}
						/>
						<Column
							dataField={'PeriodIncome'}
							caption={'Оборот за период'}
						/>
						<Column
							dataField={'LastActivityDate'}
							caption={'Последняя активность'}
						/>
						<Column
							dataField={'ReferrerName'}
							caption={'Пригласивший'}
						/>
					</DataGrid>
					<div id={'export-wrapper'}>
						<Popover
							target={'.dx-toolbar-after'}
							visible={isShowExportPopup}
							onHiding={() => {
								setIsShowExportPopup(false);
							}}
						>
							<div className={'export-fields'}>
								<div className="dx-field">
									<div className="dx-field-label">С</div>
									<div className="dx-field-value">
										<DateBox
											value={startDate.value}
											max={endDate.value}
											useMaskBehavior={true}
											displayFormat={'dd.MM.yy'}
											onValueChanged={({value}) => {
												setStartDate({
													value: value,
													text: fileDateTextFormat(value),
												});
											}}
										/>
									</div>
								</div>
								<div className="dx-field">
									<div className="dx-field-label">По</div>
									<div className="dx-field-value">
										<DateBox
											value={endDate.value}
											min={startDate.value}
											useMaskBehavior={true}
											displayFormat={'dd.MM.yy'}
											onValueChanged={({value}) => {
												setEndDate({
													value: value,
													text: fileDateTextFormat(value),
												});
											}}
										/>
									</div>
								</div>
								<div className={'export-button'}>
									<Button text={'Экспорт'} onClick={exportData}/>
								</div>
							</div>
						</Popover>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ReferrerDetail;