import React from 'react';
import AdminPopup from 'components/popup/admin-popup';
import {Template} from 'devextreme-react/core/template';
import DataGrid, {
	Column,
	Paging,
	FilterRow,
	FilterPanel,
	Scrolling,
	RemoteOperations,
	StateStoring,
	Format,
} from 'devextreme-react/data-grid';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {getWallettoCardsList} from 'services/requestConsts';
import {getDataSource} from 'services/dataSource';
import {createFilter} from 'utils/customFilters';
import {getAppliedFilters, valueToArray} from 'utils/functions';
import IbanTransactionDetails from './iban-transaction-details';
import classNames from 'classnames/bind';
import {vars} from 'utils/variables';

const {
	TRANSACTION,
	SHARPAY_IBAN_ID,
	STATE_STORING_KEYS: {CLIENTS: {IBAN_OPERATIONS_PAGE}},
	PRECISION,
} = vars;

class Iban extends React.Component {
	constructor(props) {
		super(props);
		this.gridRef = React.createRef();

		this.state = {
			cards: null,
			showFilter: false,
			ID: null,
			actionType: null,
			isShowExportDatePopup: false,
		};

		this.filterOptions = {};
	}

	componentDidMount() {
		this.getCardsList().catch((e) => {
			console.warn(e);
		});
	}

	getCardsList = async () => {
		const cards = getDataSource(getWallettoCardsList(SHARPAY_IBAN_ID));
		this.setState({cards: cards});
	};

	toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<span className={'recent-operation-text'}>
					{TRANSACTION.TEXT.FILTERS}
				</span>
			</div>
		);
	};

	showFilterHandler = () => {
		const {showFilter} = this.state;
		this.setState({
			showFilter: !showFilter,
		})
	};

	onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.push(
			{
				location: 'before',
				template: 'customToolbar'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'filter',
					onClick: this.showFilterHandler
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'clearsquare',
					onClick: () => {
						if (this.gridRef.current) {
							this.gridRef.current.instance.clearFilter()
						}
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					onClick: () => {
						const {isShowExportDatePopup} = this.state;
						this.setState({isShowExportDatePopup: !isShowExportDatePopup})
					}
				},
				location: 'after'
			},
		);
	};

	onEditorPreparing = (e) => {
		const {dataField, editorElement, parentType} = e;

		if (this.filterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = this.filterOptions[dataField];
			e.cancel = true;

			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					value: valueToArray(e.value),
					parentWidth: editorElement.clientWidth,
					onValueChanged: e.editorOptions.onValueChanged
				}
			}));
		}
	};

	closePopup = () => {
		this.setState({
			ID: null,
		});
	};

	closeExportDatePopup = () => {
		this.setState({isShowExportDatePopup: false});
	}

	render() {
		const {cards, showFilter, ID, isShowExportDatePopup} = this.state;

		return (
			<div className={'page-component-wrapper'}>
				<AdminPopup
					logo={false}
					handleClose={this.closePopup}
					visible={!!ID}
					title={'Детальная информация'}
					maxWidth={700}
				>
					<IbanTransactionDetails ID={ID}/>
				</AdminPopup>
				<DataGrid
					id={'grid-acc-statement'}
					alignment={'center'}
					className={classNames('dx-card wide-card', {
						'filter-row-visible': showFilter
					})}
					ref={this.gridRef}
					dataSource={cards}
					hoverStateEnabled={true}
					showBorders={false}
					focusedRowEnabled={false}
					columnHidingEnabled={true}
					onEditorPreparing={this.onEditorPreparing}
					onToolbarPreparing={this.onToolbarPreparing}
					height={'100%'}
					onContentReady={({component, element}) => {
						getAppliedFilters(component, element);
					}}
					onRowDblClick={(row) => {
						this.setState({ID: row.data.ID});
					}}
				>
					<RemoteOperations
						paging
						filtering
					/>
					<StateStoring
						enabled={true}
						type="localStorage"
						storageKey={IBAN_OPERATIONS_PAGE}
						savingTimeout={100}
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={true}/>
					<FilterPanel visible={true}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar='onHover'
					/>
					<Column
						caption={'Дата'}
						dataField={'InsDate'}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
					/>
					<Column
						caption={'Обновлено'}
						dataField={'LastUpdate'}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
					/>
					<Column caption={'ID'} dataField={'ID'}/>
					<Column caption={'IBAN'} dataField={'AccountCode'}/>
					<Column caption={'Сумма операции'} dataField={'OriginalAmount'}>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column caption={'Валюта операции'} dataField={'OriginalCurrencyCode'} width={140}/>
					<Column caption={'Курс'} dataField={'ConversionRate'} width={80}/>
					<Column caption={'Сумма'} dataField={'Amount'}>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column caption={'Валюта'} dataField={'AccountCurrencyCode'} width={75}/>
					<Column caption={'Статус'} dataField={'PaymentStatusName'}/>
					<Column
						caption={'Детали'}
						type={'buttons'}
						buttons={[
							{
								hint: 'Открыть',
								text: 'Открыть',
								onClick: ({row}) => {
									this.setState({ID: row.data.ID});
								}
							},
						]}
					/>
					<Template name={'customToolbar'} render={this.toolbarItemRender}/>
				</DataGrid>
				<ExportDataGrid
					ref={this.gridRef}
					exportFileName={'IBANOperationsExport'}
					getGridParams={getWallettoCardsList}
					isShowExportDatePopup={isShowExportDatePopup}
					closeExportDatePopup={this.closeExportDatePopup}
				/>
			</div>
		);
	}
}

export default Iban;
