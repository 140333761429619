import React from 'react';
import {getDefaultPageRoute, getMenuComponent, menuItems} from 'app-routes';
import PageMenu from 'components/navigation-menu/page-menu';
import {useLocation, useHistory} from 'react-router-dom';
import {vars} from 'utils/variables';
import {getFromLocalStorage} from 'utils/functions';
import UnderConstruction from '../under-construction/under-construction';

const {PATH_KEY: {SETTINGS}} = vars;

const filterMenuItems = (items, rights) => {
	return items.filter((item) => {
		return rights.includes(item.accessRight);
	});
}

const Settings = () => {
	const rights = getFromLocalStorage('userSession', 'Rights');
	console.log('rights');
	console.log(rights);

	const isShowNavLink = rights.includes('43');
	const history = useHistory();
	const location = useLocation();
	const activePageId = location.pathname.split('/')[2];
	let ActiveComponent = null;

	if (activePageId) {
		ActiveComponent = getMenuComponent(SETTINGS, activePageId);
	} else {
		history.push(getDefaultPageRoute(SETTINGS));
	}

	return <div>
		<UnderConstruction/>
	</div>

	return (
		<div className={'checkout-wrapper'}>
			<div className={'admin-navigation-menu'}>
				<PageMenu
					items={filterMenuItems(menuItems[SETTINGS], rights)}
				/>
			</div>
			<div
				className={'active-component-wrapper'}
			>
				{activePageId ? (
					<ActiveComponent/>
				) : null}
			</div>
		</div>
	);
}

export default Settings;