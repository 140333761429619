import React from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow,
	Format,
	Paging,
	RemoteOperations,
	Scrolling,
	StateStoring,
} from 'devextreme-react/data-grid';
import {
	capitalizeFirstLetter,
	getAppliedFilters,
	getFromLocalStorage,
	getFromSessionStorage,
	loadGridSettings,
	saveGridSettings,
	valueToArray,
} from 'utils/functions';
import {createFilter, makeBooleanFilterExpression, makeCalculateFilterExpression} from 'utils/customFilters';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {getMerchantsList} from 'services/requestConsts';
import {Template} from 'devextreme-react/core/template';
import AdminPopup from 'components/popup/admin-popup';
import {getDataSource} from 'services/dataSource';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest, updateSelectedMerchantDetails} from 'services/async';
import classNames from 'classnames/bind';
import {withRouter} from 'react-router-dom';
import {vars} from 'utils/variables';

import './clients-page.scss';

const {
	TRANSACTION,
	STATE_STORING_KEYS: {CLIENTS: {CLIENTS_PAGE}},
	PRECISION,
	CLIENT_DETAIL_PATH,
} = vars;

const getTitle = (actionType, {ID = null, Name = null}) => {
	return `Детальная информация о клиенте ID ${ID} ${Name}`;
};

class ClientsPage extends React.Component {
	constructor(props) {
		super(props);
		this.userRights = getFromLocalStorage('userSession', 'Rights');
		const accountFilterID = getFromSessionStorage('filter', 'accountFilterID');
		window.sessionStorage.removeItem('filter');
		this.gridRef = React.createRef();
		this.state = {
			merchants: [],
			dictionariesData: null,
			showFilter: !!accountFilterID,
			popupFields: null,
			rowData: {
				ID: null,
				Name: null,
				UserName: null,
			},
			actionType: null,
			accountFilterID: accountFilterID,
			isShowExportDatePopup: false,
		};

		this.filterOptions = {
			UserTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('UserTypeID'),
				options: {
					object: 'UserType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
			UserStatusName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('UserStatusID'),
				options: {
					object: 'UserStatus',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
			VmVerificationStatusName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('VmVerificationStatusID'),
				options: {
					object: 'VmVerificationStatus',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
		}
	}

	componentDidMount() {
		this.getMerchantList().catch((e) => {
			console.warn(e);
		});
	}

	getMerchantList = async () => {
		const paramObj = getMerchantsList();
		const merchants = getDataSource(paramObj);
		const dictionariesData = await this.getDictionariesData();
		this.setState({
			merchants,
			dictionariesData,
		});
	};


	getDictionariesData = async () => {
		const userTypes = await apiRequest({
			operation: 'UserType/List',
		});

		const userStatus = await apiRequest({
			operation: 'UserStatus/List',
		});

		const vmVerificationStatus = await apiRequest({
			operation: 'VmVerificationStatus/List',
		});

		const {Response: userTypesResponse} = userTypes.data;
		const {Response: userStatusResponse} = userStatus.data;
		const {Response: vmVerificationStatusResponse} = vmVerificationStatus.data;

		return {
			UserTypeName: userTypesResponse && userTypesResponse['UserType'],
			UserStatusName: userStatusResponse && userStatusResponse['UserStatus'],
			VmVerificationStatusName: vmVerificationStatusResponse && vmVerificationStatusResponse['VmVerificationStatus'],
		};
	};

	updateSelectedMerchant = async (id) => {
		let merchant = null;

		try {
			merchant = await updateSelectedMerchantDetails(id);
			if (merchant) {
				merchant.UserRights = this.userRights;
			}
			this.setState({
				rowData: merchant,
			});
		} catch (e) {
			notifyApp(e);
		}

		return merchant;
	};

	gridButtonHandler = (actionType, component, rowData) => {
		this.setState({
			popupFields: component,
			rowData,
			actionType,
		});
	};

	onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.push(
			{
				location: 'before',
				template: 'customToolbar'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'filter',
					onClick: this.showFilterHandler
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'clearsquare',
					onClick: () => {
						if (this.gridRef.current) {
							this.gridRef.current.instance.clearFilter()
						}
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'add',
					text: 'Создать клиента',
					stylingMode: 'contained',
					disabled: !(this.userRights.includes('33')),
					onClick: () => {
						const {history} = this.props;
						history.push({
							pathname: '/clients/client/create',
							state: {}
						});
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					onClick: () => {
						const {isShowExportDatePopup} = this.state;
						this.setState({isShowExportDatePopup: !isShowExportDatePopup})
					}
				},
				location: 'after'
			},
		);
	}

	toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<span className={'recent-operation-text'}>
					{TRANSACTION.TEXT.FILTERS}
				</span>
			</div>
		);
	};

	showFilterHandler = () => {
		const {showFilter} = this.state;
		this.setState({
			showFilter: !showFilter,
		})
	};

	closePopup = () => {
		this.setState({
			popupFields: null,
		});
	};

	onEditorPreparing = (e) => {
		const {dataField, editorElement, parentType} = e;

		if (this.filterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = this.filterOptions[dataField];
			e.cancel = true;
			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					value: valueToArray(e.value),
					parentWidth: editorElement.clientWidth,
					onValueChanged: e.editorOptions.onValueChanged
				}
			}));
		}
	};

	saveClientSettings = (settings) => {
		saveGridSettings(settings, CLIENTS_PAGE);
	};

	loadClientSettings = () => {
		const {accountFilterID} = this.state;
		return loadGridSettings(accountFilterID ? {ID: accountFilterID} : null, CLIENTS_PAGE);
	};

	parseDictionary = (dictionariesData, filterValue, label) => {
		let field = '';

		filterValue[2].forEach((valueId) => {
			if (!dictionariesData[filterValue[0]]) {
				return field;
			}

			const entityDictionary = dictionariesData[filterValue[0]].find((item) => {
				return item.ID === valueId;
			});

			const {Name: val} = entityDictionary;
			field += field ? `, ${val}` : val;
		});

		return `${label} Equals(${field})`;
	};

	editFilterPanelText = ({component, filterValue, text}) => {
		/*
		* filter ['', '', ''] -- one filter not dictionary
		* filter ['', '', []] -- one filter dictionary
		* filter [[], '', []] -- several column filters
		*/

		const {dictionariesData} = this.state;
		let resultString = '';
		const regExp = /\[(.*?)]/gm;
		const label = text.match(regExp);

		if (dictionariesData && Array.isArray(filterValue[2])) {

			if (filterValue[2].length === 0) {
				component.clearFilter();
			} else {
				if (Array.isArray(filterValue[0])) {
					// several column filters
					console.log('several');

					// every odd element is filter
					filterValue.forEach((filter, index) => {
						if (index % 2 === 0) {
							if (Array.isArray(filter[2]) && filter[2].length === 0) {
								let updatedFilters = filterValue.filter((item) => {
									return item[0] !== filter[0];
								});

								if (updatedFilters.length % 2 === 0) {
									if (Array.isArray(updatedFilters[0])) {
										updatedFilters = updatedFilters.slice(0, updatedFilters.length - 1);
									} else {
										updatedFilters = updatedFilters.slice(1);
									}
								}

								if (updatedFilters.length === 1) {
									updatedFilters = updatedFilters[0];
								}

								component.option('filterValue', updatedFilters);
							}

							console.log(filter[0]);
							console.log(text);

							// ['das', 'sdsa' ,'jggh']
							if (Array.isArray(filter[2])) {
								const filterStr = this.parseDictionary(dictionariesData, filter, label[index / 2]);
								resultString += resultString ? ` And ${filterStr}` : filterStr;
							} else {
								const labelText = label[index / 2];
								const operation = capitalizeFirstLetter(filter[1]);
								const resStr = `${labelText} ${operation} '${filter[2]}'`;
								resultString += resultString ? ` And ${resStr}` : resStr;
							}
						}
					});


				} else {
					// one filter dictionary
					resultString = this.parseDictionary(dictionariesData, filterValue, label[0]);
				}
			}
		}

		return resultString ? resultString : text;
	};

	closeExportDatePopup = () => {
		this.setState({isShowExportDatePopup: false});
	}

	render() {
		const {
			merchants,
			showFilter,
			popupFields,
			rowData,
			actionType,
			isShowExportDatePopup,
		} = this.state;
		const {history} = this.props;
		const PopupFields = popupFields;

		return (
			<div className={'page-component-wrapper'}>
				<AdminPopup
					logo={false}
					handleClose={this.closePopup}
					visible={!!popupFields}
					title={getTitle(actionType, rowData)}
					maxWidth={700}
				>
					{popupFields ? (
						<PopupFields
							rowData={rowData}
							actionType={actionType}
							gridButtonHandler={this.gridButtonHandler}
							closePopup={this.closePopup}
							updateMerchantsList={this.getMerchantList}
							updateSelectedMerchant={this.updateSelectedMerchant}
						/>
					) : null}
				</AdminPopup>
				<DataGrid
					id={'grid-acc-statement'}
					alignment={'center'}
					className={classNames('dx-card wide-card', {
						'filter-row-visible': showFilter
					})}
					filterSyncEnabled={true}
					ref={this.gridRef}
					dataSource={merchants}
					hoverStateEnabled={true}
					showBorders={false}
					focusedRowEnabled={false}
					columnHidingEnabled={true}
					onEditorPreparing={this.onEditorPreparing}
					onToolbarPreparing={this.onToolbarPreparing}
					height={'100%'}
					onContentReady={({component, element}) => {
						getAppliedFilters(component, element);
					}}
					onRowDblClick={(row) => {
						if (this.userRights.includes('39')) { // #89873
							history.push({
								pathname: CLIENT_DETAIL_PATH,
								state: {
									clientId: row.data.ID,
									updateMerchantsList: this.getMerchantList,
									updateSelectedMerchant: this.updateSelectedMerchant,
								}
							});
						}
					}}
				>
					{/*<Export enabled={true} allowExportSelectedData={true} fileName={'ClientsExport'}/>*/}
					<RemoteOperations
						paging
						filtering
					/>
					<StateStoring
						enabled={true}
						type='custom'
						customLoad={this.loadClientSettings}
						customSave={this.saveClientSettings}
						savingTimeout={100}
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={true}/>
					<FilterPanel
						customizeText={this.editFilterPanelText}
						visible={true}
					/>
					<Scrolling
						mode={'infinite'}
						showScrollbar='onHover'
					/>
					<Column
						visible={false}
						dataField={'UserPhone'}
						caption={'Телефон'}
					/>
					<Column
						visible={false}
						dataField={'UserEmail'}
						caption={'Email'}
					/>
					<Column
						dataField={'ID'}
						caption={'ID клиента'}
						width={100}
					/>
					<Column
						dataField={'InsDate'}
						dataType={'datetime'}
						caption={'Дата регистрации'}
						format={'dd.MM.yy, HH:mm:ss'}
						width={150}
					/>
					<Column
						dataField={'Name'}
						caption={'Клиент'}
					/>
					<Column
						dataField={'UserTypeName'}
						caption={'Тип клиента'}
						filterOperations={this.filterOptions['UserTypeName'].filterOperations}
						calculateFilterExpression={this.filterOptions['UserTypeName'].calculateFilterExpression}
						width={100}
					/>
					<Column
						dataField={'Country'}
						caption={'Страна клиента'}
						width={120}
					/>
					<Column
						dataField={'UserStatusName'}
						caption={'Статус регистрации'}
						filterOperations={this.filterOptions['UserStatusName'].filterOperations}
						calculateFilterExpression={this.filterOptions['UserStatusName'].calculateFilterExpression}
						cellRender={this.feeColumn}
						width={150}
					/>
					<Column
						dataField={'VmVerificationStatusName'}
						caption={'Статус верификации'}
						filterOperations={this.filterOptions['VmVerificationStatusName'].filterOperations}
						calculateFilterExpression={this.filterOptions['VmVerificationStatusName'].calculateFilterExpression}
						width={150}
					/>
					<Column
						dataField={'UserIsBan'}
						caption={'Заблокирован'}
						dataType={'boolean'}
						trueText={'Да'}
						falseText={'Нет'}
						calculateFilterExpression={makeBooleanFilterExpression}
						width={150}
					/>
					<Column
						dataField={'TotalBalance'}
						caption={'Баланс'}
						width={200}
					>
						<Format
							type='fixedPoint'
							precision={PRECISION}
						/>
					</Column>
					<Column
						dataField={'LastActivityDate'}
						caption={'Последняя активность'}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
						width={150}
					/>
					<Column
						caption={'Быстрый просмотр'}
						type={'buttons'}
						buttons={[
							{
								hint: 'Open',
								visible: this.userRights.includes('39'), // #89873
								text: 'Открыть',
								onClick: ({row}) => {
									history.push({
										pathname: CLIENT_DETAIL_PATH,
										state: {
											clientId: row.data.ID,
											updateMerchantsList: this.getMerchantList,
											updateSelectedMerchant: this.updateSelectedMerchant,
										}
									});
								}
							},
						]}
					/>
					<Template name={'customToolbar'} render={this.toolbarItemRender}/>
				</DataGrid>
				<ExportDataGrid
					ref={this.gridRef}
					exportFileName={'ClientsExport'}
					getGridParams={getMerchantsList}
					isShowExportDatePopup={isShowExportDatePopup}
					closeExportDatePopup={this.closeExportDatePopup}
				/>
			</div>
		);
	}
}

export default withRouter(ClientsPage);





