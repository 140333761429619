import React from 'react';
import {apiRequest} from 'services/async';
import {LoadIndicator} from 'devextreme-react/load-indicator';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';
import './info.scss';

const {SWIFT_PAYMENT_SYSTEM_TYPE_ID} = vars;

const preventNullValue = (value) => {
	return value === null ? '' : value;
}

export class Info extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			details: null,
		};
	}

	async componentDidMount() {
		const {rowData: {ID}} = this.props;
		const {APP_CODES} = vars;
		const info = await apiRequest({
			operation: 'Payment/GetDetail',
			data: {
				Params: {
					PaymentID: ID,
				}
			},
		});

		if (info.data.ResponseCode === APP_CODES.SUCCESS) {
			this.setState({
				details: info.data.Response
			});
		} else {
			notifyApp(info.data.ResponseText);
		}
	}

	render() {
		const {details} = this.state;
		const {rowData: {PaymentSystemTypeID}} = this.props;

		return details ? (
			<div className={'info-wrapper'}>
				<div className="dx-field">
					<div className="dx-field-label">{'Дата'}</div>
					<div className="dx-field-value">{details.InsDate}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'Тип операции'}</div>
					<div className="dx-field-value">{details.BoPaymentTypeName}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'Статус'}</div>
					<div className="dx-field-value">{details.PaymentStatus}</div>
				</div>


				<div className="dx-field">
					<div className="dx-caption">{'Клиент'}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'Имя / Название'}</div>
					<div className="dx-field-value">{details.MerchantAccountName}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'Счет / IBAN / Карта'}</div>
					<div className="dx-field-value">{details.MerchantAccountCode}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'Тип счета'}</div>
					<div className="dx-field-value">{details.MerchantAccountTypeName}</div>
				</div>


				<div className="dx-field">
					<div className="dx-caption">{'Контрагент'}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'Имя / Название'}</div>
					<div className="dx-field-value">{details.ForeignClientName}</div>
				</div>

				{PaymentSystemTypeID === SWIFT_PAYMENT_SYSTEM_TYPE_ID ? (
					<>
						<div className="dx-field">
							<div className="dx-field-label">{'Код контрагента'}</div>
							<div className="dx-field-value">{details.ForeignClientCode}</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">{'Адрес'}</div>
							<div className="dx-field-value">{details.ForeignClientAddress}</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">{'Страна'}</div>
							<div className="dx-field-value">{details.ForeignClientCountry}</div>
						</div>
					</>
				) : null}

				<div className="dx-field">
					<div className="dx-field-label">{'Счет / IBAN / Карта'}</div>
					<div className="dx-field-value">{details.ForeignAccountCode}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'Тип счета'}</div>
					<div className="dx-field-value">{details.ForeignAccountTypeName}</div>
				</div>

				{PaymentSystemTypeID === SWIFT_PAYMENT_SYSTEM_TYPE_ID ? (
					<>
						<div className="dx-field">
							<div className="dx-field-label">{'Банк'}</div>
							<div className="dx-field-value">{details.ForeignClientBank}</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">{'SWIFT'}</div>
							<div className="dx-field-value">{details.ForeignClientBankSwift}</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">{'Адрес банка'}</div>
							<div className="dx-field-value">{details.ForeignClientBankAddress}</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">{'Страна банка'}</div>
							<div className="dx-field-value">{details.ForeignClientBankCountry}</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">{'Дополнительная информация'}</div>
							<div className="dx-field-value">{details.ForeignClientBankAdditionalInfo}</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">{'Банк корреспондент'}</div>
							<div className="dx-field-value">{details.ForeignClientCorrBank}</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">{'SWIFT корреспондента'}</div>
							<div className="dx-field-value">{details.ForeignClientCorrBankSwift}</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">{'Адрес корреспондента'}</div>
							<div className="dx-field-value">{details.ForeignClientCorrBankAddress}</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">{'Страна корреспондента'}</div>
							<div className="dx-field-value">{details.ForeignClientCorrBankCountry}</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">{'Счет корреспондента'}</div>
							<div className="dx-field-value">{details.ForeignClientCorrBankAccount}</div>
						</div>
					</>
				) : null}

				<div className="dx-field">
					<div className="dx-caption">{'Транзакция'}</div>
				</div>

				{details.WithdrawAmount !== null ? (
					<div className="dx-field">
						<div className="dx-field-label">{'К списанию, Валюта'}</div>
						<div className="dx-field-value">{`${details.WithdrawAmount} ${details.WithdrawCurrencyCode}`}</div>
					</div>
				) : null}

				{(details.BoPaymentTypeID !== null || details.FeeAmount !== null) ? (
					<div className="dx-field">
						<div className="dx-field-label">{'Комиссия'}</div>
						<div className="dx-field-value">
							{
								[1, 4, 7, 9].includes(details.BoPaymentTypeID) ?
									`${details.FeeAmount} ${details.ReceiveCurrencyCode}` :
									[2, 3, 5, 6, 8, 11].includes(details.BoPaymentTypeID) ?
										`${details.FeeAmount} ${details.WithdrawCurrencyCode} ${preventNullValue(details.FeePayerTypeCode)}` :
										''
							}
						</div>
					</div>
				) : null}

				{details.ReceiveAmount !== null ? (
					<div className="dx-field">
						<div className="dx-field-label">{'К получению, Валюта'}</div>
						<div className="dx-field-value">{`${details.ReceiveAmount} ${details.ReceiveCurrencyCode}`}</div>
					</div>
				) : null}

				<div className="dx-field">
					<div className="dx-field-label">{'Курс'}</div>
					<div className="dx-field-value">{
						`1 ${details.WithdrawCurrencyCode} = ${details.CurrencyRate} ${details.ReceiveCurrencyCode}`
					}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'ID Транзакции'}</div>
					<div className="dx-field-value">{details.PayGatePaymentID}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'Описание платежа'}</div>
					<div className="dx-field-value">{details.Description}</div>
				</div>


				<div className="dx-field">
					<div className="dx-caption">{'Системные параметры'}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'Эквайер'}</div>
					<div className="dx-field-value">{details.ExtPaymentSystemName}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'ID Транзакции Эквайера'}</div>
					<div className="dx-field-value">{details.ExtPsTransactionID}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'Статус Транзакции Эквайера'}</div>
					<div className="dx-field-value">{details.ExtPsPaymentStatus}</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">{'Ошибка обработки'}</div>
					<div className="dx-field-value">{details.ExtPsErrorText}</div>
				</div>
			</div>
		) : (
			<LoadIndicator/>
		);
	}
}