import React from 'react';
import Collapsible from 'react-collapsible';
import Form, {CustomRule, GroupItem, SimpleItem} from 'devextreme-react/form';
import {requiredCallback} from 'utils/functions';
import {vars} from 'utils/variables';
import DocumentsGrid from '../common/documents-grid';
import {Button} from 'devextreme-react';
import CommentGrid from '../common/comment-grid';

const {COMPACT_BOX_WIDTH, VALIDATION: {REQUIRED_TEXT}} = vars;

const ClientCreateAccordion = ({
																 collapsableFormRef,
																 merchantData,
																 selectedVerificationType,
																 selectedUserType,
																 documents,
																 setIsShowAddPopup,
																 rights,
																 comments,
																 updateComments,
															 }) => {

	const manualMode = selectedVerificationType === 2;
	const isRequiredField = selectedVerificationType === 2 && selectedUserType === 2;
	const isFormSaved = !!(merchantData.MerchantID);

	const modifyLabel = (label, isNeedValidation) => {
		return isRequiredField && isNeedValidation ? label + '*' : label;
	}

	const getItem = (
		isFormSaved, key, dataField, label, isNeedValidation = true,
		options = {}, editorType = 'dxTextBox',
	) => {
		const editorOptions = Object.assign({
			readOnly: isFormSaved ? isFormSaved : !isRequiredField,
		}, options);

		return (
			<SimpleItem
				key={key}
				dataField={dataField}
				label={{text: modifyLabel(label, isNeedValidation)}}
				editorType={editorType}
				editorOptions={editorOptions}
			>
				{isRequiredField && isNeedValidation ? (
					<CustomRule
						message={REQUIRED_TEXT}
						validationCallback={requiredCallback}
					/>
				) : null}
			</SimpleItem>
		);
	}

	const commonFields = [
		{key: 'BUcompanyName', value: 'Наименование'},
		{key: 'BUregNumber', value: 'Регистрационный номер'},
		{key: 'BUregCountry', value: 'Страна регистрации'},
		{
			key: 'BUdateOfReg', value: 'Дата регистрации', options: {
				type: 'date',
				displayFormat: 'yyyy-MM-dd',
				useMaskBehavior: true,
				width: COMPACT_BOX_WIDTH,
			}, editorType: 'dxDateBox',
		},
		{key: 'BUcategory', value: 'Тип бизнеса'},
	];

	const groupedFields = [
		{key: 'BUemail', value: 'Email'},
		{key: 'BUcountry', value: 'Страна'},
		{key: 'BUtown', value: 'Город'},
		{key: 'BUstreet', value: 'Улица'},
		{key: 'BUpostCode', value: 'Почтовый индекс'},
		{key: 'BUlegaladdress', value: 'Юридический адрес'},
		{key: 'BUpostaladdress', value: 'Почтовый адрес'},
		{key: 'BUphone', value: 'Телефон 1'},
		{key: 'BUphone2', value: 'Телефон 2', isNeedValidation: false},

		{key: 'BUHEADfirstName', value: 'Имя руководителя'},
		{key: 'BUHEADlastName', value: 'Фамилия руководителя'},
		{
			key: 'BUHEADdateOfBirth', value: 'Дата рождения руководителя', options: {
				type: 'date',
				displayFormat: 'yyyy-MM-dd',
				useMaskBehavior: true,
				width: COMPACT_BOX_WIDTH,
			}, editorType: 'dxDateBox',
		},
		{key: 'BUHEADcountryOfBirth', value: 'Страна рождения руководителя'},
		{key: 'BUHEADemail', value: 'Email руководителя'},
		{key: 'BUHEADmessenger', value: 'Мессенджер руководителя', isNeedValidation: false},

		{key: 'BUHEADcountry', value: 'Страна'},
		{key: 'BUHEADtown', value: 'Город'},
		{key: 'BUHEADstreet', value: 'Улица'},
		{key: 'BUHEADpostCode', value: 'Почтовый индекс'},
		{key: 'BUHEADphone', value: 'Телефон 1'},
		{key: 'BUHEADphone2', value: 'Телефон 2', isNeedValidation: false},
	];

	return (
		<div>
			<Collapsible
				trigger={'Бизнес данные'}
			>
				<Form
					ref={collapsableFormRef}
					id={'client-create-form'}
					showColonAfterLabel={true}
					labelLocation={'left'}
					validationGroup={'business'}
				>
					{commonFields.map((item, key) => {
						return getItem(isFormSaved, key, item.key, item.value, true, item.options, item.editorType);
					})}

					<GroupItem caption={'Юридический адрес'}>
						{groupedFields.map((item, key) => {
							return getItem(isFormSaved, key, item.key, item.value, item.isNeedValidation, item.options, item.editorType);
						})}
					</GroupItem>
				</Form>
			</Collapsible>
			<Collapsible
				trigger={'Документы'}
			>
				<Button
					className={'gap-button'}
					type={'success'}
					disabled={!(merchantData.MerchantID && manualMode && rights.includes('34'))}
					onClick={() => {
						setIsShowAddPopup(true);
					}}
					text={'Добавить'}
				/>
				<DocumentsGrid documents={documents}/>
			</Collapsible>
			<Collapsible trigger={'Комментарии'}>
				<CommentGrid
					merchantId={merchantData.MerchantID}
					merchantHistory={comments}
					updateComments={updateComments}
				/>
			</Collapsible>
		</div>
	);
}

export default ClientCreateAccordion;