import React, {useState, useEffect} from 'react';
import {apiRequest} from '../../../../services/async';
import './income-payout.scss';
import {SelectBox, TextArea, Button} from "devextreme-react";
import {vars} from "../../../../utils/variables";
import {notifyApp} from "../../../../utils/notifyWrapper";
import Validator, {RequiredRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';

const I = {
	STATUS: 'Статус',
	VERIFICATION_COMMENT: 'Комментарий',
	ACCEPT_BUTTON: 'Подтвердить',
	CANCEL_BUTTON: 'Отмена',
};

const {APP_CODES: {SUCCESS}} = vars;

const ChangeVerification = ({closePopup, rowData, updateMerchant}) => {
	const [statusList, setStatusList] = useState(null);

	useEffect(() => {
		const getStatus = async () => {
			const response = await apiRequest({
				operation: 'VmVerificationStatus/List',
			});

			const {data: {ResponseCode, Response}} = response;

			if (ResponseCode === SUCCESS && Response) {
				setStatusList(Response.VmVerificationStatus);
			}
		};

		getStatus().catch((e) => {
			console.error(e);
		});
	}, []);


	const [status, setStatus] = useState('');
	const [comment, setComment] = useState('');

	const changeVerificationClick = async () => {
		const result = validationEngine.validateGroup('verification-group');

		if (result.isValid) {
			const request = await apiRequest({
				operation: 'Merchant/ChangeVerificationStatus',
				data: {
					Params: {
						MerchantID: rowData.MerchantID,
						VmVerificationStatusID: parseInt(status, 10),
						Remark: comment,
					}
				},
			});

			const {data: {ResponseCode, ResponseText}} = request;

			if (ResponseCode === SUCCESS) {
				updateMerchant();
				closePopup();
			} else {
				notifyApp(ResponseText);
			}
		}
	};

	return (
		<div className={'action-wrapper'}>
			<div className={'clients-popup-field'}>
				<SelectBox
					items={statusList}
					valueExpr="ID"
					placeholder={I.STATUS}
					displayExpr={'Name'}
					value={status}
					onValueChanged={({value}) => {
						setStatus(value);
					}}
				>
					<Validator validationGroup={'verification-group'}>
						<RequiredRule message={'Status required'}/>
					</Validator>
				</SelectBox>
			</div>
			<div className={'clients-popup-field'}>
				<TextArea
					value={comment}
					placeholder={I.VERIFICATION_COMMENT}
					onValueChanged={({value}) => {
						setComment(value);
					}}
				>
					<Validator validationGroup={'verification-group'}>
						<RequiredRule message={'Comment required'}/>
					</Validator>
				</TextArea>
			</div>
			<div className={'action-buttons'}>
				<Button
					className={'action-button'}
					onClick={changeVerificationClick}
					text={I.ACCEPT_BUTTON}
				/>
				<Button
					className={'action-button'}
					onClick={closePopup}
					text={I.CANCEL_BUTTON}
				/>
			</div>
		</div>
	);
};

export default ChangeVerification;
