import {vars} from 'utils/variables';
import React from "react";

const {WALLETTO_ACCOUNT_ID, MERCHANT: {ACCOUNT_STATUS: {IS_ARRESTED, IS_ACTIVE}}, HIGHLIGHT_COLUMN_CLASS} = vars;

export const sha512 = async (str) => {
	return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
		return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
	});
};

export const getFromLocalStorage = (key, fieldKey) => {
	const record = JSON.parse(window.localStorage.getItem(key));
	if (record && fieldKey) {
		return record[fieldKey];
	}
	return record;
};

export const getFromSessionStorage = (key, fieldKey) => {
	const record = JSON.parse(window.sessionStorage.getItem(key));
	if (record && fieldKey) {
		return record[fieldKey];
	}
	return record;
};

export const valueToArray = (val) => {
	return Array.isArray(val) || val == null || val === '' ? val : [val];
};

export const getStatusName = (id) => {
	const statusMap = new Map([
		[1, 'Новый'],
		[2, 'В обработке'],
		[4, 'Подтвержден'],
		[5, 'Ошибка'],
		[6, 'Отклонен'],
		[8, 'На модерации'],
	]);
	return statusMap.get(id) || 'Не известно';
};

export const haveAnyOf = (selectedItems) => {
	const statuses = selectedItems.map(item => item.IsArrest);
	const uniqueStatuses = [...new Set(statuses)];
	return {
		haveArrested: uniqueStatuses.includes(IS_ARRESTED),
		haveActive: uniqueStatuses.includes(IS_ACTIVE),
	};
};

export const checkWallettoAccounts = (selectedAccounts) => {
	const wallettoAccounts = selectedAccounts.filter((account) => {
		return account.PaymentSystemTypeID === WALLETTO_ACCOUNT_ID;
	});
	return wallettoAccounts.length === selectedAccounts.length;
};

export const getCommonMerchantRestrictionData = (
	{UserIsBan, IsRestrictAddAccount, IsRestrictAddExternalAccount, VmVerificationStatusName}
) => {
	return [
		{
			ID: 1,
			Type: 'Ограничение работы пользователя (всех его мерчантов)',
			Status: !!UserIsBan ? 'Ограничен' : 'Нет ограничений',
		},
		{
			ID: 2,
			Type: 'Ограничение мерчанта на добавление новых счетов',
			Status: !!IsRestrictAddAccount ? 'Ограничен' : 'Нет ограничений',
		},
		{
			ID: 3,
			Type: 'Ограничение мерчанта на добавление внешних счетов',
			Status: !!IsRestrictAddExternalAccount ? 'Ограничен' : 'Нет ограничений',
		},
		{
			ID: 4,
			Type: 'Верификация',
			Status: VmVerificationStatusName,
		},
	];
};

export const onChangeMerchantParameterToolbar = ({toolbarOptions}) => {
	toolbarOptions.renderAs = 'bottomToolbar';
	toolbarOptions.items.unshift(
		{
			location: 'before',
			template: 'restrictHistoryTemplate'
		},
	);
};

export const formatDate = (dateString, withoutHours = false) => {
	if (!dateString) return;
	const split = dateString.split('T');
	return withoutHours ? split[0] : `${split[0]} ${split[1]}`;
};

export const getMerchantName = ({FirstNameOrig, LastNameOrig}) => {
	const firstName = FirstNameOrig ? FirstNameOrig : '';
	const lastName = LastNameOrig ? LastNameOrig : '';
	return `${firstName} ${lastName}`;
};

const modifyColumnsOnLoad = (columns, filterKey, filterValue) => {
	columns.forEach((column) => {
		if (column.filterValue) {
			delete column.filterValue;
		}
		if (column.dataField === filterKey) {
			column.filterValue = filterValue;
		}
	});
	return [...columns];
};

export const saveGridSettings = (settings, gridKey = 'storage') => {
	console.log(settings);
	localStorage.setItem(gridKey, JSON.stringify(settings));
};

export const loadGridSettings = (initFilter = null, gridKey = 'storage') => {
	const ls = localStorage.getItem(gridKey);
	let settings = ls ? JSON.parse(ls) : {};

	if (initFilter) {
		const entries = Object.entries(initFilter);
		const filterKey = entries[0][0];
		const filterValue = entries[0][1];

		if (settings.columns) {
			settings.columns = modifyColumnsOnLoad(settings.columns, filterKey, filterValue);
		}

		settings.filterValue = [filterKey, '=', filterValue];
	}
	return settings;
};

export const isConfirmedValue = (value) => {
	return value === 1 ? 'check' : 'close';
};

export const getAppliedFilters = (component, element) => {
	const combinedFilters = component.getCombinedFilter();
	const highlightedColumns = element.getElementsByClassName(HIGHLIGHT_COLUMN_CLASS);

	if (highlightedColumns.length > 0) {
		clearColumnHighlight([...highlightedColumns]);
	}

	if (!combinedFilters) return;

	if (combinedFilters && Array.isArray(Object.values(combinedFilters)[0])) {
		if (combinedFilters.filterValue instanceof Date) {
			highlightColumnWithFilter(combinedFilters.columnIndex);
		} else {
			const filters = combinedFilters.filter((item) => {
				return Array.isArray(item);
			});

			filters.forEach((filter) => {
				if (filter.filterValue || (Array.isArray(filter.filterValue) && filter.filterValue.length > 0)) {
					highlightColumnWithFilter(filter.columnIndex);
				}
			});
		}
	} else {
		highlightColumnWithFilter(combinedFilters.columnIndex);
	}
};

const clearColumnHighlight = (elements) => {
	elements.forEach((item) => {
		item.classList.remove(HIGHLIGHT_COLUMN_CLASS);
	});
};

const highlightColumnWithFilter = (columnIndex) => {
	const el = document.querySelector(`td[aria-colindex="${columnIndex + 1}"][role="columnheader"]`);
	if (el) {
		el.classList.add(HIGHLIGHT_COLUMN_CLASS);
	}
};

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getRestrictionsValue = ({
																			 IsRestrictAddAccount,
																			 IsRestrictAddExternalAccount,
																			 UserIsBan,
																			 IsArrestAccount
																		 }) => {
	const restrictions = [IsRestrictAddAccount, IsRestrictAddExternalAccount, UserIsBan, IsArrestAccount];
	const haveAnyRestrictions = restrictions.some(r => r === 1);
	const whoIs = IsArrestAccount === 1 ? 'Счета' : 'Мерчант';
	return haveAnyRestrictions ? `Да (${whoIs})` : 'Нет';
};

export const prepareGridExport = (exportInstance, gridInstance, exportCaptions) => {
	exportInstance.beginUpdate();
	const exportColumns = exportInstance.getVisibleColumns();

	exportColumns.forEach((column) => {
		let caption;

		if (exportCaptions) {
			caption = exportCaptions[column.dataField];
		} else {
			const visibleColumns = gridInstance.getVisibleColumns();

			if (visibleColumns.length > 0) {
				const columnNames = visibleColumns.map((item) => item.dataField);
				caption = columnNames.includes(column.dataField) && gridInstance.columnOption(column.dataField, 'caption');
			}
		}

		if (caption) {
			exportInstance.columnOption(column.dataField, 'caption', caption);
		} else {
			exportInstance.columnOption(column.dataField, 'visible', false);
		}
	});

	exportInstance.endUpdate();
}

export const isVisibleSettlementBtn = (rights, contestedRight, actionRow) => {
	if (!actionRow.row) return false;

	return rights.includes(contestedRight) && actionRow.row.data.IsManualPay === 1 && actionRow.row.data.Status === 0;
}

export const isVisibleRollingBtn = (rights, contestedRight, actionRow) => {
	if (!actionRow.row) return false;

	return rights.includes(contestedRight) && actionRow.row.data.IsManualPay === 1 && actionRow.row.data.RollingStatus === 0;
}

export const requiredCallback = ({value}) => {
	return value;
}

export const emailRequiredCallback = ({value}) => {
	return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
}

export const tryParseDoubleValue = (text, fraction) => {
	if (!text) return '';

	const parsedValue = text.replaceAll(',', '.').replace(/[^\d.]+/g, '');
	const arr = parsedValue.split('.');

	if (arr.length > 1 && fraction) {
		arr[1] = arr[1].substring(0, fraction);
	}
	return arr.length > 1 ? `${arr[0]}.${arr[1]}` : arr[0];
}

export const generatePassword = () => {
	const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const passwordLength = 8;
	let password = '';

	for (let i = 0; i < passwordLength; i++) {
		const randomNumber = Math.floor(Math.random() * chars.length);
		password += chars.substring(randomNumber, randomNumber + 1);
	}

	return password;
}

export const getFormattedDateColumn = ({text, data}) => {
	return (
		<div>
				<span>
					{text}
				</span>
			<br/>
			<span className={'utc'}>
					{data.UTC}
				</span>
		</div>
	);
}

export const calcColumnWidth = (charCount) => {
	const averageRobotoTwelveCharWidth = 7;
	const paddings = 16;
	return (charCount * averageRobotoTwelveCharWidth) + paddings;
}