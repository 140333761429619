import React from 'react';
import DataGrid, {
	Column,
	Paging,
	FilterRow,
	FilterPanel,
	Scrolling,
	RemoteOperations,
	StateStoring,
	Format,
} from 'devextreme-react/data-grid';
import {getDataSource} from 'services/dataSource';
import {getCheckoutPaymentList} from 'services/requestConsts';
import {
	getFromLocalStorage,
	getFromSessionStorage,
	valueToArray,
	saveGridSettings,
	loadGridSettings,
	getAppliedFilters,
} from 'utils/functions';
import {Template} from 'devextreme-react/core/template';
import {createFilter, makeCalculateFilterExpression} from 'utils/customFilters';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {menuItems} from 'app-routes';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames/bind';
import {vars} from 'utils/variables';

import './payments.scss';

const {
	BO_PAYMENT_TYPE__INCOME_CHECKOUT,
	TRANSACTION,
	STATE_STORING_KEYS: {MERCHANTS: {PAYMENTS_PAGE}},
	PRECISION,
} = vars;

class Payments extends React.Component {
	constructor(props) {
		super(props);
		const merchantOrderID = getFromSessionStorage('filter', 'merchantOrderID');
		this.gridRef = React.createRef();

		this.state = {
			payments: [],
			userRights: [],
			showFilter: !!merchantOrderID,
			popupFields: null,
			popupActionType: null,
			merchantOrderID: merchantOrderID,
			isShowExportDatePopup: false,
		};

		this.paymentFilterOptions = {
			BoPaymentTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('BoPaymentTypeID'),
				options: {
					object: 'BoPaymentType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
			PaymentSystemTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('PaymentSystemTypeID'),
				options: {
					object: 'PaymentSystemType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
			PaymentStatusName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('PaymentStatusID'),
				options: {
					object: 'PaymentStatus',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
			ReceiveCurrency: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('ForeignCurrencyID'),
				options: {
					object: 'Currency',
					displayName: (data) => {
						return data && this.displayCurrency(data.Code, data.Symbol);
					},
					keyName: 'ID',
					value: undefined,
					additionalCols: ['Code', 'Symbol'],
					onValueChanged: () => {
					},
				}
			},
			AccountCurrency: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('AccountCurrencyID'),
				options: {
					object: 'Currency',
					displayName: (data) => {
						return data && this.displayCurrency(data.Code, data.Symbol);
					},
					keyName: 'ID',
					value: undefined,
					additionalCols: ['Code', 'Symbol'],
					onValueChanged: () => {
					},
				}
			},
		};
	}

	componentDidMount() {
		this.getPaymentList().catch((e) => console.error(e));
	}

	getPaymentList = async () => {
		const settlementId = getFromSessionStorage('filter', 'settlementID');
		const userRights = getFromLocalStorage('userSession', 'Rights');
		const paramObj = getCheckoutPaymentList(BO_PAYMENT_TYPE__INCOME_CHECKOUT, settlementId);
		const payments = getDataSource(paramObj);
		window.sessionStorage.removeItem('filter');
		this.setState({
			payments,
			userRights,
		});
	};

	dateColumn(props) {
		return (
			<div>
				<span>
					{props.text}
				</span>
				<br/>
				<span className={'utc'}>
					{props.data.UTC}
				</span>
			</div>
		);
	}

	colorRedClass(id) {
		return classNames({
			'special-payment-type-id': id === TRANSACTION.SPECIAL_BO_PAYMENT_TYPE_ID
		});
	}

	paymentTypeColumn = ({data: {BoPaymentTypeID}, value}) => {
		return <div className={this.colorRedClass(BoPaymentTypeID)}>{value}</div>;
	};

	currencyColumn = ({data: {AccountCurrencyCode, AccountCurrencySymbol, BoPaymentTypeID}}) => {
		return (
			<span className={this.colorRedClass(BoPaymentTypeID)}>
				{this.displayCurrency(AccountCurrencyCode, AccountCurrencySymbol)}
			</span>
		);
	};

	amountColumn = ({data: {BoPaymentTypeID}, text}) => {
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};

	feeColumn = ({data: {BoPaymentTypeID}, text}) => {
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};

	totalAmountColumn = ({data: {BoPaymentTypeID}, text}) => {
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};


	displayCurrency(code, symbol) {
		return `${code} ${symbol}`
	}

	toReceiveCurrencyColumn = ({data: {ForeignCurrencyCode, ForeignCurrencySymbol, BoPaymentTypeID}}) => {
		return (
			<span className={this.colorRedClass(BoPaymentTypeID)}>
				{this.displayCurrency(ForeignCurrencyCode, ForeignCurrencySymbol)}
			</span>
		);
	};

	toReceiveAmountColumn = ({data: {BoPaymentTypeID}, text}) => {
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};

	toReceiveCurrencyExchangeColumn = ({data}) => {
		const {AccountCurrencySymbol, ForeignCurrencySymbol, CurrencyRate, BoPaymentTypeID} = data;
		return (
			<span className={this.colorRedClass(BoPaymentTypeID)}>
				{`${AccountCurrencySymbol} 1 = ${ForeignCurrencySymbol} ${CurrencyRate.toFixed(2)}`}
			</span>
		);
	};

	statusColumn({value, data}) {
		const {STATUS_COLOR} = TRANSACTION;
		let statusClass;
		switch (data.PaymentStatusID) {
			case 4:
				statusClass = STATUS_COLOR.GREEN;
				break;
			case 5:
				statusClass = STATUS_COLOR.RED;
				break;
			case 1:
				statusClass = STATUS_COLOR.BLACK;
				break;
			default:
				statusClass = STATUS_COLOR.OTHER;
		}
		return <span className={`status-column ${statusClass}`}>{value}</span>;
	}

	onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.push(
			{
				location: 'before',
				template: 'customToolbar'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'filter',
					onClick: this.showFilterHandler
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'clearsquare',
					onClick: () => {
						if (this.gridRef.current) {
							this.gridRef.current.instance.clearFilter()
						}
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					onClick: () => {
						const {isShowExportDatePopup} = this.state;
						this.setState({isShowExportDatePopup: !isShowExportDatePopup})
					}
				},
				location: 'after'
			},
		);
	}

	toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<span className={'recent-operation-text'}>
					{TRANSACTION.TEXT.FILTERS}
				</span>
			</div>
		);
	};

	showFilterHandler = () => {
		const {showFilter} = this.state;
		this.setState({
			showFilter: !showFilter,
		})
	};

	closePopup = () => {
		this.setState({
			popupFields: null,
		});
	};

	/**
	 *
	 * @param {object} e
	 * @param {string} e.dataField
	 * @param {HTMLElement} e.editorElement
	 * @param {object} e.editorOptions
	 * @param {boolean} e.cancel
	 * @param {any} e.value
	 * @param {'dataRow' | 'filterRow' | 'headerRow' | 'searchPanel'} e.parentType
	 */
	onEditorPreparing = (e) => {
		const {dataField, editorElement, parentType} = e;

		if (this.paymentFilterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = this.paymentFilterOptions[dataField];
			e.cancel = true;
			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					parentWidth: editorElement.clientWidth,
					value: valueToArray(e.value),
					onValueChanged: e.editorOptions.onValueChanged
				}
			}));
		}
	};

	savePaymentsSettings = (settings) => {
		saveGridSettings(settings, PAYMENTS_PAGE);
	};

	loadPaymentsSettings = () => {
		const {merchantOrderID} = this.state;
		return loadGridSettings(merchantOrderID ? {MerchantOrderID: merchantOrderID} : null, PAYMENTS_PAGE);
	};

	closeExportDatePopup = () => {
		this.setState({isShowExportDatePopup: false});
	}

	render() {
		const {history} = this.props;
		const {payments, showFilter, isShowExportDatePopup} = this.state;
		return (
			<div className={'page-component-wrapper'}>
				<DataGrid
					id={'grid-acc-statement'}
					ref={this.gridRef}
					alignment={'center'}
					className={classNames('dx-card wide-card', {
						'filter-row-visible': showFilter
					})}
					dataSource={payments}
					hoverStateEnabled={true}
					showBorders={false}
					focusedRowEnabled={false}
					columnHidingEnabled={true}
					onEditorPreparing={this.onEditorPreparing}
					onToolbarPreparing={this.onToolbarPreparing}
					height={'100%'}
					onContentReady={({component, element}) => {
						getAppliedFilters(component, element);
					}}
				>
					<RemoteOperations
						paging
						filtering
					/>
					<StateStoring
						enabled={true}
						type="custom"
						customLoad={this.loadPaymentsSettings}
						customSave={this.savePaymentsSettings}
						savingTimeout={100}
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={true}/>
					<FilterPanel visible={true}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar='onHover'
					/>
					<Column
						dataField={'InsDate'}
						caption={'Дата'}
						hidingPriority={18}
						dataType={'datetime'}
						format={'dd.MM.yyyy, HH:mm'}
						cellRender={this.dateColumn}
						width={130}
					/>
					<Column
						dataField={'ID'}
						caption={'ID'}
						hidingPriority={17}
						width={100}
					/>
					<Column
						dataField={'AccountMerchantID'}
						caption={'Мерчант'}
						hidingPriority={16}
						width={100}
					/>
					<Column
						dataField={'MerchantApiProfileID'}
						caption={'ID профиля'}
						hidingPriority={15}
					/>
					<Column
						dataField={'MerchantOrderID'}
						caption={'Order ID'}
						hidingPriority={14}
					/>
					<Column
						dataField={'BoPaymentTypeName'}
						caption={'Тип операции'}
						hidingPriority={13}
						filterOperations={this.paymentFilterOptions['BoPaymentTypeName'].filterOperations}
						calculateFilterExpression={this.paymentFilterOptions['BoPaymentTypeName'].calculateFilterExpression}
						cellRender={this.paymentTypeColumn}
						width={200}
					/>
					<Column
						dataField={'Amount'}
						caption={'Сумма'}
						hidingPriority={12}
						cellRender={this.amountColumn}
					>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column
						dataField={'AccountCurrency'}
						caption={'Валюта счета'}
						hidingPriority={11}
						alignment={'center'}
						filterOperations={this.paymentFilterOptions['AccountCurrency'].filterOperations}
						calculateFilterExpression={this.paymentFilterOptions['AccountCurrency'].calculateFilterExpression}
						cellRender={this.currencyColumn}
						width={100}
					/>
					<Column
						dataField={'FeeAmount'}
						caption={'Комиссия'}
						hidingPriority={10}
						cellRender={this.feeColumn}
					>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column
						dataField={'TotalAmount'}
						caption={'Итого'}
						hidingPriority={9}
						cellRender={this.totalAmountColumn}
					>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column
						dataField={'RollingAmount'}
						caption={'Hold'}
						hidingPriority={8}
					>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column
						dataField={'ForeignAmount'}
						caption={'К получению'}
						hidingPriority={7}
						alignment={'center'}
						cellRender={this.toReceiveAmountColumn}
					>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column
						caption={'Курс валюты'}
						hidingPriority={6}
						cellRender={this.toReceiveCurrencyExchangeColumn}
						width={130}
					/>
					<Column
						dataField={'ReceiveCurrency'}
						caption={'Валюта получения'}
						hidingPriority={5}
						alignment={'center'}
						filterOperations={this.paymentFilterOptions['ReceiveCurrency'].filterOperations}
						calculateFilterExpression={this.paymentFilterOptions['ReceiveCurrency'].calculateFilterExpression}
						cellRender={this.toReceiveCurrencyColumn}
						width={100}
					/>
					<Column
						dataField={'PaymentStatusName'}
						caption={'Статус'}
						hidingPriority={4}
						filterOperations={this.paymentFilterOptions['PaymentStatusName'].filterOperations}
						calculateFilterExpression={this.paymentFilterOptions['PaymentStatusName'].calculateFilterExpression}
						cellRender={this.statusColumn}
						width={150}
					/>
					<Column
						dataField={'PaymentSystemTypeName'}
						caption={'Платежная система'}
						hidingPriority={3}
						filterOperations={this.paymentFilterOptions['PaymentSystemTypeName'].filterOperations}
						calculateFilterExpression={this.paymentFilterOptions['PaymentSystemTypeName'].calculateFilterExpression}
					/>
					<Column
						dataField={'ForeignAccountCode'}
						caption={'Card/Account'}
						hidingPriority={2}
					/>
					<Column
						dataField={'ForeignClientName'}
						caption={'Payeer'}
						hidingPriority={1}
					/>
					<Column
						dataField={'ErrorText'}
						caption={'Ошибка'}
						hidingPriority={0}
					/>
					<Column
						caption={'Действие'}
						type={'buttons'}
						buttons={[
							{
								hint: 'Order',
								text: 'Заказ',
								onClick: ({row}) => {
									window.sessionStorage.setItem('filter', JSON.stringify({
										merchantOrderID: row.data.MerchantOrderID
									}));
									history.push(menuItems.checkout[0].url);
								}
							}
						]}
					/>
					<Template name={'customToolbar'} render={this.toolbarItemRender}/>
				</DataGrid>
				<ExportDataGrid
					ref={this.gridRef}
					exportFileName={'PaymentsExport'}
					getGridParams={getCheckoutPaymentList}
					isShowExportDatePopup={isShowExportDatePopup}
					closeExportDatePopup={this.closeExportDatePopup}
				/>
			</div>
		);
	}
}

export default withRouter(Payments);


