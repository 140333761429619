import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, { Component } from 'react';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import './App.scss';
import './dx-styles.scss';
import { Footer, LoginForm } from './components';
import {
	AdminLayout as Layout,
  SingleCard
} from './layouts';
import { sizes, subscribe, unsubscribe } from './utils/media-query';
import {locale} from "devextreme/localization";
import {vars} from "./utils/variables";

const {CLIENT_PATH} = vars;

const LoginContainer = ({ logIn }) => <LoginForm onLoginClick={logIn} />;

const NotAuthPage = (props) => (
  <SingleCard>
    <Route render={() => <LoginContainer {...props} />} />
  </SingleCard>
);

const AuthPage = (props) => (
  <Layout title={appInfo.title} {...props}>
    <Switch>
      {routes.map(item => (
        <Route
          exact
          key={item.path}
          path={item.path}
          component={item.component}
        />
      ))}
      <Redirect to={CLIENT_PATH} />
    </Switch>
    <Footer>
      Copyright © 2011-2019 Developer Express Inc.
      <br />
      All trademarks or registered trademarks are property of their
      respective owners.
    </Footer>
  </Layout>
);

class App extends Component {
  constructor(props) {
    super(props);

		locale('en');
    this.state = {
      loggedIn: false,
      screenSizeClass: this.getScreenSizeClass()
    };

    this.userMenuItems = [
      {
        text: 'Logout',
        icon: 'runner',
        onClick: this.logOut
      }
    ];
  }

  componentDidMount() {
		const user = localStorage.getItem('user');

		this.setState({
			loggedIn: !!user
		});

		subscribe(this.screenSizeChanged);
  }

  componentWillUnmount() {
    unsubscribe(this.screenSizeChanged);
  }

  render() {
    const { loggedIn } = this.state;
		const id = loggedIn ? '' : 'login-page';

    return (
      <div id={id} className={`app ${this.state.screenSizeClass}`}>
				<Router>{loggedIn ? <AuthPage userMenuItems={this.userMenuItems} /> : <NotAuthPage logIn={this.logIn} />}</Router>
      </div>
    );
  }

  getScreenSizeClass() {
    const screenSizes = sizes();
    return Object.keys(screenSizes).filter(cl => screenSizes[cl]).join(' ');
  }

  screenSizeChanged = () => {
    this.setState({
      screenSizeClass: this.getScreenSizeClass()
    });
  };

  logIn = (userID) => {
		localStorage.setItem('user', userID);
    this.setState({ loggedIn: true });
  };

  logOut = () => {
		// localStorage.removeItem('user');
		localStorage.clear();
    this.setState({ loggedIn: false });
  };
}

export default App;
