import React from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow,
	Format,
	Paging,
	RemoteOperations,
	Scrolling,
	StateStoring,
} from 'devextreme-react/data-grid';
import {getDataSource} from 'services/dataSource';
import {getAccountsList, getMerchantsList} from 'services/requestConsts';
import {Template} from 'devextreme-react/core/template';
import AdminPopup from 'components/popup/admin-popup';
import {AccountDetails} from './accountsPopupFields';
import {createFilter, makeCalculateFilterExpression} from 'utils/customFilters';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {
	getAppliedFilters,
	getFromLocalStorage,
	getFromSessionStorage,
	loadGridSettings,
	saveGridSettings,
	valueToArray,
} from 'utils/functions';
import {updateSelectedMerchantDetails} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import classNames from 'classnames/bind';
import {vars} from 'utils/variables';

const {TRANSACTION, STATE_STORING_KEYS: {CLIENTS: {ACCOUNTS_PAGE}}, PRECISION} = vars;

const getTitle = (actionType, {ID= null, MerchantID = null, Name = null, MerchantName = null, Code, UserName = 'No response'}) => {
	const titles = {
		open: `ID: ${ID}, Счёт: ${Code}`,
		restriction: `Ограничения возможностей мерчантов ${MerchantID} ${Name}`,
		income: 'Техническое пополнение',
		payout: 'Техническое списание',
		atm: 'Снятие в АТМ',
		contactless: 'Бесконтактная оплата',
		magnetStripe: 'Магнитная полоса',
		ecommerce: 'Интернет операции',
	};

	return titles[actionType];
};

export default class Accounts extends React.Component {
	constructor(props) {
		super(props);
		const merchantFilterID = getFromSessionStorage('filter', 'merchantFilterID');
		window.sessionStorage.removeItem('filter');
		this.gridRef = React.createRef();

		this.state = {
			accounts: [],
			showFilter: !!merchantFilterID,
			popupFields: null,
			rowData: {
				ID: null,
				Name: null,
				UserName: null,
			},
			actionType: null,
			merchantFilterID: merchantFilterID,
			isShowExportDatePopup: false,
		};

		this.filterOptions = {
			UserTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('UserTypeID'),
				options: {
					object: 'UserType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
			UserStatusName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('UserStatusID'),
				options: {
					object: 'UserStatus',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
		}
	}

	componentDidMount() {
		this.getAccountsList().catch((e) => {
			console.warn(e);
		});
	}

	getMerchantList = async () => {
		const merchants = getDataSource(getMerchantsList());
		await merchants.load();
		this.setState({merchants: merchants});
	};

	getAccountsList = async () => {
		const accounts = getDataSource(getAccountsList());
		this.setState({accounts: accounts});
	};

	updateSelectedMerchant = async (id) => {
		const userRights = getFromLocalStorage('userSession', 'Rights');
		let merchant = null;

		try {
			merchant = await updateSelectedMerchantDetails(id);
			if (merchant) {
				merchant.UserRights = userRights;
			}
			this.setState({
				rowData: merchant,
			});
		} catch (e) {
			notifyApp(e);
		}

		return merchant;
	};

	gridButtonHandler = (actionType, component, rowData) => {
		this.setState({
			popupFields: component,
			rowData,
			actionType,
		});
	};

	onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.push(
			{
				location: 'before',
				template: 'customToolbar'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'filter',
					onClick: this.showFilterHandler
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'clearsquare',
					onClick: () => {
						if (this.gridRef.current) {
							this.gridRef.current.instance.clearFilter()
						}
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					onClick: () => {
						const {isShowExportDatePopup} = this.state;
						this.setState({isShowExportDatePopup: !isShowExportDatePopup})
					}
				},
				location: 'after'
			},
		);
	}

	toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<span className={'recent-operation-text'}>
					{TRANSACTION.TEXT.FILTERS}
				</span>
			</div>
		);
	};

	showFilterHandler = () => {
		const {showFilter} = this.state;
		this.setState({
			showFilter: !showFilter,
		})
	};

	closePopup = () => {
		this.setState({
			popupFields: null,
		});
	};

	onEditorPreparing = (e) => {
		const {dataField, editorElement, parentType} = e;

		if (this.filterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = this.filterOptions[dataField];
			e.cancel = true;

			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					value: valueToArray(e.value),
					parentWidth: editorElement.clientWidth,
					onValueChanged: e.editorOptions.onValueChanged
				}
			}));
		}
	};

	saveAccountsSettings = (settings) => {
		saveGridSettings(settings, ACCOUNTS_PAGE);
	};

	loadAccountsSettings = () => {
		const {merchantFilterID} = this.state;
		return loadGridSettings(merchantFilterID ? {MerchantID: merchantFilterID} : null, ACCOUNTS_PAGE);
	};

	closeExportDatePopup = () => {
		this.setState({isShowExportDatePopup: false});
	}

	render() {
		const {accounts, showFilter, popupFields, rowData, actionType, isShowExportDatePopup} = this.state;
		const PopupFields = popupFields;
		const maxPopupWidth = actionType === 'restriction' ? 1200 : 800;

		return (
			<div className={'page-component-wrapper'}>
				<AdminPopup
					logo={false}
					handleClose={this.closePopup}
					visible={!!popupFields}
					title={getTitle(actionType, rowData)}
					maxWidth={maxPopupWidth}
				>
					{popupFields ? (
						<PopupFields
							rowData={rowData}
							actionType={actionType}
							gridButtonHandler={this.gridButtonHandler}
							closePopup={this.closePopup}
							accountsData={accounts}
							updateSelectedMerchant={this.updateSelectedMerchant}
							gridRef={this.gridRef}
						/>
					) : null}
				</AdminPopup>
				<DataGrid
					id={'grid-acc-statement'}
					alignment={'center'}
					className={classNames('dx-card wide-card', {
						'filter-row-visible': showFilter
					})}
					ref={this.gridRef}
					dataSource={accounts}
					hoverStateEnabled={true}
					showBorders={false}
					focusedRowEnabled={false}
					columnHidingEnabled={true}
					onEditorPreparing={this.onEditorPreparing}
					onToolbarPreparing={this.onToolbarPreparing}
					height={'100%'}
					onContentReady={({component, element}) => {
						getAppliedFilters(component, element);
					}}
					onRowDblClick={(row) => {
						this.gridButtonHandler('open', AccountDetails, row.data);
					}}
				>
					<RemoteOperations
						paging
						filtering
					/>
					<StateStoring
						enabled={true}
						type="custom"
						customLoad={this.loadAccountsSettings}
						customSave={this.saveAccountsSettings}
						savingTimeout={100}
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={true}/>
					<FilterPanel visible={true}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar='onHover'
					/>
					<Column
						dataField={'MerchantID'}
						caption={'ID клиента'}
						width={100}
					/>
					<Column
						dataField={'MerchantName'}
						caption={'Клиент'}
					/>
					<Column
						dataField={'UserTypeName'}
						filterOperations={this.filterOptions['UserTypeName'].filterOperations}
						calculateFilterExpression={this.filterOptions['UserTypeName'].calculateFilterExpression}
						caption={'Тип клиента'}
						width={100}
					/>
					<Column
						dataField={'PaymentSystemTypeName'}
						caption={'Тип счета'}
					/>
					<Column
						dataField={'PaymentSystemTypeInternalName'}
						caption={'Внутренний тип счёта'}
					/>
					<Column
						dataField={'InsDate'}
						caption={'Дата создания'}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
						width={150}
					/>
					<Column
						dataField={'Code'}
						caption={'Счет'}
					/>
					<Column
						dataField={'CurrencyCode'}
						caption={'Валюта'}
						width={65}
					/>
					<Column
						dataField={'Balance'}
						caption={'Баланс'}
					>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column
						dataField={'LastPaymentInsDate'}
						caption={'Дата последней операции'}
						dataType={'datetime'}
						format={'dd.MM.yy, HH:mm:ss'}
					/>
					<Column
						dataField={'AccountStatusName'}
						caption={'Статус'}
						width={100}
					/>
					<Column
						caption={'Быстрый просмотр'}
						type={'buttons'}
						buttons={[
							{
								hint: 'Open',
								text: 'Открыть',
								onClick: ({row}) => {
									this.gridButtonHandler('open', AccountDetails, row.data);
								}
							},
						]}
					/>
					<Template name={'customToolbar'} render={this.toolbarItemRender}/>
				</DataGrid>
				<ExportDataGrid
					ref={this.gridRef}
					exportFileName={'AccountsExport'}
					getGridParams={getAccountsList}
					isShowExportDatePopup={isShowExportDatePopup}
					closeExportDatePopup={this.closeExportDatePopup}
				/>
			</div>
		);
	}
}
