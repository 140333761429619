import React, {useRef, useState} from 'react';
import DataGrid from 'devextreme-react/data-grid';
import {Button, DateBox, Popover} from 'devextreme-react';
import {prepareGridExport} from 'utils/functions';
import {getFilter, prepareDatesForGridExport} from 'services/dataSourceFilterUtils';
import {getDataSource} from 'services/dataSource';

const ExportDataGrid = React.forwardRef(({
																					 isShowExportDatePopup,
																					 closeExportDatePopup,
																					 getGridParams,
																					 exportFileName,
																					 filterFieldName = 'InsDate',
																					 exportCaptions
																				 }, ref) => {
	const exportRef = useRef(null);
	const [exportDataSource, setExportDataSource] = useState(null);
	const [startDate, setStartDate] = useState(new Date);
	const [endDate, setEndDate] = useState(new Date);

	const prepareDatasource = () => {
		const gridInstance = ref.current.instance;

		const paramObj = getGridParams();

		const apiFilters = getFilter(gridInstance.getCombinedFilter(), paramObj.fieldsSettings);

		paramObj.defFilter = {
			...apiFilters,
			[filterFieldName]: {
				between: prepareDatesForGridExport(startDate, endDate),
			},
		};

		const merchantsDataSource = getDataSource(paramObj);

		setExportDataSource(merchantsDataSource);
	}

	const onContentReadyExport = () => {
		const exportInstance = exportRef.current.instance;
		prepareGridExport(exportInstance, ref.current.instance, exportCaptions);

		exportInstance.exportToExcel();
	}

	const onExporting = (e) => {
		e.fileName = exportFileName;
	}

	return (
		<div>
			{exportDataSource && (
				<DataGrid
					id={'grid-acc-statement-export'}
					visible={false}
					ref={exportRef}
					onContentReady={onContentReadyExport}
					onExporting={onExporting}
					dataSource={exportDataSource}
				/>
			)}
			<div id={'calendar-wrapper'}>
				<Popover
					target={'.dx-toolbar-after'}
					visible={isShowExportDatePopup}
					onHiding={closeExportDatePopup}
				>
					<div className={'export-fields'}>
						<div className="dx-field">
							<div className="dx-field-label">С</div>
							<div className="dx-field-value">
								<DateBox
									value={startDate}
									max={endDate}
									useMaskBehavior={true}
									displayFormat={'dd.MM.yy'}
									onValueChanged={({value}) => {
										setStartDate(value);
									}}
								/>
							</div>
						</div>
						<div className="dx-field">
							<div className="dx-field-label">По</div>
							<div className="dx-field-value">
								<DateBox
									value={endDate}
									min={startDate}
									useMaskBehavior={true}
									displayFormat={'dd.MM.yy'}
									onValueChanged={({value}) => {
										setEndDate(value);
									}}
								/>
							</div>
						</div>
						<div className={'export-button'}>
							<Button text={'Ok'} onClick={prepareDatasource}/>
						</div>
					</div>
				</Popover>
			</div>
		</div>
	);
});

export default ExportDataGrid;