import React from 'react';
import {Button} from 'devextreme-react';
import {confirm} from 'devextreme/ui/dialog';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {useHistory} from 'react-router-dom';
import {vars} from 'utils/variables';

const {APP_CODES: {SUCCESS}, MERCHANT, NOTIFY} = vars;

const DetailButtons = ({
												 selectedAccountItem,
												 integrationFormData,
												 isReadOnly,
												 setIsReadOnly,
												 formState,
												 profileRef,
												 paymentRef,
												 gridRef
											 }) => {
	const {merchant, merchantFilterID} = formState;
	const history = useHistory();
	const handleClose = () => {
		if (merchant) {
			history.push(MERCHANT.PROFILES_PATH);
		} else {
			history.replace(MERCHANT.PROFILES_PATH);
		}
	};
	// TODO: save, close
	return (
		<div className={'details-buttons'}>
			{isReadOnly ? (
				<>
					<Button
						type={'success'}
						onClick={() => {
							console.log('Редактировать');
							setIsReadOnly(false);
						}}
						text={'Редактировать'}
					/>
					<Button
						type={'danger'}
						onClick={() => {
							console.log('Удалить');
							let result = confirm("<i>Are you sure?</i>", "Confirm changes");
							result.then(async (dialogResult) => {
								//alert(dialogResult ? "Confirmed" : "Canceled");
								console.log(merchant.ID);
								console.log(merchant.MerchantID);
								if (dialogResult) {
									const deleteRequest = await apiRequest({
										operation: 'MerchantApiProfile/Del',
										data: {
											Params: {
												ID: merchant.ID,
												MerchantID: merchant.MerchantID,
											},
										},
									});

									if (deleteRequest.data.ResponseCode === SUCCESS) {
										handleClose();
									} else {
										notifyApp(deleteRequest.data.ResponseText);
									}
								}
							});
						}}
						text={'Удалить'}
					/>
				</>
			) : (
				<>
					<Button
						type={'default'}
						onClick={async () => {
							console.log('Сохранить');

							let formData = profileRef.current.instance.option('formData');

							if (paymentRef.current) {
								const paymentFormData = paymentRef.current.instance.option('formData');
								Object.assign(formData, paymentFormData);
							}

							if (Object.keys(formData).length === 0) {
								Object.assign(formData, {
									BUEmail: null,
									CancelURL: null,
									DefaultAccountBalance: null,
									DefaultAccountCode: null,
									DefaultAccountCurrencyCode: null,
									DefaultAccountCurrencySymbol: null,
									DefaultAccountID: null,
									Description: null,
									GeneralAccountCode: null,
									GeneralAccountID: null,
									GeneralSettlementDelayDays: null,
									GeneralSettlementPeriodDays: null,
									ID: null,
									IndividualSettlementDelayDays: null,
									IndividualSettlementPeriodDays: null,
									InsDate: null,
									IsAllowEdit: null,
									MerchantApiProfileStatusID: null,
									MerchantApiProfileStatusName: null,
									MerchantID: merchantFilterID,
									MerchantName: null,
									Name: '',
									NotifyURL: null,
									PayGateToken: null,
									RollingAccountBalance: null,
									SettlementDelayDays: null,
									SettlementPeriodDays: null,
									SuccessURL: null,
									WebAddress: null,
								});
							}

							const gridDataFiltered = gridRef.current ?
								gridRef.current.instance.getDataSource().items().filter(item => item.IsSelected) :
								[];

							if (gridDataFiltered.length > 0) {
								let selectedGridItems = [];

								gridDataFiltered.forEach((item) => {
									selectedGridItems.push(
										{
											PaymentSystemTypeID: item.PaymentSystemTypeID,
											RollingReserveRate: item.IndividualRollingReserveRate,
											RollingReserveDays: item.IndividualRollingReserveDays,
											IsPayRetailersPaywall: item.IsPayRetailersPaywall ? 1 : 0,
											IsAllowPayoutFromDefaultAccount: item.IsAllowPayoutFromDefaultAccount ? 1 : 0,
										}
									);
								});

								formData = Object.assign(formData, {MerchantApiProfilePaymentSystemType: selectedGridItems});
							}

							if (integrationFormData) {
								formData = Object.assign(formData, integrationFormData);
							}

							const modData = {
								...formData,
								GeneralAccountCode: selectedAccountItem.Code,
								GeneralAccountID: selectedAccountItem.ID,
								MerchantID: merchantFilterID,
							};

							console.group('Save all data');
							console.log(formData);
							console.log(modData);
							console.groupEnd();

							const request = await apiRequest({
								operation: `MerchantApiProfile/${merchant ? 'Mod' : 'Ins'}`,
								data: {
									Params: modData,
								},
							});

							if (request && request.data.ResponseCode === SUCCESS) {
								merchant ? setIsReadOnly(true) : handleClose();
								notifyApp('Operation successful', NOTIFY.SUCCESS);
							} else {
								notifyApp(request.data.ResponseText);
							}
						}}
						text={'Сохранить'}
					/>
					<Button
						type={'danger'}
						onClick={() => {
							console.log('Отменить');
							setIsReadOnly(true);
						}}
						text={'Отменить'}
					/>
				</>
			)}
			<Button
				type={'normal'}
				onClick={() => {
					console.log('Закрыть');
					handleClose();
				}}
				text={'Закрыть'}
			/>
		</div>
	);
};

export default DetailButtons;
