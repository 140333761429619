import React from 'react';
import Form, {SimpleItem} from 'devextreme-react/form';
import {getRestrictionsValue} from 'utils/functions';
import {gridDate} from 'services/dataSourceFilterUtils';
import TextBox from 'devextreme-react/text-box';

const ClientDetailInfo = (props) => {
	const {rowData} = props;

	return (
		<Form
			id="client-detail-info"
			formData={rowData}
			readOnly={true}
			showColonAfterLabel={true}
			labelLocation={'left'}
		>
			<SimpleItem dataField={'UserTypeName'} label={{text: 'Тип клиента'}}/>
			<SimpleItem dataField={'UserStatusName'} label={{text: 'Статус регистрации'}}/>
			<SimpleItem dataField={'InsDate'} label={{text: 'Дата регистрации'}} editorType={'dxTextBox'}
									editorOptions={{value: gridDate(rowData['InsDate'], true)}}/>
			<SimpleItem label={{text: 'Заблокирован'}} editorType={'dxCheckBox'}
									editorOptions={{value: rowData.UserIsBan === 1}}/>
			<SimpleItem
				render={() => {
					return (
						<div className={'verification-item'}>
							{rowData.VmApplicantUrl ? (
								<div className={'icon-wrapper'}>
									<a target={'_blank'} href={rowData.VmApplicantUrl}>
										<img src={'/images/person_search.svg'} alt='VmApplicantUrl'/>
									</a>
								</div>
							) : null}
							<TextBox
								defaultValue={rowData.VmVerificationStatusName}
								readOnly
							/>
						</div>
					);
				}}
				label={{text: 'Верификация'}}
			/>
			<SimpleItem dataField={'VmLastUpdate'} label={{text: 'Дата верификации'}} editorType={'dxTextBox'}
									editorOptions={{value: gridDate(rowData['VmLastUpdate'], true)}}/>
			<SimpleItem label={{text: 'Ограничения'}} editorType={'dxTextBox'}
									editorOptions={{value: getRestrictionsValue(rowData)}}/>
			<SimpleItem dataField={'ReferralCode'} label={{text: 'Код для реферальной программы'}}/>
		</Form>
	);
};

export default ClientDetailInfo;