import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {Column, Editing, PatternRule} from 'devextreme-react/data-grid';
import Form, {GroupItem, SimpleItem} from 'devextreme-react/form';
import {CheckBox} from 'devextreme-react/check-box';
import {notifyApp} from 'utils/notifyWrapper';
import DetailButtons from './detail-buttons';
import Loader from 'components/loader/loader';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';
import './profile-details-form.scss';

const {APP_CODES: {SUCCESS}, PAY_RETAILS, CHECKOUT_REFILL_ID} = vars;

const integerOrDouble = /^\d+(\.\d+)?$/;

const editorOptions = {
	disabled: {disabled: true},
	readOnly: {readOnly: true},
}


const ProfileDetailsForm = ({formState}) => {
	const {merchantFilterID, merchant} = formState;
	const [isAccountCollapsed, setIsAccountCollapsed] = useState(true);
	const [isPaymentCollapsed, setIsPaymentCollapsed] = useState(true);
	const [isIntegrationCollapsed, setIsIntegrationCollapsed] = useState(true);
	const [isReadOnly, setIsReadOnly] = useState(true);
	const [formData, setFormData] = useState(null);
	const [integrationFormData, setIntegrationFormData] = useState(null);
	const [gridItems, setGridItems] = useState(null);
	const [isReady, setIsReady] = useState(false);
	const [accountItems, setAccountItems] = useState(null);
	const [selectedAccountItem, setSelectedAccountItem] = useState({ID: null, Code: null});
	const profileRef = useRef();
	const paymentRef = useRef();
	const gridRef = useRef();
	const apiKeyRef = useRef();

	const formProps = {
		showColonAfterLabel: true,
		labelLocation: 'left',
	};

	const loadAccountSelectData = async () => {
		const accountForSelectBox = await apiRequest({
			operation: 'Account/List',
			data: {
				Filters: {
					MerchantID: {'=': merchantFilterID},
					PaymentSystemTypeID: {'=': 0},
					AccountTypeID: {'=': 1},
				},
				Sorts: ['-ID'],
			}
		});

		if (accountForSelectBox.data.ResponseCode === SUCCESS) {
			return Promise.resolve(accountForSelectBox.data.Response.Account);
		} else {
			return Promise.reject(accountForSelectBox.data.ResponseText);
		}
	};

	const loadApiKeys = async () => {
		const apiKeysRequest = await apiRequest({
			operation: 'MerchantApiProfile/GetApiKeys',
			data: {
				Params: {ID: merchant.ID},
			},
		});

		if (apiKeysRequest.data.ResponseCode === SUCCESS) {
			return Promise.resolve(apiKeysRequest.data.Response);
		} else {
			return Promise.reject(apiKeysRequest.data.ResponseText);
		}
	};

	const loadFormData = async () => {
		let merchantData = {};

		if (!merchant) {
			return Promise.resolve(null);
		}

		const merchantApiProfilesRequest = await apiRequest({
			operation: 'MerchantApiProfile/List',
			data: {
				Filters: {ID: merchant.ID},
			},
		});

		if (merchantApiProfilesRequest.data.ResponseCode === SUCCESS) {
			Object.assign(merchantData, merchantApiProfilesRequest.data.Response.MerchantApiProfile[0]);
			return Promise.resolve(merchantData);
		} else {
			return Promise.reject(merchantApiProfilesRequest.data.ResponseText);
		}
	};

	useEffect(() => {
		console.group('useEffect');
		console.log(merchant);
		console.groupEnd();
		if (!formData) {
			loadFormData().then((merchantData) => {
				if (merchantData) {
					if (merchantData && merchantData.GeneralAccountCode && merchantData.GeneralAccountID) {
						setSelectedAccountItem({ID: merchantData.GeneralAccountID, Code: merchantData.GeneralAccountCode});
					}
					setFormData(merchantData);
					return true;
				}
				return false;
			}).then((result) => {
				console.group('profile-details-resolve');
				console.log(result);
				console.groupEnd();
				setIsReadOnly(result);
				return result;
			}).then((result) => {
				// load api keys
				return result ? loadApiKeys() : null;
			}).then((integrationData) => {
				// set loaded data
				console.group('api keys');
				console.log(integrationData);
				console.groupEnd();
				setIntegrationFormData(integrationData);
			}).catch((error) => {
				console.group('profile-details-reject');
				console.error(error);
				console.groupEnd();
				notifyApp(error);
			}).finally(() => {
				setIsReady(true);
			});
		}
	}, [formData]);

	const loadGridData = async () => {
		const request = await apiRequest({
			operation: 'MerchantApiProfilePaymentSystemType/List',
			data: {
				Filters: {
					MerchantApiProfileID: merchant ? merchant.ID : null,
					PaymentTypeID: CHECKOUT_REFILL_ID,
				},
				Sorts: ['Name'],
			}
		});

		if (request.data.ResponseCode === SUCCESS) {
			if (request.data.Response.MerchantApiProfilePaymentSystemType.length > 0) {
				const origin = request.data.Response.MerchantApiProfilePaymentSystemType;
				const data = [];
				const copy = [];

				origin.forEach((item) => {
					data.push({...item, IsPayRetailersPaywall: !!item.IsPayRetailersPaywall});
					copy.push({...item, IsPayRetailersPaywall: !!item.IsPayRetailersPaywall});
				});

				return Promise.resolve({data, copy});
			}
		} else {
			return Promise.reject(request.data.ResponseText);
		}
	};

	useEffect(() => {
		console.group('useEffectmerchantFilterID');
		console.log(merchantFilterID);
		console.groupEnd();
		loadAccountSelectData().then((accounts) => {
			setAccountItems(accounts);
		}).then(() => {
			console.group('datas');
			console.log(merchant);
			console.groupEnd();
			// merchant.MerchantID
			return loadGridData();
		}).then((gridData) => {
			console.group('grid data');
			console.log(gridData);
			console.groupEnd();
			setGridItems(gridData);
		}).catch((error) => {
			notifyApp(error);
		});
	}, [merchantFilterID]);

	const onRowUpdating = (obj) => {
		const {newData, oldData, key} = obj;
		console.group('updating');
		console.log(obj);
		console.groupEnd();

		if (!newData.hasOwnProperty('IsSelected')) {
			const originData = gridItems.copy.find((item) => item.PaymentSystemTypeID === key);
			const changedKeys = [];
			const newRowData = Object.assign(oldData, newData);

			for (const [key, value] of Object.entries(originData)) {
				if (key !== 'IsSelected' && newRowData[key] !== value) {
					changedKeys.push(key);
				}
			}

			newData.IsSelected = changedKeys.length > 0;
		}
	};

	const getPayoutBooleanValue = (rowData) => {
		return !!rowData.IsAllowPayoutFromDefaultAccount;
	};

	return (
		<div>
			<Loader isShowLoader={!isReady}/>
			<DetailButtons
				selectedAccountItem={selectedAccountItem}
				integrationFormData={integrationFormData}
				formState={formState}
				setIsReadOnly={setIsReadOnly}
				isReadOnly={isReadOnly}
				profileRef={profileRef}
				paymentRef={paymentRef}
				gridRef={gridRef}
			/>
			<Form
				ref={profileRef}
				formData={formData}
				readOnly={isReadOnly}
				{...formProps}
			>
				<GroupItem caption="Профиль">
					<SimpleItem dataField={'Name'} label={{text: 'Имя'}}/>
					<SimpleItem dataField={'MerchantApiProfileStatusName'} editorOptions={editorOptions.disabled}
											label={{text: 'Статус'}}/>
					<SimpleItem dataField={'NotifyURL'} label={{text: 'URL для уведомления'}}/>
					<SimpleItem dataField={'SuccessURL'} label={{text: 'URL для успешного платежа'}}/>
					<SimpleItem dataField={'CancelURL'} label={{text: 'URL для отмененного платежа'}}/>
					<SimpleItem dataField={'Description'} label={{text: 'Описание'}}/>
					<SimpleItem dataField={'WebAddress'} label={{text: 'Адрес сайта'}}/>
					<SimpleItem dataField={'BUEmail'} label={{text: 'Email для отображения клиента'}}/>
				</GroupItem>
			</Form>
			<div className={'accordion-items-wrapper'}>
				<div id={'accounts'} className={'accordion-item'}>
					<div className={'accordion-header'} onClick={() => {
						setIsAccountCollapsed(!isAccountCollapsed);
					}}>
						<i className={`dx-icon dx-icon-chevron${isAccountCollapsed ? 'right' : 'down'}`}/>
						<span className={'dx-form-group-caption'}>Счета</span>
					</div>
					{!isAccountCollapsed && (
						<div className={'accordion-form'}>
							<Form
								formData={formData}
								readOnly={isReadOnly}
								{...formProps}
							>
								<SimpleItem dataField={'DefaultAccountCode'} editorOptions={editorOptions.disabled}
														label={{text: 'Счёт мерчанта'}}/>
								<SimpleItem
									dataField={'GeneralAccountCode'}
									label={{text: 'Счёт для выплат'}}
									editorType={'dxSelectBox'}
									editorOptions={{
										displayExpr: 'Code',
										valueExpr: 'ID',
										items: accountItems,
										value: selectedAccountItem.ID,
										onValueChanged: ({value}) => {
											const selectedAccount = accountItems.find((item) => item.ID === value);
											setSelectedAccountItem(selectedAccount);
										},
									}}
								/>
							</Form>
						</div>
					)}
				</div>
				<div id={'payments'} className={'accordion-item'}>
					<div className={'accordion-header'} onClick={() => {
						setIsPaymentCollapsed(!isPaymentCollapsed);
					}}>
						<i className={`dx-icon dx-icon-chevron${isPaymentCollapsed ? 'right' : 'down'}`}/>
						<span className={'dx-form-group-caption'}>Платежные системы и параметры выплат</span>
					</div>
					{!isPaymentCollapsed && (
						<div className={'accordion-form'}>
							<Form
								ref={paymentRef}
								formData={formData}
								readOnly={isReadOnly}
								{...formProps}
							>
								<SimpleItem
									label={{text: 'Разрешена оплата заказов'}}
									render={() => {
										const ds = paymentRef.current;

										if (ds) {
											const isAllowCheckoutPayment = !!ds.instance.option('formData')['IsAllowCheckoutPayment'];

											return <CheckBox
												disabled={isReadOnly}
												onValueChanged={({value}) => {
													ds.instance.option('formData')['IsAllowCheckoutPayment'] = value ? 1 : 0;
												}}
												defaultValue={isAllowCheckoutPayment}
											/>
										} else {
											return null;
										}
									}}
								/>
								<SimpleItem
									label={{text: 'Разрешена выплата'}}
									render={() => {
										const ds = paymentRef.current;

										if (ds) {
											const isAllowPayout = !!ds.instance.option('formData')['IsAllowPayout'];

											return <CheckBox
												disabled={isReadOnly}
												onValueChanged={({value}) => {
													ds.instance.option('formData')['IsAllowPayout'] = value ? 1 : 0;
												}}
												defaultValue={isAllowPayout}
											/>
										} else {
											return null;
										}
									}}
								/>

								<SimpleItem dataField={'GeneralSettlementPeriodDays'} editorOptions={editorOptions.disabled}
														label={{text: 'Период Сеттлемента'}}/>
								{/*<SimpleItem dataField={'IndividualSettlementPeriodDays'} editorType={'dxNumberBox'}
														label={{text: 'Индивидуальное значение "Период Сеттлемента"'}}/>*/}
								<SimpleItem dataField={'GeneralSettlementDelayDays'} editorOptions={editorOptions.disabled}
														label={{text: 'Отсрочка выплаты Сеттлемента '}}/>
								{/*<SimpleItem dataField={'IndividualSettlementDelayDays'} editorType={'dxNumberBox'}
														label={{text: 'Индивидуальное значение "Отсрочка выплаты Сеттлемента"'}}/>*/}
							</Form>
							<DataGrid
								ref={gridRef}
								disabled={isReadOnly}
								dataSource={gridItems.data}
								keyExpr={'PaymentSystemTypeID'}
								onEditorPreparing={(e) => {
									// disable last column for non "Pay Retails" records
									if (
										e.parentType === 'dataRow' &&
										e.dataField === 'IsPayRetailersPaywall' &&
										e.row.data.PaymentSystemTypeID !== PAY_RETAILS
									) {
										e.editorOptions.disabled = true;
									}
								}}
								onRowUpdating={onRowUpdating}
							>
								<Editing
									mode="cell"
									allowUpdating={true}
								/>
								<Column dataField={'IsSelected'} dataType={'boolean'} caption={'Selected'}/>
								<Column dataField={'Name'} dataType={'string'} caption={'Name'}/>
								<Column allowEditing={false} dataType={'number'} dataField={'GeneralRollingReserveRate'}
												caption={'General Rolling Reserve Rate'}/>
								<Column dataType={'number'} dataField={'IndividualRollingReserveRate'}
												caption={'Individual Rolling Reserve Rate'}>
									<PatternRule pattern={integerOrDouble} ignoreEmptyValue/>
								</Column>
								<Column allowEditing={false} dataType={'number'} dataField={'GeneralRollingReserveDays'}
												caption={'General Rolling Reserve Days'}/>
								<Column dataType={'number'} dataField={'IndividualRollingReserveDays'}
												caption={'Individual Rolling Reserve Days'}>
									<PatternRule pattern={integerOrDouble} ignoreEmptyValue/>
								</Column>
								<Column calculateCellValue={getPayoutBooleanValue} dataField={'IsAllowPayoutFromDefaultAccount'}
												dataType={'boolean'} caption={'Payout from default account'}/>
								<Column dataField={'IsPayRetailersPaywall'} dataType={'boolean'}
												caption={'Is PayRetailers paywall'}/>
							</DataGrid>
						</div>
					)}
				</div>
				{integrationFormData && (
					<div id={'integration'} className={'accordion-item'}>
						<div className={'accordion-header'} onClick={() => {
							setIsIntegrationCollapsed(!isIntegrationCollapsed);
						}}>
							<i className={`dx-icon dx-icon-chevron${isIntegrationCollapsed ? 'right' : 'down'}`}/>
							<span className={'dx-form-group-caption'}>Интеграция</span>
						</div>
						{!isIntegrationCollapsed && (
							<div className={'accordion-form'}>
								<Form
									ref={apiKeyRef}
									formData={integrationFormData}
									readOnly={isReadOnly}
									{...formProps}
								>
									<SimpleItem dataField={'APIKey'} label={{text: 'Токен мерчанта'}}
															editorOptions={editorOptions.readOnly}/>
									<SimpleItem dataField={'NotifySignKey'} label={{text: 'Ключ подписи'}}
															editorOptions={editorOptions.readOnly}/>
									{integrationFormData.hasOwnProperty('PayGateToken') && (
										<SimpleItem dataField={'PayGateToken'} label={{text: 'Токен шлюза'}}/>
									)}
								</Form>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default ProfileDetailsForm;
