import React from "react";
import {Button, TextBox} from 'devextreme-react';
import {getFromLocalStorage} from 'utils/functions';
import {withRouter} from 'react-router-dom';
import RestrictionForm from 'pages/clients/clients-page/clientsPopupFields/restriction-form';
import {IncomePayout} from 'pages/clients/clients-page/clientsPopupFields';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import AccountSettings from "./account-settings";
import dayjs from "dayjs";
import Form, {GroupItem, SimpleItem} from 'devextreme-react/form';
import {vars} from 'utils/variables';

import 'pages/clients/clients-page/clientsPopupFields/merchant-details.scss';

const {MERCHANT, CLIENT_PATH, APP_CODES, WALLETTO_ACCOUNT_ID, SHARPAY_IBAN_ID} = vars;

const balanceEnabledIds = [WALLETTO_ACCOUNT_ID, SHARPAY_IBAN_ID];

const I = {
	INCOME_BUTTON: 'Пополнение',
	PAYOUT_BUTTON: 'Списание',
	RESTRICTION_BUTTON: 'Блокировки',
	TRANSACTIONS: 'Транзакции',
	CLIENT: 'Клиент',
	BALANCE: 'Показать остаток',
	PARAMETERS: 'Параметры',
};

class AccountDetailsClass extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userRights: getFromLocalStorage('userSession', 'Rights'),
			balance: null,
		};
	}

	getMerchantDetail = async () => {
		const {rowData} = this.props;

		const request = await apiRequest({
			operation: 'Merchant/GetDetail',
			data: {
				Params: {
					MerchantID: rowData.MerchantID,
				}
			}
		});

		if(request.data.ResponseCode === APP_CODES.SUCCESS) {
			return request.data.Response;
		} else {
			throw new Error(request.data.ResponseText);
		}
	}

	componentDidMount() {
		this.getMerchantDetail().then((result) => {
			this.setState({
				merchantDetailData: result,
			});
		}).catch((error) => {
			notifyApp(error);
		});
	}

	checkButtonRight = (right) => {
		const {userRights} = this.state;
		return userRights.includes(right);
	};

	getCreationDate = (props) => {
		const {editorOptions: {value}} = props;
		const val = dayjs(value).format('YYYY-MM-DD HH:mm:ss');

		return (
			<TextBox
				value={val}
				readOnly={true}
			/>
		);
	};

	render() {
		const {rowData, gridButtonHandler, history} = this.props;
		const {merchantDetailData, balance, userRights} = this.state;
		const isIncomeVisible = this.checkButtonRight(MERCHANT.RIGHTS.INCOME);
		const isPayoutVisible = this.checkButtonRight(MERCHANT.RIGHTS.PAYOUT);
		return (
			<div className={'merchant-details-wrapper'}>
				<div className={'merchant-details-buttons'}>
					<Button
						type={'normal'}
						text={I.RESTRICTION_BUTTON}
						onClick={() => {
							merchantDetailData.UserRights = userRights;
							gridButtonHandler('restriction', RestrictionForm, merchantDetailData);
						}}
					/>
					<Button
						type={'danger'}
						visible={isPayoutVisible}
						onClick={() => {
							gridButtonHandler('payout', IncomePayout, {MerchantID: rowData.MerchantID, AccountID: rowData.ID});
						}}
						text={I.PAYOUT_BUTTON}
					/>
					<Button
						type={'success'}
						visible={isIncomeVisible}
						onClick={() => {
							gridButtonHandler('income', IncomePayout, {MerchantID: rowData.MerchantID, AccountID: rowData.ID});
						}}
						text={I.INCOME_BUTTON}
					/>
					<Button
						type={'normal'}
						text={I.CLIENT}
						onClick={() => {
							const value = {
								accountFilterID: rowData.MerchantID
							};

							window.sessionStorage.setItem('filter', JSON.stringify(value));

							history.push(CLIENT_PATH);
						}}
					/>
					<Button
						type={'default'}
						text={I.TRANSACTIONS}
						onClick={() => {
							window.sessionStorage.setItem('filter', JSON.stringify({
								accountFilterID: rowData.ID,
							}));
							history.push(MERCHANT.TRANSACTIONS_PATH);
						}}
					/>
					{balanceEnabledIds.includes(rowData.PaymentSystemTypeID) && (
						<Button
							type={'success'}
							text={I.BALANCE}
							onClick={async () => {
								const request = await apiRequest({
									operation: 'WallettoAccount/GetBalance',
									data: {
										Params: {
											AccountID: rowData.ID
										}
									},
								});

								if (request.data.ResponseCode === APP_CODES.SUCCESS) {
									this.setState({
										balance: request.data.Response,
									});

								} else {
									notifyApp(request.data.ResponseText);
								}
							}}
						/>
					)}
				</div>
				{balance && (
					<div className={'merchant-details'}>
						<p>{`Баланс: ${balance.Balance}`}</p>
						<p>{`Заблокировано: ${balance.Hold}`}</p>
						<p>{`Итого: ${balance.Total}`}</p>
					</div>
				)}
				{rowData.PaymentSystemTypeID === WALLETTO_ACCOUNT_ID && (
					<AccountSettings
						rowData={rowData}
						gridButtonHandler={gridButtonHandler}
					/>
				)}
				<div>
					<Form
						id="account-details-form"
						formData={rowData}
						readOnly={true}
						showColonAfterLabel={true}
						labelLocation={'left'}
					>
						<GroupItem caption="Счёт">
							<SimpleItem dataField={'ID'} label={{text: 'ID'}}/>
							<SimpleItem render={this.getCreationDate} dataField={'InsDate'} label={{text: 'Дата создания'}}/>
							<SimpleItem dataField={'AccountStatusName'} label={{text: 'Статус'}}/>
							<SimpleItem dataField={'ExternalAccountID'} label={{text: 'Внешний ID счёта'}}/>
							<SimpleItem dataField={'Code'} label={{text: 'IBAN'}}/>
							<SimpleItem dataField={'ExternalCardID'} label={{text: 'Внешний ID карты'}}/>
							<SimpleItem dataField={'CardNumber'} label={{text: 'Карта'}}/>
						</GroupItem>
						<GroupItem caption="Клиент">
							<SimpleItem dataField={'MerchantID'} label={{text: 'ID'}}/>
							<SimpleItem dataField={'MerchantName'} label={{text: 'Клиент'}}/>
							<SimpleItem dataField={'ExternalClientID'} label={{text: 'Внешний ID Клиента'}}/>
						</GroupItem>
					</Form>
				</div>
			</div>
		);
	}
}

const WithRouterAccountDetails = withRouter(AccountDetailsClass);
export {WithRouterAccountDetails as AccountDetails};
