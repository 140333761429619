import React from 'react';
import {LoadIndicator} from 'devextreme-react/load-indicator';
import {IncomePayout} from '../clientsPopupFields';
import {getFromLocalStorage, formatDate, getMerchantName, isConfirmedValue} from 'utils/functions';
import {withRouter} from 'react-router-dom';
import ChangeMerchantParameters from './change-merchant-parameters';
import PartnersComponent from './partners';
import {apiRequest, baseUrl} from 'services/async';
import AdminPopup from 'components/popup/admin-popup';
import {CheckBox} from 'devextreme-react/check-box';
import {Button} from 'devextreme-react';
import {vars} from 'utils/variables';
import './merchant-details.scss';

const {MERCHANT, APP_CODES} = vars;

const I = {
	CHANGE_VERIFICATION_BUTTON: 'Смена верификации',
	INCOME_BUTTON: 'Пополнение',
	PAYOUT_BUTTON: 'Списание',
	// RESTRICTION_BUTTON: 'Смена параметров',
	CHANGE_PARAMETERS_BUTTON: 'Параметры',
	TRANSACTIONS: 'Транзакции',
	REGISTRATION_DATE: 'Дата регистрации',
	REGISTRATION_STATUS: 'Статус регистрации',
	LOCKED: 'Заблокирован',
	VERIFICATION: 'Верификация',
	VERIFICATION_DATE: 'Дата верификации',
	RESTRICTIONS: 'Ограничения Да / Нет  (Мерчант / Счета)',
	CLIENT_TYPE: 'Тип клиента',
	NAME: 'Имя',
	ORIGINAL_NAME: 'Имя (оригинал)',
	CLIENT: 'Клиент',
	ENTERED_NAME: 'Имя (введенное)',
	ENTERED_LAST_NAME: 'Фамилия (введенная)',
	CLIENT_BIRTH_DATE: 'Дата рождения клиента',
	EMAIL: 'email',
	PHONE: 'Телефон',
	COUNTRY: 'Страна клиента',
	TOWN: 'Город',
	TOWN_ENTERED: 'Город (введенный)',
	ADDRESS: 'Адрес',
	ADDRESS_ENTERED: 'Адрес (введенный)',
	ACCOUNTS: 'Счета',
	PARTNERS: 'Партнеры',
	CLIENT_DOCUMENTS: 'Документы клиента',
	GET_DATA: 'Получить данные',
	UPDATE_DATA: 'Обновить данные',
	CREATE: 'Создать',
	RESULT: 'Результат',
	PROFILES: 'Профили',
	CREATE_PROFILE: 'Создать профиль',
};

const getPopupTitle = (actionType, {ID = null, Name = null, UserName = null}) => {
	const titles = {
		restriction: `Параметры мерчанта ${ID} ${Name ? Name : ''} ${UserName ? UserName : ''}`,
		income: 'Техническое пополнение',
		payout: 'Техническое списание',
	};
	return titles[actionType] ? titles[actionType] : I.RESULT;
};

class MerchantDetailsClass extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedMerchant: {
				UserRights: getFromLocalStorage('userSession', 'Rights'),
				...props.rowData,
			},
			documents: [],
			innerPopupFields: null,
		};
	}

	componentDidMount() {
		this.getDetails().catch((e) => {
			console.error(e);
		});
	}

	getDetails = async () => {
		const {rowData: {ID}} = this.props;
		const request = await apiRequest({
			operation: 'MerchantDocument/List',
			data: {
				Filters: {MerchantID: ID},
				NestedColumns: {
					MerchantDocumentFile: null
				}
			}
		});

		if (request.data.ResponseCode === APP_CODES.SUCCESS) {
			this.setState({documents: request.data.Response.MerchantDocument});
		}
	};

	checkButtonRight = (right) => {
		const {selectedMerchant: {UserRights}} = this.state;
		return UserRights ? UserRights.includes(right) : !!UserRights;
	};

	setInnerPopup = (actionType, popupComponent, rowData) => {
		this.setState({
			innerPopupFields: popupComponent,
			rowData,
			actionType,
		});
	};

	closeInnerPopup = () => {
		this.setState({innerPopupFields: null});
	};

	getRestrictionsValue = ({IsRectrictAddAccount, IsRestrictAddExternalAccount, UserIsBan, IsArrestAccount}) => {
		const restrictions = [IsRectrictAddAccount, IsRestrictAddExternalAccount, UserIsBan, IsArrestAccount];
		const haveAnyRestrictions = restrictions.some(r => r === 1);
		const whoIs = IsArrestAccount === 1 ? 'Счета' : 'Мерчант';
		return haveAnyRestrictions ? `Да (${whoIs})` : 'Нет';
	};

	render() {
		const {rowData, history, updateMerchantsList, updateSelectedMerchant} = this.props;
		const {selectedMerchant, documents, actionType, innerPopupFields} = this.state;
		const isIncomeVisible = this.checkButtonRight(MERCHANT.RIGHTS.INCOME);
		const isPayoutVisible = this.checkButtonRight(MERCHANT.RIGHTS.PAYOUT);
		const address = selectedMerchant && selectedMerchant.Street !== null && selectedMerchant.PostCode !== null ? `${selectedMerchant.Street} ${selectedMerchant.PostCode}` : '';
		const maxPopupWidth = actionType === 'change-parameters' ? 1200 : 700;
		const InnerPopupFields = innerPopupFields;

		return (
			<div className={'merchant-details-wrapper'}>
				<AdminPopup
					container={'.app'}
					logo={false}
					handleClose={this.closeInnerPopup}
					visible={!!innerPopupFields}
					title={getPopupTitle(actionType, rowData)}
					maxWidth={maxPopupWidth}
				>
					{innerPopupFields && (
						<InnerPopupFields
							rowData={selectedMerchant}
							actionType={actionType}
							closePopup={this.closeInnerPopup}
							updateMerchantsList={updateMerchantsList}
							updateSelectedMerchant={updateSelectedMerchant}
						/>
					)}
				</AdminPopup>
				<div className={'merchant-details-buttons'}>
					<Button
						type={'success'}
						visible={isIncomeVisible}
						onClick={() => {
							this.setInnerPopup('income', IncomePayout);
						}}
						text={I.INCOME_BUTTON}
					/>
					<Button
						type={'danger'}
						visible={isPayoutVisible}
						onClick={() => {
							this.setInnerPopup('payout', IncomePayout);
						}}
						text={I.PAYOUT_BUTTON}
					/>
					<Button
						type={'normal'}
						text={I.CHANGE_PARAMETERS_BUTTON}
						onClick={() => {
							this.setInnerPopup('change-parameters', ChangeMerchantParameters);
						}}
					/>
					<Button
						type={'success'}
						text={I.ACCOUNTS}
						onClick={(e) => {
							const value = {
								merchantFilterID: rowData.ID
							};

							window.sessionStorage.setItem('filter', JSON.stringify(value));

							history.push(MERCHANT.ACCOUNTS_PATH);
						}}
					/>
					<Button
						type={'default'}
						text={I.TRANSACTIONS}
						onClick={(e) => {
							window.sessionStorage.setItem('filter', JSON.stringify({
								merchantFilterID: rowData.ID
							}));
							history.push(MERCHANT.TRANSACTIONS_PATH);
						}}
					/>
					<Button
						type={'success'}
						text={I.PROFILES}
						onClick={(e) => {
							history.push({
								pathname: MERCHANT.PROFILES_PATH,
								state: {merchantFilterID: rowData.ID}
							});
						}}
					/>
					<Button
						type={'danger'}
						text={I.CREATE_PROFILE}
						onClick={(e) => {
							history.push({
								pathname: MERCHANT.PROFILE_DETAIL_PATH,
								state: {merchantFilterID: rowData.ID}
							});
						}}
					/>
				</div>
				<div className={'merchant-details'}>
					{selectedMerchant ? (
						<div className={'merchant-fields-wrapper'}>
							<div className="dx-field">
								<div className="dx-field-label">{I.REGISTRATION_STATUS}</div>
								<div className="dx-field-value">{selectedMerchant.UserStatusName}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.REGISTRATION_DATE}</div>
								<div className="dx-field-value">{formatDate(selectedMerchant.InsDate)}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.LOCKED}</div>
								<div className="checkbox-field-value">
									{selectedMerchant.UserIsBan === 1 ? (
										<CheckBox defaultValue={true} readOnly={true}/>
									) : (
										<CheckBox defaultValue={false} readOnly={true}/>
									)}
								</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.VERIFICATION}</div>
								<div className="dx-field-value">{selectedMerchant.VmVerificationStatusName}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.VERIFICATION_DATE}</div>
								<div className="dx-field-value">{formatDate(selectedMerchant.VmLastUpdate)}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.RESTRICTIONS}</div>
								<div className="dx-field-value">{this.getRestrictionsValue(selectedMerchant)}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.CLIENT_TYPE}</div>
								<div className="dx-field-value">{selectedMerchant.UserTypeName}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.NAME}</div>
								<div className="dx-field-value">{selectedMerchant.Name}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.ORIGINAL_NAME}</div>
								<div
									className="dx-field-value">{getMerchantName(selectedMerchant)}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.CLIENT_BIRTH_DATE}</div>
								<div className="dx-field-value">{formatDate(selectedMerchant.DateOfBirth, true)}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.EMAIL}</div>
								<div className="dx-field-value">
									<span>{selectedMerchant.UserEmail}</span>
									<i className={`field-value dx-icon-${isConfirmedValue(selectedMerchant.UserIsEmailConfirmed)}`}/>
								</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.PHONE}</div>
								<div className="dx-field-value">
									<span>{selectedMerchant.UserPhone}</span>
									<i className={`field-value dx-icon-${isConfirmedValue(selectedMerchant.UserIsPhoneConfirmed)}`}/>
								</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.COUNTRY}</div>
								<div className="dx-field-value">{selectedMerchant.Country}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.TOWN}</div>
								<div className="dx-field-value">{selectedMerchant.Town}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.TOWN_ENTERED}</div>
								<div className="dx-field-value">{selectedMerchant.TownOrig}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.ADDRESS}</div>
								<div className="dx-field-value">{address}</div>
							</div>
							<div className="dx-field">
								<div className="dx-field-label">{I.ADDRESS_ENTERED}</div>
								<div className="dx-field-value">{selectedMerchant.StreetOrig}</div>
							</div>
							<div className={'table-wrapper'}>
								{documents.length > 0 && (
									<div style={{marginBottom: '5px'}} className="dx-form-group-caption">{I.CLIENT_DOCUMENTS}</div>
								)}
								<table>
									<tbody>
									{documents.map((item, i) => {
										return (
											<tr key={i}>
												<td>{item.DocumentType}</td>
												{item.MerchantDocumentFile && item.MerchantDocumentFile.length > 0 && (
													<td className={'document-link-wrapper'}>
														{item.MerchantDocumentFile.map((file, fileKey) => {
															return (
																<table key={`file-${fileKey}`}
																>
																	<tbody>
																	<tr>
																		<td>
																			File:
																			<br/>
																			<a
																				target={'_blank'}
																				rel={'noopener noreferrer'}
																				href={`${baseUrl}/MerchantDocument/DownloadFile?Params.MerchantDocumentFileID=${file.ID}`}
																			>
																				{file.ImageID}
																			</a>
																		</td>
																	</tr>
																	</tbody>
																</table>
															);
														})}
													</td>
												)}
											</tr>
										);
									})}
									</tbody>
								</table>
							</div>
							<br/>
							<div className={'labeled-group'}>
								<div className="dx-form-group-caption">{I.PARTNERS}</div>
								<div className={'table-wrapper'}>
									<table className={'partners-table'}>
										<tbody>
										{selectedMerchant.WallettoClientExternalID ? (
											<tr>
												<td>Walletto</td>
												<td>
													<Button
														type={'normal'}
														text={I.GET_DATA}
														onClick={() => {
															this.setInnerPopup('GetClient', PartnersComponent);
														}}
													/>
												</td>
												<td>
													<Button
														type={'normal'}
														text={I.UPDATE_DATA}
														onClick={() => {
															this.setInnerPopup('SetClient', PartnersComponent);
														}}
													/>
												</td>
											</tr>
										) : (
											<tr>
												<td>Walletto</td>
												<td>
													<Button
														type={'normal'}
														text={I.CREATE}
														onClick={() => {
															this.setInnerPopup('CreateClient', PartnersComponent)
														}}
													/>
												</td>
											</tr>
										)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					) : <LoadIndicator/>}
				</div>
			</div>
		);
	}
}

const WithRouterMerchantDetails = withRouter(MerchantDetailsClass);
export {WithRouterMerchantDetails as MerchantDetails};
