import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow,
	Format,
	Paging,
	RemoteOperations,
	Scrolling,
} from 'devextreme-react/data-grid';
import {getDataSource} from 'services/dataSource';
import {getMerchantOrder} from 'services/requestConsts';
import {getAppliedFilters, getFromLocalStorage, getFromSessionStorage} from 'utils/functions';
import {menuItems} from 'app-routes';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {Button, SelectBox, TextArea} from 'devextreme-react';
import AdminPopup from 'components/popup/admin-popup';
import {Template} from 'devextreme-react/core/template';
import {formatNumber} from 'devextreme/localization';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames/bind';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';

const {
	APP_CODES: {SUCCESS},
	TRANSACTION,
	STATE_STORING_KEYS: {MERCHANTS: {ORDERS_PAGE}},
	PRECISION,
	NOTIFY,
} = vars;

const Orders = ({history}) => {
	const merchantOrderID = getFromSessionStorage('filter', 'merchantOrderID');
	const rights = getFromLocalStorage('userSession', 'Rights');
	const isClosePaymentButtonVisible = ({row: {data}}) => {
		// https://wiki.macc.com.ua/maccsystems/pages/viewpage.action?pageId=43384924
		return rights.includes('45') && data.MerchantOrderStatusID === 4;
	}
	const gridRef = useRef(null);
	const [merchantOrder, setMerchantOrder] = useState(null);
	const [isShowExportDatePopup, setIsShowExportDatePopup] = useState(false);
	const [showFilter, setShowFilter] = useState(() => {
		return !!merchantOrderID;
	});

	const closeExportDatePopup = () => {
		setIsShowExportDatePopup(false);
	}

	useEffect(() => {
		const dataSource = getDataSource(getMerchantOrder(merchantOrderID));
		setMerchantOrder(dataSource);
		window.sessionStorage.removeItem('filter');
	}, []);

	const toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<span className={'recent-operation-text'}>
					{TRANSACTION.TEXT.FILTERS}
				</span>
			</div>
		);
	};

	const onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.push(
			{
				location: 'before',
				template: 'customToolbar'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'filter',
					onClick: () => {
						setShowFilter(!showFilter);
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'clearsquare',
					onClick: () => {
						if (gridRef.current) {
							gridRef.current.instance.clearFilter()
						}
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					onClick: () => {
						setIsShowExportDatePopup(!isShowExportDatePopup);
					}
				},
				location: 'after'
			},
		);
	}

	const [actionRowData, setActionRowData] = useState(null);
	const [amountRecalcType, setAmountRecalcType] = useState(null);
	const [remark, setRemark] = useState('');

	const closePopup = () => {
		setActionRowData(null);
	}

	return (
		<div className={'page-component-wrapper'}>
			<AdminPopup
				logo={false}
				visible={!!actionRowData}
				handleClose={closePopup}
				title={`Закрыть заказ ID: ${actionRowData && actionRowData.ID}`}
			>
				{actionRowData ? (
					<div>
						<div className='field-info'>
							<div>{`Сумма заказа: ${actionRowData.OrigAmount} ${actionRowData.CurrencyCode}`}</div>
							<div>{`Оплачено: ${actionRowData.PaymentAmount} ${actionRowData.CurrencyCode}`}</div>
						</div>
						<div className={'field-info'}>
							<div className="field-label">{'Тип обновления суммы заказа:'}</div>
							<SelectBox
								displayExpr={'Name'}
								valueExpr={'ID'}
								placeholder={'Выбрать'}
								items={[
									{'Name': 'без обновления суммы', ID: 0},
									{'Name': 'с обновлением суммы заказа', ID: 1},
								]}
								onValueChanged={({value}) => {
									setAmountRecalcType(value);
								}}
							/>
						</div>
						<br/>
						<div className={'field-info'}>
							<div className="field-label">{'Системный комментарий:'}</div>
							<TextArea
								onValueChanged={({value}) => {
									setRemark(value);
								}}
							/>
						</div>
						<div className={'button-wrapper'}>
							<Button
								text={'Подтвердить'}
								onClick={async () => {
									const request = await apiRequest({
										operation: 'MerchantOrder/Close',
										data: {
											Params: {
												MerchantOrderID: actionRowData.ID,
												AmountRecalcType: amountRecalcType,
												Remark: remark,
											}
										}
									});

									if (request.data.ResponseCode === SUCCESS) {
										notifyApp('Success', NOTIFY.SUCCESS);
										closePopup();
									} else {
										notifyApp(request.data.ResponseText);
									}
								}}
							/>
							<Button
								text={'Отмена'}
								onClick={closePopup}
							/>
						</div>
					</div>
				) : null}
			</AdminPopup>
			<DataGrid
				ref={gridRef}
				id={'grid-acc-statement'}
				className={classNames('dx-card wide-card', {
					'filter-row-visible': showFilter
				})}
				onToolbarPreparing={onToolbarPreparing}
				height={'100%'}
				onContentReady={({component, element}) => {
					getAppliedFilters(component, element);
				}}
				dataSource={merchantOrder}
				defaultFilterValue={merchantOrderID ? ['ID', '=', merchantOrderID] : null}
			>
				<RemoteOperations
					paging
					filtering
				/>
				<Paging enabled defaultPageSize={50}/>
				<FilterRow visible={true}/>
				<FilterPanel visible={true}/>
				<Scrolling
					mode={'infinite'}
					showScrollbar='onHover'
				/>
				<Column
					dataField={'InsDate'}
					caption={'Дата'}
					dataType={'datetime'}
					format={'dd.MM.yy, HH:mm:ss'}
					hidingPriority={14}
				/>
				<Column
					dataField={'ID'}
					caption={'ID'}
					hidingPriority={13}
				/>
				<Column
					dataField={'MerchantID'}
					caption={'Мерчант'}
					hidingPriority={12}
				/>
				<Column
					dataField={'MerchantApiProfileID'}
					caption={'ID профиля'}
					hidingPriority={11}
				/>
				<Column
					dataField={'ExternalOrderID'}
					caption={'Внешний ID'}
					hidingPriority={10}
				/>
				<Column
					dataField={'MerchantOrderTypeName'}
					caption={'Тип заказа'}
					hidingPriority={9}
				/>
				<Column
					dataField={'CurrencyCode'}
					caption={'Валюта заказа'}
					hidingPriority={8}
				/>
				<Column
					dataField={'OrigAmount'}
					caption={'Сумма заказа'}
					hidingPriority={7}
				>
					<Format
						type={'fixedPoint'}
						precision={PRECISION}
					/>
				</Column>
				<Column
					// minWidth={130}
					alignment={'right'}
					calculateCellValue={(row) => {
						const paymentAmount = formatNumber(row.PaymentAmount, {
							type: 'fixedPoint',
							precision: PRECISION,
						});
						const amount = formatNumber(row.Amount, {
							type: 'fixedPoint',
							precision: PRECISION,
						});
						return `${paymentAmount} | ${amount}`;
					}}
					caption={'Оплачено'}
					hidingPriority={6}
				/>
				<Column
					dataField={'FeeAmount'}
					caption={'Комиссия'}
					hidingPriority={5}
				>
					<Format
						type={'fixedPoint'}
						precision={PRECISION}
					/>
				</Column>
				<Column
					dataField={'MerchantOrderStatusName'}
					caption={'Статус'}
					hidingPriority={4}
				/>
				<Column
					dataField={'PaymentID'}
					caption={'ID Платежа'}
					hidingPriority={3}
				/>
				<Column
					dataField={'CustomerID'}
					caption={'Customer ID'}
					hidingPriority={2}
				/>
				<Column
					dataField={'CustomerEmail'}
					caption={'Email'}
					hidingPriority={1}
				/>
				<Column
					dataField={'CustomerPhone'}
					caption={'Phone'}
					hidingPriority={0}
				/>
				<Column
					type={'buttons'}
					buttons={[
						{
							hint: 'Платежи',
							icon: 'mdi mdi-format-align-justify',
							onClick: ({row}) => {
								window.sessionStorage.setItem('filter', JSON.stringify({
									merchantOrderID: row.data.ID
								}));
								history.push(menuItems.checkout[1].url);
							}
						},
						{
							visible: isClosePaymentButtonVisible,
							hint: 'Закрыть заказ',
							icon: 'mdi mdi-package-variant-closed',
							onClick: ({row}) => {
								setActionRowData(row.data);
							}
						}
					]}
				/>
				<Template name={'customToolbar'} render={toolbarItemRender}/>
			</DataGrid>
			<ExportDataGrid
				ref={gridRef}
				exportFileName={'OrdersExport'}
				getGridParams={getMerchantOrder}
				isShowExportDatePopup={isShowExportDatePopup}
				closeExportDatePopup={closeExportDatePopup}
			/>
		</div>
	);
};

export default withRouter(Orders);
