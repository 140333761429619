import React, {useEffect, useRef, useState} from 'react';
import DataGrid, {
	Button as GridButton,
	Column,
	FilterRow, Format,
	Paging,
	RemoteOperations,
	Scrolling
} from 'devextreme-react/data-grid';
import {Button, Popover, TextArea} from 'devextreme-react';
import {getDataSource} from 'services/dataSource';
import {getRollingList} from 'services/requestConsts';
import {useHistory} from 'react-router-dom';
import {getAppliedFilters, getFromLocalStorage, isVisibleRollingBtn} from 'utils/functions';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import AdminPopup from 'components/popup/admin-popup';
import {Template} from 'devextreme-react/core/template';
import classNames from 'classnames';
import {vars} from 'utils/variables';
import {menuItems} from 'app-routes';

const {PRECISION, TRANSACTION, NOTIFY, APP_CODES: {SUCCESS}} = vars;

const initialActionRow = {row: null, target: null};
const initialConfirm = {remark: '', operation: null, title: ''};

const Rolling = () => {
	const gridRef = useRef(null);
	const [rolling, setRolling] = useState(null);
	const [actionRow, setActionRow] = useState(initialActionRow);
	const [showFilter, setShowFilter] = useState(false);
	const [rights] = useState(() => {
		return getFromLocalStorage('userSession', 'Rights');
	});
	const history = useHistory();

	useEffect(() => {
		const dataSource = getDataSource(getRollingList());
		setRolling(dataSource);
	}, []);

	const [confirmData, setConfirmData] = useState(initialConfirm);

	const handleClose = () => {
		const dataSource = getDataSource(getRollingList());
		setConfirmData(initialConfirm);
		setActionRow(initialActionRow);
		setRolling(dataSource);
	};

	const toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<span className={'recent-operation-text'}>
					{TRANSACTION.TEXT.FILTERS}
				</span>
			</div>
		);
	};

	const onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.push(
			{
				location: 'before',
				template: 'customToolbar'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'filter',
					onClick: () => {
						setShowFilter(!showFilter);
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'clearsquare',
					onClick: () => {
						if (gridRef.current) {
							gridRef.current.instance.clearFilter()
						}
					}
				},
				location: 'before'
			},
		);
	};

	return (
		<div className={'page-component-wrapper'}>
			<AdminPopup
				key={confirmData.title}
				title={confirmData.title}
				logo={false}
				handleClose={handleClose}
				visible={!!confirmData.operation}
				maxWidth={800}
			>
				<div className={'add-comment-wrapper'}>
					{actionRow.row ? (
						<div className="field-info">
							<div>{`Клиент: ${actionRow.row.data.MerchantName}`}</div>
							<div>{`Счёт: ${actionRow.row.data.AccountCode}`}</div>
							<div>{`Профиль: ${actionRow.row.data.MerchantApiProfileName}`}</div>
						</div>
					) : null}

					<div className="field-label">{'Примечание'}</div>
					<TextArea
						height={120}
						text={confirmData.remark}
						onValueChanged={({value}) => {
							setConfirmData({
								...confirmData,
								remark: value,
							});
						}}
					/>
					<Button id={'add-comment-btn'} text={'Ok'} onClick={async () => {
						const request = await apiRequest({
							operation: confirmData.operation,
							data: {
								Params: {
									RollingPaymentID: actionRow.row.data.ID,
									Remark: confirmData.remark,
								}
							}
						});

						if (request.data.ResponseCode === SUCCESS) {
							notifyApp('Success', NOTIFY.SUCCESS);
							handleClose();
						} else {
							notifyApp(request.data.ResponseText);
						}
					}}/>
				</div>
			</AdminPopup>
			<Popover
				visible={!!actionRow.row}
				target={actionRow.target}
				onHiding={() => {
					setActionRow(initialActionRow);
				}}
			>
				<Button onClick={() => {
					window.sessionStorage.setItem('filter', JSON.stringify({
						id: actionRow.row.data.PaymentID
					}));
					history.push(menuItems.checkout[1].url);
				}}>
					Показать платежи
				</Button>
				<Button
					visible={isVisibleRollingBtn(rights, '31', actionRow)}
					onClick={() => {
						const {ID, Amount, CurrencyCode} = actionRow.row.data;
						const title = `Выплатить роллинг: ID ${ID}, сумма ${Amount}, валюта ${CurrencyCode}`;
						setConfirmData({remark: '', operation: 'RollingPayment/Pay', title: title});
					}}
				>
					Выплатить
				</Button>
				<Button
					visible={isVisibleRollingBtn(rights, '32', actionRow)}
					onClick={() => {
						const {ID, Amount, CurrencyCode} = actionRow.row.data;
						const title = `Отметить как выплаченный: ID ${ID}, сумма ${Amount}, валюта ${CurrencyCode}`;
						setConfirmData({remark: '', operation: 'RollingPayment/CloseWithoutPay', title: title});
					}}
				>
					Закрыть без выплаты
				</Button>
			</Popover>
			<DataGrid
				ref={gridRef}
				id={'grid-acc-statement'}
				dataSource={rolling}
				className={classNames('dx-card wide-card', {
					'filter-row-visible': showFilter
				})}
				height={'100%'}
				onToolbarPreparing={onToolbarPreparing}
				onContentReady={({component, element}) => {
					getAppliedFilters(component, element);
				}}
			>
				<RemoteOperations
					paging
					filtering
				/>
				<FilterRow visible={true}/>
				<Paging enabled defaultPageSize={50}/>
				<Scrolling
					mode={'infinite'}
					showScrollbar='onHover'
				/>
				<Column
					dataField={'InsDate'}
					caption={'Дата'}
					dataType={'datetime'}
					format={'dd.MM.yyyy, HH:mm'}
				/>
				<Column dataField={'ID'} caption={'ID'}/>
				<Column dataField={'MerchantID'} caption={'Мерчант'}/>
				<Column dataField={'MerchantApiProfileID'} caption={'ID Профиля'}/>
				<Column
					dataField={'RollingReleaseDate'}
					caption={'Дата возврата'}
					dataType={'date'}
					format={'dd.MM.yyyy'}
				/>
				<Column dataField={'RollingStatusName'} caption={'Статус'}/>
				<Column
					dataField={'Amount'}
					caption={'Сумма'}
				>
					<Format
						type={'fixedPoint'}
						precision={PRECISION}
					/>
				</Column>
				<Column dataField={'CurrencySymbol'} caption={'Валюта'}/>
				<Column
					type={'buttons'}
					caption={'Действия'}
				>
					<GridButton text={'Действия'} onClick={(e) => {
						setActionRow({
							row: e.row,
							target: e.event.target,
						});
					}}/>
				</Column>
				<Template name={'customToolbar'} render={toolbarItemRender}/>
			</DataGrid>
		</div>
	);
}

export default Rolling;