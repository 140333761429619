import React, {useState, useEffect} from "react";
import {apiRequest} from "../../../../services/async";
import {vars} from "../../../../utils/variables";
import {notifyApp} from "../../../../utils/notifyWrapper";
import {Switch} from 'devextreme-react/switch';
import {LoadIndicator} from 'devextreme-react/load-indicator';
import AccountSettingsRemark from "./account-settings-remark";

const {APP_CODES: {SUCCESS}} = vars;

const AccountSettings = ({rowData, gridButtonHandler}) => {
	const [rules, setRules] = useState(null);
	const [loader, setLoader] = useState(true);

	const loadCardRules = async () => {
		const getRulesRequest = await apiRequest({
			operation: 'WallettoCard/GetCardRule',
			data: {
				Params: {
					AccountID: rowData.ID
				}
			},
		});

		if (getRulesRequest.data.ResponseCode === SUCCESS) {
			setRules({...getRulesRequest.data.Response});
		} else {
			notifyApp(getRulesRequest.data.ResponseText);
			setLoader(false);
		}
	};

	useEffect(() => {
		loadCardRules().catch((e) => {
			console.error(e);
		});
	}, []);

	return rules ? (
		(
			<div className={'dx-fieldset'}>
				<div className="dx-field">
					<div className="dx-field-label">Снятие в АТМ</div>
					<div className="dx-field-value">
						<Switch
							defaultValue={!!rules.AtmDisabled}
							onValueChanged={({value}) => {
								gridButtonHandler('atm', AccountSettingsRemark, {
									ruleName: 'AtmDisabled',
									ruleValue: value,
									accountId: rowData.ID
								});
							}}
						/>
					</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">Бесконтактная оплата</div>
					<div className="dx-field-value">
						<Switch
							defaultValue={!!rules.ContactlessDisabled}
							onValueChanged={({value}) => {
								gridButtonHandler('contactless', AccountSettingsRemark, {
									ruleName: 'ContactlessDisabled',
									ruleValue: value,
									accountId: rowData.ID
								});
							}}
						/>
					</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">Магнитная полоса</div>
					<div className="dx-field-value">
						<Switch
							defaultValue={!!rules.MagnetStripeDisabled}
							onValueChanged={({value}) => {
								gridButtonHandler('magnetStripe', AccountSettingsRemark, {
									ruleName: 'MagnetStripeDisabled',
									ruleValue: value,
									accountId: rowData.ID
								});
							}}
						/>
					</div>
				</div>
				<div className="dx-field">
					<div className="dx-field-label">Интернет операции</div>
					<div className="dx-field-value">
						<Switch
							defaultValue={!!rules.EcommerceDisabled}
							onValueChanged={({value}) => {
								gridButtonHandler(
									'ecommerce',
									AccountSettingsRemark,
									{ruleName: 'EcommerceDisabled', ruleValue: value, accountId: rowData.ID}
								);
							}}
						/>
					</div>
				</div>
			</div>
		)
	) : (
		<div className={'fields-loader'}>
			<LoadIndicator visible={loader} height={40} width={40}/>
		</div>
	);
};

export default AccountSettings;
