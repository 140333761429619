import React, {useEffect, useState} from 'react';
import DataGrid, {Button, Column, Editing, Paging, RemoteOperations, Scrolling} from 'devextreme-react/data-grid';
import {vars} from 'utils/variables';
import {getDataSource} from 'services/dataSource';
import {getConfigurations} from 'services/requestConsts';
import {apiRequest} from 'services/async';
import {confirm} from 'devextreme/ui/dialog';

const {APP_CODES: {SUCCESS}} = vars;

const SystemSettings = () => {
	const [settingsDs, setSettingsDs] = useState([]);

	const up2 = (e) => {
		const isCanceled = new Promise((resolve, reject) => {
			const promptPromise = confirm("Are you sure?", "Confirm changes");
			promptPromise.then((dialogResult) => {
				if (dialogResult) {
					saveToApi(e).then(() => {
						resolve(false);
					}).catch((error) => {
						reject(error);
					});
				} else {
					return resolve(true);
				}
			});
		});
		e.cancel = isCanceled;
	}
	const updateRow = (event) => {
		const isCanceled = new Promise((resolve, reject) => {
			saveToApi(event).then(() => {
				resolve(false);
			}).catch((error) => {
				reject(error);
			});
		});

		event.cancel = isCanceled;
	}

	const saveToApi = async (event) => {
		const request = await apiRequest({
			operation: 'Configurations/Mod',
			data: {
				Params: {ID: event.oldData.ID, Value: event.newData.Value},
			}
		});

		if (request.data.ResponseCode === SUCCESS) {
			return request.data.ResponseCode;
		} else {
			throw new Error(request.data.ResponseText);
		}
	}

	useEffect(() => {
		const ds = getDataSource(getConfigurations());
		setSettingsDs(ds);
	}, []);

	return (
		<div className={'page-component-wrapper'}>
			<DataGrid
				id={'grid-acc-statement'}
				className={'dx-card wide-card'}
				dataSource={settingsDs}
				onRowUpdating={updateRow}
				repaintChangesOnly={true}
			>
				<Editing
					allowUpdating={true}
					mode={'row'}
					refreshMode={'repaint'}
				/>
				<Scrolling
					mode={'infinite'}
					showScrollbar='onHover'
				/>
				<Paging enabled defaultPageSize={50}/>
				<RemoteOperations
					paging
				/>
				<Column dataField={'ID'} caption={'Параметр'} allowEditing={false}/>
				<Column dataField={'Value'} caption={'Значение'}/>
				<Column dataField={'Description'} caption={'Описание'} allowEditing={false}/>
				<Column type={'buttons'}>
					<Button name={'show'} icon={'mdi mdi-eye-outline'} onClick={() => {
						alert('show');
					}}/>
					<Button name={'edit'} />
				</Column>
			</DataGrid>
		</div>
	);
}

export default SystemSettings;