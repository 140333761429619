import React, {useEffect, useState} from 'react';
import {LoadIndicator} from 'devextreme-react/load-indicator';
import {apiRequest} from 'services/async';
import {vars} from 'utils/variables';

const {APP_CODES} = vars;

const PartnersComponent = (props) => {
	const {rowData: {MerchantID}, actionType, updateSelectedMerchant} = props;
	const [jsonResponse, setJsonResponse] = useState(null);

	useEffect(() => {
		const getData = async () => {
			const operation = `WallettoClient/${actionType}`;
			const request = await apiRequest({
				operation: operation,
				data: {
					Params: {
						MerchantID: MerchantID,
					}
				}
			});

			if (request.data.ResponseCode === APP_CODES.SUCCESS) {
				setJsonResponse(request.data.Response.WallettoClientInfoJson);
			} else {
				try {
					const errorText = JSON.parse(request.data.ResponseText);
					setJsonResponse({
						...request.data,
						ResponseText: errorText
					});
				} catch (e) {
					setJsonResponse(request.data);
				}
			}
		};

		if (jsonResponse) {
			updateSelectedMerchant(MerchantID);
		} else {
			getData().catch((e) => {
				console.error(e);
			});
		}
	}, [MerchantID, actionType, jsonResponse]);

	return jsonResponse ? (
		<pre>{JSON.stringify(jsonResponse, null, 4)}</pre>
	) : (
		<div style={{textAlign: 'center'}}>
			<br/>
			<br/>
			<LoadIndicator/>
		</div>
	);
};

export default PartnersComponent;
