import React from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow,
	Format,
	Paging,
	RemoteOperations,
	Scrolling,
	StateStoring,
} from 'devextreme-react/data-grid';
import {getDataSource} from 'services/dataSource';
import {
	exportMerchantOrderPayment,
	getExportMerchantOrderPaymentList,
	getMerchantOrderPaymentList
} from 'services/requestConsts';
import {
	getAppliedFilters,
	getFromLocalStorage,
	getFromSessionStorage,
	loadGridSettings,
	saveGridSettings,
	valueToArray,
} from 'utils/functions';
import {Template} from 'devextreme-react/core/template';
import {createFilter, makeCalculateFilterExpression} from 'utils/customFilters';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {menuItems} from 'app-routes';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames/bind';
import {vars} from 'utils/variables';

import './merchantTransactions.scss';

const {
	TRANSACTION,
	STATE_STORING_KEYS: {MERCHANTS: {PAYMENTS_PAGE}},
	PRECISION,
} = vars;

class MerchantTransactions extends React.Component {
	constructor(props) {
		super(props);
		const merchantOrderID = getFromSessionStorage('filter', 'merchantOrderID');
		const settlementId = getFromSessionStorage('filter', 'settlementID');
		const id = getFromSessionStorage('filter', 'id');

		this.gridRef = React.createRef();

		this.state = {
			payments: [],
			userRights: [],
			showFilter: !!merchantOrderID,
			popupFields: null,
			popupActionType: null,
			merchantOrderID: merchantOrderID,
			settlementId: settlementId,
			id: id,
			isShowExportDatePopup: false,
		};

		this.paymentFilterOptions = {
			BoPaymentTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('BoPaymentTypeID'),
				options: {
					object: 'BoPaymentType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
			PaymentSystemTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('PaymentSystemTypeID'),
				options: {
					object: 'PaymentSystemType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
			PaymentStatusName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('PaymentStatusID'),
				options: {
					object: 'PaymentStatus',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
		};
	}

	componentDidMount() {
		this.getPaymentList().catch((e) => console.error(e));
	}

	getPaymentList = async () => {
		// const {settlementId} = this.state;
		const userRights = getFromLocalStorage('userSession', 'Rights');
		const paramObj = getMerchantOrderPaymentList();
		const payments = getDataSource(paramObj);
		window.sessionStorage.removeItem('filter');
		this.setState({
			payments,
			userRights,
		});
	};

	dateColumn(props) {
		return (
			<div>
				<span>
					{props.text}
				</span>
				<br/>
				<span className={'utc'}>
					{props.data.UTC}
				</span>
			</div>
		);
	}

	colorRedClass(id) {
		return classNames({
			'special-payment-type-id': id === TRANSACTION.SPECIAL_BO_PAYMENT_TYPE_ID
		});
	}

	paymentTypeColumn = ({data: {BoPaymentTypeID}, value}) => {
		return <div className={this.colorRedClass(BoPaymentTypeID)}>{value}</div>;
	};

	currencyColumn = ({data: {AccountCurrencyCode, AccountCurrencySymbol, BoPaymentTypeID}}) => {
		return (
			<span className={this.colorRedClass(BoPaymentTypeID)}>
				{this.displayCurrency(AccountCurrencyCode, AccountCurrencySymbol)}
			</span>
		);
	};

	amountColumn = ({data: {BoPaymentTypeID}, text}) => {
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};

	feeColumn = ({data: {BoPaymentTypeID}, text}) => {
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};

	totalAmountColumn = ({data: {BoPaymentTypeID}, text}) => {
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};


	displayCurrency(code, symbol) {
		return `${code} ${symbol}`
	}

	toReceiveCurrencyColumn = ({data: {ForeignCurrencyCode, ForeignCurrencySymbol, BoPaymentTypeID}}) => {
		return (
			<span className={this.colorRedClass(BoPaymentTypeID)}>
				{this.displayCurrency(ForeignCurrencyCode, ForeignCurrencySymbol)}
			</span>
		);
	};

	toReceiveAmountColumn = ({data: {BoPaymentTypeID}, text}) => {
		return <span className={this.colorRedClass(BoPaymentTypeID)}>{text}</span>;
	};

	toReceiveCurrencyExchangeColumn = ({data}) => {
		const {AccountCurrencySymbol, ForeignCurrencySymbol, CurrencyRate, BoPaymentTypeID} = data;
		return (
			<span className={this.colorRedClass(BoPaymentTypeID)}>
				{`${AccountCurrencySymbol} 1 = ${ForeignCurrencySymbol} ${CurrencyRate.toFixed(2)}`}
			</span>
		);
	};

	statusColumn({value, data}) {
		const {STATUS_COLOR} = TRANSACTION;
		let statusClass;
		switch (data.PaymentStatusID) {
			case 4:
				statusClass = STATUS_COLOR.GREEN;
				break;
			case 5:
				statusClass = STATUS_COLOR.RED;
				break;
			case 1:
				statusClass = STATUS_COLOR.BLACK;
				break;
			default:
				statusClass = STATUS_COLOR.OTHER;
		}
		return <span className={`status-column ${statusClass}`}>{value}</span>;
	}

	onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.push(
			{
				location: 'before',
				template: 'customToolbar'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'filter',
					onClick: this.showFilterHandler
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'clearsquare',
					onClick: () => {
						if (this.gridRef.current) {
							this.gridRef.current.instance.clearFilter()
						}
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					onClick: () => {
						const {isShowExportDatePopup} = this.state;
						this.setState({isShowExportDatePopup: !isShowExportDatePopup})
					}
				},
				location: 'after'
			},
		);
	}

	toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<span className={'recent-operation-text'}>
					{TRANSACTION.TEXT.FILTERS}
				</span>
			</div>
		);
	};

	showFilterHandler = () => {
		const {showFilter} = this.state;
		this.setState({
			showFilter: !showFilter,
		})
	};

	closePopup = () => {
		this.setState({
			popupFields: null,
		});
	};

	/**
	 *
	 * @param {object} e
	 * @param {string} e.dataField
	 * @param {HTMLElement} e.editorElement
	 * @param {object} e.editorOptions
	 * @param {boolean} e.cancel
	 * @param {any} e.value
	 * @param {'dataRow' | 'filterRow' | 'headerRow' | 'searchPanel'} e.parentType
	 */
	onEditorPreparing = (e) => {
		const {dataField, editorElement, parentType} = e;

		if (this.paymentFilterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = this.paymentFilterOptions[dataField];
			e.cancel = true;
			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					parentWidth: editorElement.clientWidth,
					value: valueToArray(e.value),
					onValueChanged: e.editorOptions.onValueChanged
				}
			}));
		}
	};

	savePaymentsSettings = (settings) => {
		console.log('savePaymentsSettings');
		saveGridSettings(settings, PAYMENTS_PAGE);
	};

	loadPaymentsSettings = () => {
		console.log('loadPaymentsSettings');
		const {merchantOrderID, settlementId, id} = this.state;
		const initFilter = merchantOrderID ? {MerchantOrderID: merchantOrderID} :
			settlementId ? {SettlementID: settlementId} : id ? {ID: id} :
				null;
		return loadGridSettings(initFilter, PAYMENTS_PAGE);
	};

	closeExportDatePopup = () => {
		this.setState({isShowExportDatePopup: false});
	}

	inOrderCurrencyCaptions = () => {
		return (
			<div className={'in-order-currency'}>
				<p>Сумма</p>
				<p>Комиссия</p>
				<p>Итого</p>
				<p>Курс</p>
			</div>
		);
	};

	inOrderCurrency = (obj) => {
		const {
			MerchantOrderCurrencyCode, MerchantOrderAmount, MerchantOrderFeeAmount, MerchantOrderTotalAmount
		} = obj.data;

		return (
			<div className={'in-order-currency in-order-currency_right'}>
				<p>{`${MerchantOrderAmount} ${MerchantOrderCurrencyCode}`}</p>
				<p>{`${MerchantOrderFeeAmount} ${MerchantOrderCurrencyCode}`}</p>
				<p>{`${MerchantOrderTotalAmount} ${MerchantOrderCurrencyCode}`}</p>
			</div>
		);
	};

	inPaymentCurrency = (obj) => {
		const {
			ForeignCurrencyCode, ForeignAmount, ForeignFeeAmount, ForeignTotalAmount, ForeignCurrencyRate
		} = obj.data;

		return (
			<div className={'in-order-currency in-order-currency_right'}>
				<p>{`${ForeignAmount} ${ForeignCurrencyCode}`}</p>
				<p>{`${ForeignFeeAmount} ${ForeignCurrencyCode}`}</p>
				<p>{`${ForeignTotalAmount} ${ForeignCurrencyCode}`}</p>
				<p>{ForeignCurrencyRate}</p>
			</div>
		);
	};

	inAccountCurrency = (obj) => {
		const {
			AccountCurrencyCode, Amount, FeeAmount, ClearAmount, CurrencyRate
		} = obj.data;

		return (
			<div className={'in-order-currency in-order-currency_right'}>
				<p>{`${ClearAmount} ${AccountCurrencyCode}`}</p>
				<p>{`${FeeAmount} ${AccountCurrencyCode}`}</p>
				<p>{`${Amount} ${AccountCurrencyCode}`}</p>
				<p>{CurrencyRate}</p>
			</div>
		);
	};

	render() {
		const {history} = this.props;
		const {payments, showFilter, isShowExportDatePopup} = this.state;
		return (
			<div className={'page-component-wrapper'}>
				<DataGrid
					id={'grid-acc-statement'}
					ref={this.gridRef}
					alignment={'center'}
					className={classNames('dx-card wide-card', {
						'filter-row-visible': showFilter
					})}
					dataSource={payments}
					hoverStateEnabled={true}
					showBorders={false}
					focusedRowEnabled={false}
					columnHidingEnabled={true}
					onEditorPreparing={this.onEditorPreparing}
					onToolbarPreparing={this.onToolbarPreparing}
					height={'100%'}
					onContentReady={({component, element}) => {
						getAppliedFilters(component, element);
					}}
				>
					<RemoteOperations
						paging
						filtering
					/>
					<StateStoring
						enabled={true}
						type="custom"
						customLoad={this.loadPaymentsSettings}
						customSave={this.savePaymentsSettings}
						savingTimeout={100}
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={true}/>
					<FilterPanel visible={true}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar='onHover'
					/>
					<Column dataField={'SettlementID'} visible={false}/>
					<Column
						dataField={'InsDate'}
						caption={'Дата'}
						dataType={'datetime'}
						format={'dd.MM.yyyy, HH:mm'}
						cellRender={this.dateColumn}
						width={130}
						hidingPriority={18}
					/>
					<Column
						dataField={'ID'}
						caption={'ID платежа'}
						width={100}
						hidingPriority={17}
					/>
					<Column
						dataField={'MerchantOrderID'}
						caption={'ID заказа'}
						hidingPriority={16}
					/>
					<Column
						dataField={'MerchantID'}
						caption={'ID мерчанта'}
						hidingPriority={15}
					/>
					<Column
						dataField={'MerchantApiProfileID'}
						caption={'ID профиля'}
						hidingPriority={14}
					/>
					<Column
						dataField={'BoPaymentTypeName'}
						caption={'Тип операции'}
						filterOperations={this.paymentFilterOptions['BoPaymentTypeName'].filterOperations}
						calculateFilterExpression={this.paymentFilterOptions['BoPaymentTypeName'].calculateFilterExpression}
						cellRender={this.paymentTypeColumn}
						width={200}
						hidingPriority={13}
					/>
					<Column
						dataField={'PaymentSystemTypeName'}
						caption={'Способ оплаты'}
						filterOperations={this.paymentFilterOptions['PaymentSystemTypeName'].filterOperations}
						calculateFilterExpression={this.paymentFilterOptions['PaymentSystemTypeName'].calculateFilterExpression}
						hidingPriority={11}
					/>
					<Column
						cellRender={this.inOrderCurrencyCaptions}
						hidingPriority={10}
						width={128}
					/>
					<Column
						caption={'В валюте заказа'}
						cellRender={this.inOrderCurrency}
						hidingPriority={9}
					/>
					<Column
						caption={'В валюте платежа'}
						cellRender={this.inPaymentCurrency}
						hidingPriority={8}
					/>
					<Column
						caption={'В валюте счета'}
						cellRender={this.inAccountCurrency}
						hidingPriority={7}
					/>
					<Column
						dataField={'RollingAmount'}
						caption={'Hold'}
						hidingPriority={6}
					>
						<Format
							type={'fixedPoint'}
							precision={PRECISION}
						/>
					</Column>
					<Column
						dataField={'PaymentStatusName'}
						caption={'Статус'}
						filterOperations={this.paymentFilterOptions['PaymentStatusName'].filterOperations}
						calculateFilterExpression={this.paymentFilterOptions['PaymentStatusName'].calculateFilterExpression}
						cellRender={this.statusColumn}
						width={150}
						hidingPriority={5}
					/>
					<Column
						dataField={'ForeignAccountCode'}
						caption={'Card/Account'}
						hidingPriority={4}
					/>
					<Column
						dataField={'ForeignClientName'}
						caption={'Holder/Payeer'}
						hidingPriority={3}
					/>
					<Column
						dataField={'ErrorText'}
						caption={'Ошибка'}
						hidingPriority={2}
					/>
					<Column
						dataField={'PayGatePaymentID'}
						caption={'PayGateTransactionID'}
						hidingPriority={1}
					/>
					<Column
						caption={'Действие'}
						type={'buttons'}
						buttons={[
							{
								hint: 'Order',
								text: 'Заказ',
								onClick: ({row}) => {
									window.sessionStorage.setItem('filter', JSON.stringify({
										merchantOrderID: row.data.MerchantOrderID
									}));
									history.push(menuItems.checkout[0].url);
								}
							}
						]}
					/>
					<Template name={'customToolbar'} render={this.toolbarItemRender}/>
				</DataGrid>
				<ExportDataGrid
					ref={this.gridRef}
					exportFileName={'MerchantTransactionsExport'}
					getGridParams={getExportMerchantOrderPaymentList}
					isShowExportDatePopup={isShowExportDatePopup}
					closeExportDatePopup={this.closeExportDatePopup}
					exportCaptions={exportMerchantOrderPayment}
				/>
			</div>
		);
	}
}

export default withRouter(MerchantTransactions);


