import React, {useState, useEffect, useRef} from 'react';
import DataGrid, {
	Column,
	Paging,
	FilterRow,
	Scrolling,
	RemoteOperations,
	StateStoring,
} from 'devextreme-react/data-grid';
import {useHistory} from 'react-router-dom';
import {getDataSource} from 'services/dataSource';
import {getMerchantApiProfile} from 'services/requestConsts';
import {getAppliedFilters} from "utils/functions";
import {Template} from 'devextreme-react/core/template';
import classNames from 'classnames/bind';
import {vars} from 'utils/variables';
import ExportDataGrid from "../../../components/export-data-grid/export-data-grid";

const {TRANSACTION, STATE_STORING_KEYS: {MERCHANTS: {PROFILES_PAGE}}, MERCHANT: {PROFILE_DETAIL_PATH}} = vars;

const Profiles = (props) => {
	const gridRef = useRef(null);
	const [merchantApiProfiles, setMerchantApiProfiles] = useState(null);
	const [isShowExportDatePopup, setIsShowExportDatePopup] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const history = useHistory();

	const closeExportDatePopup = () => {
		setIsShowExportDatePopup(false);
	}

	useEffect(() => {
		const dataSource = getDataSource(getMerchantApiProfile());
		setMerchantApiProfiles(dataSource);
	}, []);

	const toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<span className={'recent-operation-text'}>
					{TRANSACTION.TEXT.FILTERS}
				</span>
			</div>
		);
	};

	const onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.push(
			{
				location: 'before',
				template: 'customToolbar'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'filter',
					onClick: ()=> {
						setShowFilter(!showFilter);
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'clearsquare',
					onClick: () => {
						if (gridRef.current) {
							gridRef.current.instance.clearFilter()
						}
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					onClick: () => {
						setIsShowExportDatePopup(!isShowExportDatePopup);
					}
				},
				location: 'after'
			},
		);
	};

	return <div className={'page-component-wrapper'}>
		<DataGrid
			ref={gridRef}
			id={'grid-acc-statement'}
			className={classNames('dx-card wide-card', {
				'filter-row-visible': showFilter
			})}
			onToolbarPreparing={onToolbarPreparing}
			height={'100%'}
			dataSource={merchantApiProfiles}
			onContentReady={({component, element}) => {
				getAppliedFilters(component, element);
			}}
		>
			<RemoteOperations
				paging
				filtering
			/>
			{/*<StateStoring enabled={true} type="localStorage" storageKey={PROFILES_PAGE}/>*/}
			<Paging enabled defaultPageSize={50}/>
			<FilterRow visible={true}/>
			<Scrolling
				mode={'infinite'}
				showScrollbar='onHover'
			/>
			<Column
				defaultFilterValue={history.location.state && history.location.state.merchantFilterID}
				dataType={'number'}
				dataField={'MerchantID'}
				caption={'ID Клиента'}
			/>
			<Column dataType={'string'} dataField={'MerchantName'} caption={'Имя Клиента'}/>
			<Column dataType={'number'} dataField={'ID'} caption={'ID Профиля'}/>
			<Column dataType={'string'} dataField={'Name'} caption={'Имя Профиля'}/>
			<Column
				dataField={'InsDate'}
				caption={'Дата создания'}
				dataType={'datetime'}
				format={'dd.MM.yy, HH:mm:ss'}
			/>
			<Column dataType={'string'} dataField={'MerchantApiProfileStatusName'} caption={'Статус'}/>
			<Column dataType={'string'} visible={false} dataField={'NotifySignKey'}/>
			<Column
				type={'buttons'}
				caption={'Действие'}
				buttons={[
					{
						hint: 'Открыть',
						text: 'Открыть',
						onClick: ({row}) => {
							history.push({
								pathname: PROFILE_DETAIL_PATH,
								state: {merchant: row.data, merchantFilterID: row.data.MerchantID}
							});
						}
					},
				]}
			/>
			<Template name={'customToolbar'} render={toolbarItemRender}/>
		</DataGrid>
		<ExportDataGrid
			ref={gridRef}
			exportFileName={'ProfilesExport'}
			getGridParams={getMerchantApiProfile}
			isShowExportDatePopup={isShowExportDatePopup}
			closeExportDatePopup={closeExportDatePopup}
		/>
	</div>;
};

export default Profiles;


