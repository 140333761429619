import React, {useEffect, useState} from 'react';
import {Button, SelectBox, TextArea, TextBox} from 'devextreme-react';
import Validator, {RequiredRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import {notifyApp} from 'utils/notifyWrapper';
import {apiRequest} from 'services/async';
import {generatePassword} from 'utils/functions';
import {vars} from 'utils/variables';

import './income-payout.scss';

const I = {
	ACCOUNT: 'Счёт',
	ACCEPT_BUTTON: 'Создать',
	CANCEL_BUTTON: 'Отмена',
};

const {APP_CODES: {SUCCESS}, WALLETTO_ACCOUNT_ID} = vars;

const CreateNewAccount = ({closePopup, rowData, updateMerchant}) => {
	const [staticPassword, setStaticPassword] = useState('');
		const [accountsList, setAccountsList] = useState([]);

	const [remark, setRemark] = useState('');
	const [selectedAccount, setSelectedAccount] = useState({
		UniqID: null,
	});

	const getAllowedAccounts = async () => {
		const operationName = 'AllowedCreateMatrix';

		const request = await apiRequest({
			operation: `Account/${operationName}`,
			data: {
				Params: {
					MerchantID: rowData.MerchantID,
				}
			},
		});

		let result = [];

		if (request.data.ResponseCode === SUCCESS) {
			const response = request.data.Response[operationName];

			response.forEach((item, index) => {
				result.push({
					...item,
					UniqID: index,
				});
			});

			return result;
		} else {
			throw new Error(request.data.ResponseText);
		}
	}

	useEffect(() => {
		getAllowedAccounts().then((result) => {
			setAccountsList(result);
		}).catch((error) => {
			notifyApp(error);
		});
	}, []);

	useEffect(() => {
		let pass = null;

		if (selectedAccount.PaymentSystemSubTypeID === 2) {
			pass = generatePassword();
		}

		setStaticPassword(pass);

	}, [selectedAccount]);

	const createNewAccount = async () => {
		const result = validationEngine.validateGroup('create-new-group');

		if (result.isValid) {
			let operation = 'Unknown';
			let params = {
				MerchantID: rowData.MerchantID,
				PaymentSystemTypeID: selectedAccount.PaymentSystemTypeID,
				Remark: remark,
			};

			switch (selectedAccount.PaymentSystemTypeID) {
				case 0:
					operation = 'Account/Create';
					params = {
						...params,
						CurrencyID: selectedAccount.CurrencyID,
						PayGateTokenID: selectedAccount.PayGateTokenID,
					};
					break;
				case 11:
					operation = 'WallettoAccount/Create';
					params = {
						...params,
						WallettoAccountTypeID: selectedAccount.PaymentSystemSubTypeID,
						StaticPassword: staticPassword,
					}
					break;
				case 12:
					operation = 'WallettoAccount/Create';
					break;
			}

			const request = await apiRequest({
				operation: operation,
				data: {
					Params: params,
				},
			});

			const {data: {ResponseCode, ResponseText}} = request;

			if (ResponseCode === SUCCESS) {
				updateMerchant();
				closePopup();
			} else {
				notifyApp(ResponseText);
			}
		}
	};

	return (
		<div className={'action-wrapper'}>
			<div className={'clients-popup-field'}>
				<SelectBox
					items={accountsList}
					valueExpr={'UniqID'}
					placeholder={I.ACCOUNT}
					displayExpr={(item) => {
						if (!item) return null;

						const first = item.PaymentSystemSubTypeName === null ? '' : item.PaymentSystemSubTypeName;
						const second = item.CurrencyCode === null ? '' : item.CurrencyCode;
						const third = item.PayGateTokenDisplayName === null ? '' : '/ ' + item.PayGateTokenDisplayName;

						return item && `${first} / ${second} ${third}`;
					}}
					value={selectedAccount.UniqID}
					onValueChanged={({value}) => {
						const selected = accountsList.find((item) => {
							return item.UniqID === value;
						});

						setSelectedAccount(selected);
					}}
				>
					<Validator validationGroup={'create-new-group'}>
						<RequiredRule message={'Field required'}/>
					</Validator>
				</SelectBox>
			</div>
			{selectedAccount.PaymentSystemSubTypeID === 2 ?
				(
					<div
						key={staticPassword}
						className={'clients-popup-field'}>
						<span className={'dx-field-label'}>StaticPassword (3D-Secure)</span>
						<TextBox
							defaultValue={staticPassword}
							text={staticPassword}
							onValueChanged={({value}) => {
								setStaticPassword(value);
							}}
						>
							<Validator validationGroup={'create-new-group'}>
								<RequiredRule message={'Field required'}/>
							</Validator>
						</TextBox>
					</div>
				)
				: null
			}
			<TextArea
				value={remark}
				placeholder={'Комментарий'}
				onValueChanged={({value}) => {
					setRemark(value);
				}}
			/>
			<div className={'action-buttons'}>
				<Button
					className={'action-button'}
					onClick={createNewAccount}
					text={I.ACCEPT_BUTTON}
				/>
				<Button
					className={'action-button'}
					onClick={closePopup}
					text={I.CANCEL_BUTTON}
				/>
			</div>
		</div>
	);
};

export default CreateNewAccount;
