import React, {useRef, useState} from 'react';
import DataGrid, {
	Column,
	FilterPanel,
	FilterRow,
	Paging,
	RemoteOperations,
	Scrolling
} from 'devextreme-react/data-grid';
import classNames from 'classnames/bind';
import {notifyApp} from 'utils/notifyWrapper';
import {getDataSource} from 'services/dataSource';
import {exportCryptoExchangeOrder, getCryptoExchangeOrder,} from 'services/requestConsts';
import {getFromLocalStorage} from 'utils/functions';
import {Template} from 'devextreme-react/core/template';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {vars} from 'utils/variables';
import {apiRequest} from 'services/async';
import ShowComment from '../clients-page/clientsPopupFields/show-comment';
import AdminPopup from 'components/popup/admin-popup';
import {Info} from "../transcactions/transactionsPopupFields";

const {TRANSACTION, APP_CODES, NOTIFY} = vars;

const initPopupState = {
	title: 'Комментарий',
	visible: false,
	actionType: {
		subtype: null,
		remark: '',
	},
	rowData: null,
};

const cryptoExchangeDatasource = getDataSource(getCryptoExchangeOrder());

const Market = () => {
	const userRights = getFromLocalStorage('userSession', 'Rights') || [];
	const gridRef = useRef(null);
	const [isShowExportDatePopup, setIsShowExportDatePopup] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const closeExportDatePopup = () => {
		setIsShowExportDatePopup(false);
	}
	const toolbarItemRender = () => {
		return (
			<div className={'recent-operation'}>
				<span className={'recent-operation-text'}>
					{TRANSACTION.TEXT.FILTERS}
				</span>
			</div>
		);
	};
	const onToolbarPreparing = ({toolbarOptions: {items}}) => {
		items.push(
			{
				location: 'before',
				template: 'customToolbar'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'filter',
					onClick: () => {
						setShowFilter(!showFilter);
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'clearsquare',
					onClick: () => {
						if (gridRef.current) {
							gridRef.current.instance.clearFilter()
						}
					}
				},
				location: 'before'
			},
			{
				widget: 'dxButton',
				options: {
					icon: 'xlsxfile',
					onClick: () => {
						setIsShowExportDatePopup(!isShowExportDatePopup);
					}
				},
				location: 'after'
			},
		);
	}

	const [popupState, setPopupState] = useState(initPopupState);

	const closePopup = () => {
		setPopupState(initPopupState);
	}

	return (
		<div className={'page-component-wrapper'}>
			<AdminPopup
				container={'.app'}
				logo={false}
				handleClose={closePopup}
				visible={popupState.visible}
				title={popupState.title}
			>
				<ShowComment
					key={popupState.rowData}
					actionType={popupState.actionType}
					rowData={popupState.rowData}
					closePopup={closePopup}
					operation={'CryptoExchangeOrder/ModRemark'}
					fieldName={'CryptoExchangeOrderID'}
				/>
			</AdminPopup>
			<DataGrid
				ref={gridRef}
				id={'grid-acc-statement'}
				className={classNames('dx-card wide-card', {
					'filter-row-visible': showFilter
				})}
				dataSource={cryptoExchangeDatasource}
				onToolbarPreparing={onToolbarPreparing}
			>
				<RemoteOperations
					paging
					filtering
				/>
				<Paging enabled defaultPageSize={50}/>
				<FilterRow visible={true}/>
				<FilterPanel visible={true}/>
				<Scrolling
					mode={'infinite'}
					showScrollbar='onHover'
				/>
				<Column
					ref={gridRef}
					dataField={'InsDate'}
					caption={exportCryptoExchangeOrder['InsDate']}
					dataType={'datetime'}
					format={'dd.MM.yy, HH:mm:ss'}
					width={150}
				/>
				<Column dataField={'ID'} caption={exportCryptoExchangeOrder['ID']}/>
				<Column dataField={'MerchantID'} caption={exportCryptoExchangeOrder['MerchantID']}/>
				<Column dataField={'CryptoExchangeTypeName'} caption={exportCryptoExchangeOrder['CryptoExchangeTypeName']}/>
				<Column dataField={'OrderType'} caption={exportCryptoExchangeOrder['OrderType']}/>
				<Column dataField={'Actives'} caption={exportCryptoExchangeOrder['Actives']}/>
				<Column dataField={'CurrencyRate'} caption={exportCryptoExchangeOrder['CurrencyRate']}/>
				<Column dataField={'AvgCurrencyRate'} caption={exportCryptoExchangeOrder['AvgCurrencyRate']}/>
				<Column dataField={'Amount'} caption={exportCryptoExchangeOrder['Amount']}/>
				<Column dataField={'ExecutedAmount'} caption={exportCryptoExchangeOrder['ExecutedAmount']}/>
				<Column dataField={'TotalAmount'} caption={exportCryptoExchangeOrder['TotalAmount']}/>
				<Column dataField={'StatusName'} caption={exportCryptoExchangeOrder['StatusName']}/>
				<Column dataField={'Remark'} caption={exportCryptoExchangeOrder['Remark']}/>
				<Column
					type={'buttons'}
					caption={'Действие'}
					buttons={[
						/*{
							hint: 'Info',
							icon: 'info',
							visible: true,
							onClick: (e) => {
								this.handleGridColumnButton('info', Info, e.row.data);
							}
						},*/
						{
							hint: 'Decline',
							icon: 'close',
							visible: ({row: {data: {Status}}}) => {
								return Status === 0 && userRights.includes('36');
							},
							onClick: async ({row: {key}}) => {
								const request = await apiRequest({
									operation: 'CryptoExchangeOrder/Cancel',
									data: {
										Params: {
											CryptoExchangeOrderID: key,
										}
									},
								});

								if (request.data.ResponseCode === APP_CODES.SUCCESS) {
									notifyApp(`Ордер ${key} отменен`, NOTIFY.SUCCESS);
									await new Promise(resolve => setTimeout(resolve, 2000));
									if (gridRef.current) {
										gridRef.current.instance.refresh();
									}
								} else {
									notifyApp(request.data.ResponseText);
								}
							}
						},
						{
							hint: 'Info',
							icon: 'edit',
							visible: true,
							onClick: ({row}) => {
								setPopupState({
									title: 'Комментарий',
									visible: true,
									actionType: {
										subtype: 'add-comment',
										remark: row.data.Remark,
										updateComments: () => {
											if (gridRef.current) {
												gridRef.current.instance.refresh();
											}
										}
									},
									rowData: row.data,
								});
							}
						},
					]}
				/>
				<Template name={'customToolbar'} render={toolbarItemRender}/>
			</DataGrid>
			<ExportDataGrid
				ref={gridRef}
				exportFileName={'CryptoExchangeOrderExport'}
				getGridParams={getCryptoExchangeOrder}
				isShowExportDatePopup={isShowExportDatePopup}
				closeExportDatePopup={closeExportDatePopup}
				exportCaptions={exportCryptoExchangeOrder}
			/>
		</div>
	);
}

export default Market;