import React from 'react';
import PageMenu from '../../components/navigation-menu/page-menu';
import {menuItems, getMenuComponent, getDefaultPageRoute} from '../../app-routes';
import {withRouter} from 'react-router-dom';
import {vars} from '../../utils/variables';
import './checkout.scss';

const {PATH_KEY: {CHECKOUT}} = vars;

const Checkout = ({location, history}) => {
	let ActiveComponent = null;
	const activePageId = location.pathname.split('/')[2];

	if (activePageId) {
		ActiveComponent = getMenuComponent(CHECKOUT, activePageId);
	} else {
		history.push(getDefaultPageRoute(CHECKOUT));
	}

	return (
		<div className={'checkout-wrapper'}>
			<div className={'admin-navigation-menu'}>
				<PageMenu
					items={menuItems[CHECKOUT]}
				/>
			</div>
			<div
				className={'active-component-wrapper'}
			>
				{activePageId ? (
					<ActiveComponent/>
				) : null}
			</div>
		</div>
	);
};

export default withRouter(Checkout);
