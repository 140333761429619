import React, {useEffect, useState} from 'react';
import DataGrid, {Button as GridButton, Column, Selection,} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import {vars} from "utils/variables";
import {Template} from 'devextreme-react';
import {apiRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {
	checkWallettoAccounts,
	getCommonMerchantRestrictionData,
	getFromLocalStorage,
	haveAnyOf,
	onChangeMerchantParameterToolbar,
} from 'utils/functions';
import RestrictPopup from 'pages/clients/clients-page/clientsPopupFields/restrict/restrict-popup';
import ChangeVerification from 'pages/clients/clients-page/clientsPopupFields/change-verification';
import AdminPopup from 'components/popup/admin-popup';
import CreateNewAccount from './create-new-account';

const {
	APP_CODES: {SUCCESS},
	MERCHANT: {RIGHTS, ACCOUNT_STATUS, CANCEL_ACCOUNT_OPERATION},
} = vars;


const ChangeMerchantParameters = ({rowData: {MerchantID}, updateSelectedMerchant}) => {
	const [merchant, setMerchant] = useState(null);
	const [accounts, setAccounts] = useState([]);
	const [selectedAccounts, setSelectedAccounts] = useState([]);
	const [restrictHistory, setRestrictHistory] = useState([]);
	const [restrictData, setRestrictData] = useState({
		confirmOperation: '',
		remarkText: '',
		isCancelAccountOperation: false,
	});
	const [reasonsList, setReasonsList] = useState([]);
	const [isVisibleVerification, setIsVisibleVerification] = useState(false);
	const [isVisibleCreateNew, setIsVisibleCreateNew] = useState(false);
	const [commonMerchantRestrictionData, setCommonMerchantRestrictionData] = useState(null);

	const updateMerchantVariables = async (id) => {
		const merchant = await updateSelectedMerchant(id);
		setMerchant(merchant);
		setCommonMerchantRestrictionData(getCommonMerchantRestrictionData(merchant));
	};

	const updateAccountsVariable = async (id) => {
		const requestAccount = await apiRequest({
			operation: 'Account/List',
			data: {
				Filters: {
					MerchantID: id
				}
			}
		});

		const requestRestrictHistory = await apiRequest({
			operation: 'BoMerchantHistory/List',
			data: {
				Sorts: ['-InsDate'],
				Filters: {
					MerchantID: id
				}
			}
		});

		if (requestRestrictHistory.data.ResponseCode === SUCCESS) {
			setRestrictHistory(requestRestrictHistory.data.Response.BoMerchantHistory);
		} else {
			notifyApp(requestRestrictHistory.data.ResponseText);
		}

		if (requestAccount.data.ResponseCode === SUCCESS) {
			setAccounts(requestAccount.data.Response.Account);
		} else {
			notifyApp(requestAccount.data.ResponseText);
		}
	};

	const updateMerchantEffect = () => {
		updateMerchantVariables(MerchantID).catch((e) => {
			console.error(e);
		});
		updateAccountsVariable(MerchantID).catch((e) => {
			console.error(e);
		});
		fetchReasonsList().catch((e) => {
			console.error(e);
		});
	};

	useEffect(updateMerchantEffect, []);

	const restrictButton = ({data: {ID}}) => {
		const {UserIsBan, IsRestrictAddAccount, IsRestrictAddExternalAccount, UserRights} = merchant;
		let buttonProps;

		switch (ID) {
			case 1:
				if (UserIsBan) {
					buttonProps = {
						hint: 'Снять ограничения',
						text: 'Снять ограничения',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_BAN_RESTRICT),
						onClick: () => {
							console.log('Снять ограничения userIsBan');
							setRestrictData({
								...restrictData,
								confirmOperation: 'Merchant/UnrestrictUser',
							});
						}
					};
				} else {
					buttonProps = {
						hint: 'Ограничить',
						text: 'Ограничить',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_BAN_REMOVE_RESTRICT),
						onClick: () => {
							console.log('Ограничить userIsBan');
							setRestrictData({
								...restrictData,
								confirmOperation: 'Merchant/RestrictUser',
							});
						}
					};
				}
				break;
			case 4:
				buttonProps = {
					hint: 'Смена верификации',
					text: 'Смена верификации',
					width: 200,
					disabled: !UserRights.includes(RIGHTS.CHANGE_VERIFICATION_VISIBLE),
					onClick: () => {
						showVerificationPopup();
					},
				};
				break;
			case 3:
				if (IsRestrictAddExternalAccount) {
					buttonProps = {
						hint: 'Снять ограничения',
						text: 'Снять ограничения',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_ADD_ACCOUNT_RESTRICT),
						onClick: () => {
							console.log('Снять ограничения isRestrictAddExternalAccount');
							setRestrictData({
								...restrictData,
								confirmOperation: 'Merchant/UnrestrictAddExternalAccount',
							});
						}
					};
				} else {
					buttonProps = {
						hint: 'Ограничить',
						text: 'Ограничить',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_ADD_ACCOUNT_REMOVE_RESTRICT),
						onClick: () => {
							console.log('Ограничить isRestrictAddAccount');
							setRestrictData({
								...restrictData,
								confirmOperation: 'Merchant/RestrictAddExternalAccount',
							});
						}
					};
				}
				break;
			case 2:
			default:
				if (IsRestrictAddAccount) {
					buttonProps = {
						hint: 'Снять ограничения',
						text: 'Снять ограничения',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_ADD_ACCOUNT_RESTRICT),
						onClick: () => {
							console.log('Снять ограничения isRestrictAddAccount');
							setRestrictData({
								...restrictData,
								confirmOperation: 'Merchant/UnrestrictAddAccount',
							});
						}
					};
				} else {
					buttonProps = {
						hint: 'Ограничить',
						text: 'Ограничить',
						width: 200,
						visible: UserRights.includes(RIGHTS.IS_ADD_ACCOUNT_REMOVE_RESTRICT),
						onClick: () => {
							console.log('Ограничить isRestrictAddAccount');
							setRestrictData({
								...restrictData,
								confirmOperation: 'Merchant/RestrictAddAccount',
							});
						}
					};
				}
		}

		return (
			<Button
				{...buttonProps}
			/>
		);
	};

	const showAllRemarkOnDblClick = ({data}) => {
		setRestrictData({
			...restrictData,
			confirmOperation: 'showAllRemarkOnDblClick',
			remarkText: data.Remark,
		});
	};

	const showAllRemark = ({row}) => {
		setRestrictData({
			...restrictData,
			confirmOperation: 'showAllRemark',
			remarkText: row.data.Remark,
		});
	};

	const toolbarItemRender = () => {
		const userRights = getFromLocalStorage('userSession', 'Rights');
		const isSelected = selectedAccounts.length > 0;
		const haveArrestRight = userRights.includes(RIGHTS.ARREST);
		const haveCancelRight = userRights.includes(RIGHTS.CANCEL_ACCOUNT);
		const haveActiveRight = userRights.includes(RIGHTS.REMOVE_ARREST);
		const haveCreateAllowedAccountRight = userRights.includes(RIGHTS.CREATE_ALLOWED_ACCOUNT);
		const accounts = haveAnyOf(selectedAccounts);
		const isAllWallettoAccounts = checkWallettoAccounts(selectedAccounts);

		const isArrestAvailable = isSelected && haveArrestRight && accounts.haveActive;
		const isCancelAvailable = isSelected && haveCancelRight && isAllWallettoAccounts;
		const isActiveAvailable = isSelected && haveActiveRight && accounts.haveArrested;

		return (
			<div className={'restr'}>
				<Button
					text={'Арест'}
					disabled={!isArrestAvailable}
					onClick={() => {
						setRestrictData({
							confirmOperation: 'Account/Arrest',
							isCancelAccountOperation: false,
						});
					}}
				/>
				<Button
					text={'Снять арест'}
					disabled={!isActiveAvailable}
					onClick={() => {
						setRestrictData({
							confirmOperation: 'Account/Unarrest',
							isCancelAccountOperation: false,
						});
					}}
				/>
				<Button
					text={'Аннулировать'}
					disabled={!isCancelAvailable}
					onClick={() => {
						setRestrictData({
							confirmOperation: CANCEL_ACCOUNT_OPERATION,
							isCancelAccountOperation: true,
						});
					}}
				/>
				<Button
					text={'Создать счет'}
					disabled={!haveCreateAllowedAccountRight}
					onClick={showCreateNewAllowedPopup}
				/>
			</div>
		);
	};

	const getNewArrestStatus = (rowData) => {
		const {value, data} = rowData;

		let status = 'undefined';

		if (value === ACCOUNT_STATUS.IS_ACTIVE) {
			status = data.AccountStatusName;
		} else if (value === ACCOUNT_STATUS.IS_ARRESTED) {
			status = 'Arrested';
		}

		return (
			<span>{status}</span>
		);
	};

	const fetchReasonsList = async () => {
		const reason = await apiRequest({
			operation: 'WallettoCardFullBlockReason/List',
		});

		if (reason.data.ResponseCode === SUCCESS) {
			setReasonsList(reason.data.Response.WallettoCardFullBlockReason);
		} else {
			notifyApp(reason.data.ResponseText);
		}
	};

	const closeRestrictPopup = () => {
		setRestrictData({
			...restrictData,
			confirmOperation: '',
			remarkText: '',
		});
	};

	const showVerificationPopup = () => {
		setIsVisibleVerification(true);
	};

	const closeVerificationPopup = () => {
		setIsVisibleVerification(false);
	};

	const showCreateNewAllowedPopup = () => {
		setIsVisibleCreateNew(true);
	};

	const closeCreateNewAllowedPopup = () => {
		setIsVisibleCreateNew(false);
	};

	return (
		<div className={'restriction-field-wrap'}>
			<AdminPopup
				container={'.app'}
				logo={false}
				handleClose={closeVerificationPopup}
				visible={isVisibleVerification}
				title={''}
				maxWidth={700}
			>
				<ChangeVerification
					rowData={merchant}
					closePopup={closeVerificationPopup}
					updateMerchant={updateMerchantEffect}
				/>
			</AdminPopup>
			<AdminPopup
				container={'.app'}
				logo={false}
				handleClose={closeCreateNewAllowedPopup}
				visible={isVisibleCreateNew}
				title={'Создание счета'}
				maxWidth={700}
			>
				{isVisibleCreateNew ? (
					<CreateNewAccount
						key={isVisibleCreateNew}
						rowData={merchant}
						closePopup={closeCreateNewAllowedPopup}
						updateMerchant={updateMerchantEffect}
					/>
				) : null}
			</AdminPopup>
			<RestrictPopup
				rowData={merchant}
				confirmOperation={restrictData.confirmOperation}
				selectedAccounts={selectedAccounts}
				remarkText={restrictData.remarkText}
				isCancelAccount={restrictData.isCancelAccountOperation}
				reasonsList={reasonsList}
				updateCallback={updateMerchantEffect}
				closePopup={closeRestrictPopup}
			/>
			<div className={'dx-fieldset restriction-fieldset'}>
				<span className={'dx-fieldset-header'}>Общие параметры</span>
				<DataGrid
					dataSource={commonMerchantRestrictionData}
				>
					<Column
						dataField={'ID'}
						caption={'№пп'}
						width={70}
					/>
					<Column
						dataField={'Type'}
						caption={'Тип ограничения (операция)'}
					/>
					<Column
						dataField={'Status'}
						caption={'Текущий статус ограничения'}
					/>
					<Column type={'buttons'}>
						<GridButton
							render={restrictButton}
						/>
					</Column>
				</DataGrid>
			</div>
			<div className={'dx-fieldset restriction-fieldset'}>
				<span className={'dx-fieldset-header'}>Счета</span>
				<DataGrid
					dataSource={accounts}
					onToolbarPreparing={onChangeMerchantParameterToolbar}
					noDataText={'Нет счетов'}
					onSelectionChanged={({selectedRowKeys}) => {
						setSelectedAccounts(selectedRowKeys);
					}}
				>
					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'onClick'}
					/>

					<Column
						dataField="ID"
						caption="№пп"
						width={70}
					/>
					<Column
						dataField="PaymentSystemTypeName"
						caption="Платежная система"
					/>
					<Column
						dataField="PaymentSystemTypeInternalName"
						caption="Внутреннее название ПС"
						width={165}
					/>
					<Column
						dataField="Code"
						caption="Внутренний счет"
					/>
					<Column
						dataField="ExternalAccountID"
						caption="Внешний счет"
					/>
					<Column
						dataField="Name"
						caption="Карта"
					/>
					<Column
						dataField="CurrencyCode"
						caption="Валюта"
					/>
					<Column
						dataField="IsArrest"
						caption="Текущий статус"
						cellRender={getNewArrestStatus}/>
					<Template name={'restrictHistoryTemplate'} render={toolbarItemRender}/>
				</DataGrid>
			</div>
			<div className={'dx-fieldset'}>
					<span className={'dx-fieldset-header'}>
						История действий по мерчанту
					</span>
				{merchant && merchant.UserRights.includes(RIGHTS.RESTRICTION_HISTORY) ? (
					<DataGrid
						dataSource={restrictHistory}
						onRowDblClick={showAllRemarkOnDblClick}
					>
						<Column
							dataField={'InsDate'}
							caption={'Дата'}
						/>
						<Column
							dataField={'MerchantName'}
							caption={'Мерчант'}
						/>
						<Column
							dataField={'BoMerchantHistoryTypeName'}
							caption={'Параметр'}
						/>
						<Column
							dataField={'BoUserName'}
							caption={'Пользователь Back Office'}
						/>
						<Column
							dataField={'AccountCode'}
							caption={'Название счета'}
						/>
						<Column
							dataField={'Remark'}
							caption={'Комментарий'}
						/>
						<Column
							type={'buttons'}
							buttons={[
								{
									hint: 'Подробнее',
									text: 'Подробнее',
									onClick: showAllRemark
								},
							]}
						/>
					</DataGrid>
				) : (
					<span>Нет прав для просмотра истории действий по ограничениям мерчанта</span>
				)}
			</div>
		</div>
	);
};

export default ChangeMerchantParameters;
