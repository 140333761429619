import {
	NotFound, Clients, Checkout,
	ClientsPage, ClientDetails, Transactions, Market, CryptoAddress, Accounts, Cards, Iban, Referrers, ReferrerDetail,
	Orders, Rolling, Settlement, Profiles, Details, MerchantTransactions, ClientCreate,
	Settings, SystemSettings,
} from './pages';

export default [
	{id: 'clients', path: '/clients/:operation?', exact: false, component: Clients, title: 'Клиенты', url: '/clients'},
	{id: 'client-detail', path: '/clients/clients/detail', exact: true, component: ClientDetails, title: 'Детально о клиенте', hidden: true},
	{id: 'profile-details', path: '/checkout/profiles/detail', exact: true, component: Details, title: 'Профили', hidden: true},
	{id: 'referrer-details', path: '/clients/referrers/detail', exact: true, component: ReferrerDetail, title: 'Реферралы ', hidden: true},
	{id: 'clients-create', path: '/clients/client/create', exact: true, component: ClientCreate, title: 'Создать ', hidden: true},
	{id: 'checkout', path: '/checkout/:operation?', exact: false, component: Checkout, title: 'Мерчанты', url: '/checkout'},
	{id: 'settings', path: '/settings/:operation?', exact: false, component: Settings, title: 'Настройки', url: '/settings'},
];

export const menuItems = {
	'clients': [
		{id: 'clients', url: '/clients/clients', component: ClientsPage, title: 'Клиенты'},
		{id: 'accounts', url: '/clients/accounts', component: Accounts, title: 'Счета'},
		{id: 'transactions', url: '/clients/transactions', component: Transactions, title: 'Транзакции'},
		{id: 'market', url: '/clients/market', component: Market, title: 'Крипто.Маркет'},
		{id: 'crypto-address', url: '/clients/crypto-address', component: CryptoAddress, title: 'Крипто.Адреса'},
		{id: 'cards', url: '/clients/cards', component: Cards, title: 'Карты.Операции'},
		{id: 'iban', url: '/clients/iban', component: Iban, title: 'IBAN.Операции'},
		{id: 'referrers', url: '/clients/referrers', component: Referrers, title: 'Реферреры'},
	],
	'checkout': [
		{id: 'orders', url: '/checkout/orders', component: Orders, title: 'Заказы'},
		{id: 'transactions', url: '/checkout/transactions', component: MerchantTransactions, title: 'Транзакции'},
		{id: 'settlement', url: '/checkout/settlement', component: Settlement, title: 'Settlement'},
		{id: 'rolling', url: '/checkout/rolling', component: Rolling, title: 'Rolling'},
		{id: 'profiles', url: '/checkout/profiles', component: Profiles, title: 'Профили'},
	],
	'settings': [
		{
			id: 'system-settings',
			url: '/settings/system-settings',
			component: SystemSettings,
			title: 'Системные настройки',
			accessRight: '43',
		},
	],
};

export const getMenuComponent = (key, itemId) => {
	const activeRoute = menuItems[key].find(item => item.id === itemId);
	return activeRoute ? activeRoute.component : NotFound;
};

export const getDefaultPageRoute = (key) => {
	return menuItems[key][0]['url'];
};
