import React from 'react';
import TextBox from 'devextreme-react/text-box';
import ValidationGroup from 'devextreme-react/validation-group';
import Validator, {RequiredRule} from 'devextreme-react/validator';
import Button from 'devextreme-react/button';
import './login-form.scss';
import appInfo from '../../app-info';
import {sha512} from 'utils/functions';
import {apiRequest} from 'services/async';
import AdminPopup from '../popup/admin-popup';
import {notifyApp} from 'utils/notifyWrapper';
import RecoverLoginFields from '../popup/recover-login-fields/recover-login-fields';
import {vars} from 'utils/variables';
import Loader from 'components/loader/loader';

const {APP_CODES: {SUCCESS}} = vars;

const LOGIN_FORM = {
	SUCCESS_CHANGE: 'Your password has been successfully changed',
	SUCCESS_BUTTON: 'Ok',
	RECOVER_POPUP_TITLE: 'Setting password',
};

export default class LoginForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			login: '',
			password: '',
			hashedPassword: null,
			isPasswordChange: false,
			isShowLoader: false,
		};
	}

	successChangePasswordHandler = () => {
		this.setState({
			isPasswordChange: true,
		});
	};

	handleClosePopup = () => {
		this.setState({
			hashedPassword: null,
		});
	};

	loginChanged = e => {
		this.setState({login: e.value});
	};

	passwordChanged = e => {
		this.setState({password: e.value});
	};

	onLoginClick = async (args) => {
		if (!args.validationGroup.validate().isValid) {
			return;
		}

		this.setState({
			isShowLoader: true,
		});

		const {login, password} = this.state;

		const hashedPassword = await sha512(password);

		if (hashedPassword) {
			const loginRequest = await apiRequest({
				operation: 'Login',
				data: {
					Login: login,
					Password: hashedPassword,
				}
			});

			if (loginRequest.data) {
				const {ResponseCode, Response, ResponseText} = loginRequest.data;

				if (ResponseCode === SUCCESS) {
					if (Response.IsPasswordExpired) {
						// show popup
						this.setState({
							hashedPassword: hashedPassword
						});
					} else {
						const userRights = await this.requestUserRights();
						if (userRights) {
							this.props.onLoginClick(Response.UserID);
						} else {
							notifyApp(userRights.ResponseText);
						}
					}
				} else {
					notifyApp(ResponseText);
				}
			} else {
				notifyApp('Network Error');
			}
		} else {
			notifyApp('Client WebAPI error');
		}

		this.setState({
			isShowLoader: false,
		});
	};

	requestUserRights = async () => {
		const userSession = await apiRequest({
			operation: 'Login/SessionConfiguration',
		});

		if (userSession.data.ResponseCode === SUCCESS) {
			window.localStorage.setItem('userSession', JSON.stringify(userSession.data.Response));
			return Promise.resolve(userSession.data.Response);
		}

		return Promise.reject(userSession.data);
	};

	render() {
		const {login, password, hashedPassword, isPasswordChange, isShowLoader} = this.state;
		return (
			<div className={'login-wrapper'}>
				<Loader isShowLoader={isShowLoader}/>
				<AdminPopup
					visible={!!hashedPassword}
					container={document.getElementById('login-page')}
					title={isPasswordChange ? LOGIN_FORM.SUCCESS_CHANGE : LOGIN_FORM.RECOVER_POPUP_TITLE}
					handleClose={this.handleClosePopup}
				>
					{isPasswordChange ?
						(
							<div className={'success-button'}>
								<Button
									text={LOGIN_FORM.SUCCESS_BUTTON}
									onClick={this.handleClosePopup}
								/>
							</div>
						) : (
							<RecoverLoginFields
								login={login}
								password={hashedPassword}
								successHandler={this.successChangePasswordHandler}
							/>
						)
					}
				</AdminPopup>
				<ValidationGroup>
					<div className={'login-header'}>
						<div className={'title'}>{appInfo.title}</div>
						<div>Sign In to your account</div>
					</div>
					<div className={'dx-field'}>
						<TextBox
							value={login}
							onValueChanged={this.loginChanged}
							placeholder={'Login'}
							width={'100%'}
						>
							<Validator>
								<RequiredRule message={'Login is required'}/>
							</Validator>
						</TextBox>
					</div>
					<div className={'dx-field'}>
						<TextBox
							mode={'password'}
							value={password}
							onValueChanged={this.passwordChanged}
							placeholder={'Password'}
							width={'100%'}
						>
							<Validator>
								<RequiredRule message={'Password is required'}/>
							</Validator>
						</TextBox>
					</div>
					<div className={'dx-field'}>
						<Button
							type={'default'}
							text={'Login'}
							onClick={this.onLoginClick}
							width={'100%'}
						/>
					</div>
				</ValidationGroup>
			</div>
		);
	}
}
