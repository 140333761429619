import DataSource from 'devextreme/data/data_source';
import axios from 'axios';
import {prepareRequestOptions} from './async';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';
import {getFilter} from './dataSourceFilterUtils';

const {APP_CODES: {SUCCESS}} = vars;

/**
 * @param {string} key
 * @param {string[]} columns
 * @param {string} object
 * @param {object} defFilter
 * @param {object} [fieldsSettings]
 * @param {string} sorts
 * @param {function} modify
 * @return {DataSource}
 */
export const getDataSource = ({
																key,
																columns = undefined,
																object,
																defFilter = {},
																fieldsSettings,
																sorts = '-ID',
																modifier = null
															}) => {
	return new DataSource({
		key,
		load: async ({isLoadingAll, take, skip, filter}) => {
			let page = (skip + take) / take || -1;

			let apiFilter = fieldsSettings ? getFilter(filter, fieldsSettings) || {} : {};

			let requestObj = {
				Filters: {
					...defFilter,
					...apiFilter
				},
				Sorts: [sorts],
				Page: isLoadingAll ? -1 : page,
				RowsPerPage: take,
				Columns: columns,
			};

			if (!Object.keys(requestObj.Filters).length) {
				delete requestObj.Filters;
			}

			const response = await axios(prepareRequestOptions({operation: `${object}/List`, data: requestObj}));

			if (response.data.ResponseCode === SUCCESS) {
				const result = response.data.Response[object];
				return modifier ? modifier(result) : result;
			}

			const {NOTIFY} = vars;
			notifyApp(response.data.ResponseText, NOTIFY.ERROR);
			return [];
		},
		update: (e) => {
			console.log(e);
		}
	});
};
